.login-bottom-sheet-container {
    padding: 20px;
    background-color: $doctor;
    &.check-code {
        .enter-code-description {
            padding: 0;
            .back-icon {
                margin: 10px 0;
            }
        }
        .enter-code-inputs {
            padding: 0;
        }
        .receive {
            padding: 20px 0;
        }
    }
    .welcome-being-mykuya {
        position: relative;
        padding: 0;
        bottom: 0;
        margin-top: 50px;
        .text-info {
            color: $silver-chalice;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
        }
    }
}