@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@font-face {
  font-family: 'digital';
  src: url(/static/media/ds_digit.223f2246.ttf) format('truetype');
}
/* @font-face {
  font-family: 'lato_black_italic';
  src: url('./assests/fonts/lato/lato_black_italic.ttf') format('truetype');
}
@font-face {
  font-family: 'lato_black';
  src: url('./assests/fonts/lato/lato_black.ttf') format('truetype');
} */
/* @font-face {
  font-family: 'lato_bold';
  src: url('./assests/fonts/lato/lato_bold.ttf') format('truetype');
}
@font-face {
  font-family: 'lato_regular';
  src: url('./assests/fonts/lato/lato_regular.ttf') format('truetype');
} */

body {
  overflow: hidden;
  margin: 0;
  font-family: 'Poppins', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none; /* Prevent to pull refresh */
  background-color:#FAFBFC !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#launcher {
  display: none;
}

.Modal.modal {
  display: block;
  background-color: rgba(110, 110, 110, 0.55);
}
@media (min-width: 770px) {
  .fixed-wrapper {
    border: 1px solid #dededf;
  }
  .fixed-wrapper, .wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 414px;
  }
  .fixed-wrapper__main {
    position: relative;
    min-height: 100vh;
  }
  .Bottom_Sheet_Parent_Home_Page > div {
    max-width: 100% !important;
  }
  .Bottom_Sheet_Parent_Home_Page > div {
    max-width: 412px !important;
  }
}
.fixed-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.fixed-wrapper::-webkit-scrollbar {
  width: 0;
}
@media (max-width: 770px) {
  .Bottom_Sheet_Parent_Home_Page > div {
    max-width: 100% !important;
  }
}
/* .App {
  text-align: center;
} */

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.light-divider {
  border: 0.2px solid #FAFBFC; }

.dark-divider {
  border: 0.2px solid #B1B3B7; }

.fade {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(226, 238, 248, 0)), to(#E2EEF8));
  background: -webkit-linear-gradient(top, rgba(226, 238, 248, 0) 0%, #E2EEF8 100%);
  background: linear-gradient(180deg, rgba(226, 238, 248, 0) 0%, #E2EEF8 100%); }

.blue-shadow {
  box-shadow: 0px 3px 4px rgba(9, 96, 179, 0.5); }

.black-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.upper-black-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

.subtle-black-shadow, input:focus, .form-group-container.focused, .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text:hover, .button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text:hover, .add-new-card .adyen-checkout__input--focus,
.add-new-card .adyen-checkout__input--focus:hover,
.add-new-card .adyen-checkout__input:active,
.add-new-card .adyen-checkout__input:active:hover,
.add-new-card .adyen-checkout__input:focus,
.add-new-card .adyen-checkout__input:focus:hover, .topup-mykoin-bottom-sheet .top-up-mykoin-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
  box-shadow: 0px 2px 4px rgba(24, 26, 30, 0.15); }

.big-black-shadow, .queued-jobs-container ul li .check::before, .stepper-container button, .Job-chat-history-cancel ul li .check::before, .Whats_New .Whats_New_Slider_Box_Mobile, .Whats_New .Whats_New_Slider_Box_Desktop, .Active_Jobs .Active_Jobs_Slider_Box {
  box-shadow: 0px 8px 12px rgba(24, 26, 30, 0.12); }

.small-inner-shadow {
  box-shadow: inset 0 0 4px rgba(24, 26, 30, 0.1); }

.no-padding {
  padding: 0; }

.no-padding-left {
  padding-left: 0; }

.no-padding-right {
  padding-right: 0; }

.p-l-0 {
  padding-left: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.m-t-2 {
  margin-top: 2px; }

.m-t-5 {
  margin-top: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-t-29 {
  margin-top: 29px; }

.m-t-30 {
  margin-top: 30px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-50 {
  margin-top: 50px; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-b-10 {
  margin-bottom: 10px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-70 {
  margin-bottom: 70px; }

.m-t-150 {
  margin-top: 150px; }

.assistive, .form-group-container label, .rce-container-mbox .timezone, .topup-mykoin-bottom-sheet .top-up-mykoin-container .error {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.4px; }

.link, .account-version-container .account-necessary-links a, .location-search-box-container .location-search-box-inner input, .location-search-destination-box .location-search-box-inner input, .billing-information-add .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px; }

.overline, .account-version-container .account-version-number, .job-receipt-home .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner .lozenge-title p, .Job_Rating .Job_Rating_Box .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner .lozenge-title p, .request-placed .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner .lozenge-title p, .form-group-container.input-error + .error, .check-code .error, .lozenge-container .lozenge-inner .lozenge-title, .location-search-suggestions .location-search-suggestion-title, .pin-icon-wrapper .number-destination, .billing-information-card-item .billing-information-card-expiration span, .Job_Chat_History_Header_Top .Job_Chat_History_Header_Middle .Matched_State, .chat-menu-title-bar .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner .lozenge-title p, .review-product .title-bar-container .title-bar-icon .lozenge-title p, .review-booking-item-container .review-booking-item-inner + .hint, .review-booking-item-container .promo-code, .job-locations-container .icon-container .number, .job-matching .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner .lozenge-title p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px; }

.caption, .accout-container .account-profile .profile-info a, .refer .refer-description p, .queued-jobs-container ul li .check label span, .welcome .welcome-subtitle, .welcome .welcome-agree, .welcome .welcome-agree-link, .welcome .welcome-agree-policy,
.welcome .welcome-agree-terms, .check-code .receive .didnt-receive, .check-code .receive .receive-new-text, .check-code .receive .receive-new, .label-with-icon-container .label-with-icon-inner .label-with-icon-title, .tab-container .segmented-control, .saved-places-address .saved-places-name, .saved-places .title-container .sub-title, .location-search-suggestions .location-search-suggestions-item .location-search-suggestions-title, .location-search-set .Modal .message p, .location-search-box-container .location-search-box-input, .location-search-box-container .location-search-box-input::-webkit-input-placeholder, .location-search-box-container .location-search-box-input:-ms-input-placeholder, .location-search-box-container .location-search-box-input::-moz-placeholder, .add-new-card .tip, .Chat_Note_To_Partner .job-note-container p, .Featured_Title, .Top_Four_Empty .Empty_Down_Description, .review-booking-button-container .price-container p, .review-product .tip, .review-booking-item-container .review-booking-item-inner p, .toast-rejected-payment .Toastify__toast-body.toastify-inner, .payment-method-bottom-sheet .tip, .booking-price-error .booking-price-error-content .booking-price-description .text-secondary, .rejected-payment-container .rejected-payment-body p, .chat-with-support-bottom-sheet .content, .chat-with-support-bottom-sheet .code-content {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px; }

.button, .account-version-container .account-logout-container button, .Empty_Job_Btn, .Job_Rating_Buttons .Job_Rating_Buttons_Skipp .J_R_B_Skipp, .Job_Rating_Buttons .Job_Rating_Buttons_Submit .J_R_B_Submit, .datepicker.ios .datepicker-navbar .datepicker-navbar-btn:first-child, .datepicker.ios .datepicker-navbar .datepicker-navbar-btn:last-child, .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text, .button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text, .location-bottom-sheet-container + .close-button-container button, .add-new-card .adyen-checkout__button, .Cancel_Btn_Box .Cancel_Job_Button, .Job-chat-history-cancel .retry-button, .Job-chat-history-cancel .cancel-button, .Job-chat-history-cancel .chat-with-support span, .Empty_Top_Four_Btn, .Empty_Service_Btn, .Empty_Top_Four, .booking-info-container + .close-button-container button, .review-booking-button-container .review-booking-button .button-container .MuiButton-root, .cancel-booking-container .chat-with-support span, .rejected-payment-container .rejected-payment-bottom .chat-with-support span, .promo-code-bottom-sheet .promo-code-button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text, .job-unfulfilled-buttons .retry-button, .job-unfulfilled-buttons .cancel-button, .profile-form .edit-profile-action .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text, .topup-mykoin-bottom-sheet .top-up-mykoin-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text, .chat-with-support-bottom-sheet .chat-with-support span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px; }

.body2, .matching-process .matching-process-container .matching-process-row p, .Job-locations .review-booking-item-inner p, .queued-jobs-container .matching-process-container .matching-process-row p, .form-group-container input,
.form-group-container .form-control, .form-group-container .form-select-box, .welcome .description, .welcome .welcome-being-mykuya a, .billing-information-card-item .billing-information-card-item-field, .billing-information-card-item .billing-information-card-expiration p, .billing-information-card-item .billing-information-card-item-value, .billing-information-safely p, .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Type_Box .Job_Description_Inner, .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Time_Content .J_C_H_D_S_C_Job_Type_Box .Job_Description_Inner, .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .job-description-inner, .booking-service-description-component p, .booking-special .booking-special-textarea textarea::-moz-placeholder, .booking-special .booking-special-textarea textarea:placeholder, .booking-special .booking-special-textarea textarea:-moz-placeholder, .booking-special .booking-special-textarea textarea:-ms-input-placeholder, .booking-special .booking-special-textarea textarea::-webkit-input-placeholder, .cancel-booking-container p, .how-it-works-container .content-container p, .text .rce-mbox {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px; }

.body2, .matching-process .matching-process-container .matching-process-row p, .Job-locations .review-booking-item-inner p, .queued-jobs-container .matching-process-container .matching-process-row p, .form-group-container input,
.form-group-container .form-control, .form-group-container .form-select-box, .welcome .description, .welcome .welcome-being-mykuya a, .billing-information-card-item .billing-information-card-item-field, .billing-information-card-item .billing-information-card-expiration p, .billing-information-card-item .billing-information-card-item-value, .billing-information-safely p, .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Type_Box .Job_Description_Inner, .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Time_Content .J_C_H_D_S_C_Job_Type_Box .Job_Description_Inner, .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .job-description-inner, .booking-service-description-component p, .booking-special .booking-special-textarea textarea::placeholder, .booking-special .booking-special-textarea textarea:placeholder, .booking-special .booking-special-textarea textarea:-moz-placeholder, .booking-special .booking-special-textarea textarea:-ms-input-placeholder, .booking-special .booking-special-textarea textarea::-webkit-input-placeholder, .cancel-booking-container p, .how-it-works-container .content-container p, .text .rce-mbox {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px; }

.body1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px; }

.subtitle2, .job-receipt-cost .cost, .queued-jobs-container ul li .check label p, .saved-places .title-container .name, .saved-places .title-container p, .location-search-saving-container .location-search-saving-item .saved-places-title, .billing-information-card-item .billing-information-card-item-name, .how-it-works-container .content-container h6, .topup-mykoin-bottom-sheet .top-up-mykoin-container .default-amount-container .default-amount p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px; }

.subtitle1, .account-links .account-links-box-title, .job-receipt-home .title-bar-container .title-bar-icon .heading, .Job_Rating .Job_Rating_Box .title-bar-container .title-bar-icon .heading, .Feed_Back_Text, .request-placed .title-bar-container .title-bar-icon .heading, .Job_Chat_History_Header_Down .J_C_H_H_D_Profile .J_C_H_H_D_Profile_Text_Box .Job_Chat_History_Header_Name, .Job_Chat_History_Menu_Box .J_C_H_M_B_Row_Text h5, .chat-menu-title-bar .title-bar-container .title-bar-icon .heading, .Job-chat-history-cancel .title, .booking-location-box h2, .booking-special .booking-special-title p, .promo-code-bottom-sheet .title h2, .job-matching .title-bar-container .title-bar-icon .heading, .edit-profile .title-bar-container p, .title-bar-container p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px; }

.h6, .accout-container .account-profile .account-profile-name, .refer .refer-description h6, .refer .refer-action-container .refer-input-code, .job-receipt-cost .total-amount, .matching-process .matching-process-title .title p, .register-form-container .title, .welcome .welcome-title, .enter-code-inputs .signup-input, .page-title-box, .location-search-set .Modal .location-search-set-title p, .billing-information .title, .add-new-card .title, .add-new-card .adyen-checkout__fieldset__title, .Home_Header .Home_Header_greeting_container .Home_Header_greeting_Text h5, .Home_Featured .Home_Featured_Box .tab-active-home h5, .Whats_New .Whats_New_Title h1, .Active_Jobs .Active_Jobs_Title h1, .booking-service .booking-service-product .booking-service-name, .review-booking-button-container .price-container p.price, .cancel-booking-container h2, .booking-price-error .booking-price-error-content .booking-price-description .title, .rejected-payment-container .rejected-payment-header h2, .edit-profile .title, .chat-with-support-bottom-sheet .header, .jobs-tab-list .tab-item {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px; }

.h5, .products-container .shop-name h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 34px; }

.h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 33px;
  line-height: 49px; }

input {
  border-radius: 8px;
  background-color: #FAFBFC;
  padding: 5px 8px; }
  input:focus {
    border: 1px solid #81B7E9; }

.black-background {
  background: #3B424A;
  width: 90%;
  margin: 0 auto 20px;
  border-radius: 8px; }
  .black-background .Toastify__close-button--default {
    display: none; }
  .black-background.Toastify__toast {
    min-height: auto;
    padding: 15px 24px; }
  .black-background .Toastify__toast-body.toastify-inner {
    color: #FAFBFC;
    margin: 0; }
    .black-background .Toastify__toast-body.toastify-inner > div {
      display: flex; }
      .black-background .Toastify__toast-body.toastify-inner > div p {
        margin: 0;
        color: #FAFBFC;
        font-size: 14px;
        flex-basis: 80%; }
      .black-background .Toastify__toast-body.toastify-inner > div button {
        color: #81B7E9;
        background: transparent;
        font-weight: 600;
        font-size: 12px;
        border: 0;
        flex-basis: 20%;
        text-align: right; }

.fixed-body {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.account-version-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px; }
  .account-version-container .account-necessary-links {
    padding: 0 20px 24px;
    display: flex;
    flex-direction: column; }
    .account-version-container .account-necessary-links a {
      margin-bottom: 8px;
      color: #0C87FA; }
      .account-version-container .account-necessary-links a:hover, .account-version-container .account-necessary-links a:focus {
        text-decoration: none; }
  .account-version-container .account-version-number {
    color: #B1B3B7; }
  .account-version-container .account-logout-container {
    padding: 0 20px;
    display: flex;
    justify-content: space-between; }
    .account-version-container .account-logout-container button {
      color: #FC6A54;
      border: 1px solid #FC6A54;
      box-shadow: none;
      background: none;
      width: 100%;
      border-radius: 5px;
      padding: 8px; }
      .account-version-container .account-logout-container button:focus {
        outline: none; }

.account-links {
  display: flex;
  flex-direction: column; }
  .account-links.padding-top {
    padding-top: 65px; }
  .account-links .account-links-box {
    display: flex;
    justify-content: space-between;
    padding: 18px 20px; }
  .account-links .account-links-box-title {
    color: #3B424A; }

.accout-container {
  display: block;
  position: relative;
  height: 100%;
  overflow: scroll; }
  .accout-container::-webkit-scrollbar {
    width: 0; }
  .accout-container .account-profile {
    padding: 65px 20px 20px;
    display: flex;
    align-items: center; }
    .accout-container .account-profile .account-profile-name {
      margin-bottom: 0; }
    .accout-container .account-profile .account-profile-edit {
      display: inline-block;
      margin: 0 5px 0 0;
      color: #B1B3B7;
      font-size: 12px;
      line-height: 18px; }
    .accout-container .account-profile .avatar-wrapper {
      padding-right: 15px; }
    .accout-container .account-profile .account-profile-forward {
      width: 6px;
      height: 10px; }

.refer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 65px;
  background-color: #FAFBFC;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px; }
  .refer::-webkit-scroll {
    width: 0; }
  .refer .refer-img {
    padding-top: 10%;
    padding-bottom: 30px;
    text-align: center; }
  .refer .refer-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 20px; }
    .refer .refer-description h6 {
      color: #3B424A; }
    .refer .refer-description p {
      color: #3B424A;
      margin: 0; }
  .refer .refer-action-container {
    padding: 40px 20px 0;
    display: flex;
    justify-content: center; }
    .refer .refer-action-container .refer-input-code {
      text-align: center;
      color: #3B424A;
      border: 2px dashed #B56400;
      background-color: #F7E5CF;
      text-transform: none !important;
      height: 60px;
      width: 70%;
      border-radius: 8px; }
  .refer .share-code {
    position: absolute;
    bottom: 0;
    background: #FAFBFC;
    padding: 15px;
    width: 100%;
    text-align: center; }
    .refer .share-code button {
      width: 100%; }

.Refer .Refer-description {
  color: #ccc;
  margin-bottom: 2px; }

.Refer .Refer-action-container {
  padding: 40px 60px; }

.Refer .Refer-action-container .Refer-input-code {
  text-align: center;
  border: 1px dashed #0787fa !important;
  text-transform: none !important;
  height: 50px !important;
  font-size: 25px !important;
  width: 100%;
  border-radius: 5px; }

.Refer .Refer-action-container button {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  margin-top: 15px;
  padding: 12px;
  font-size: 16px;
  /* font-family: 'lato_black'; */
  color: white;
  text-transform: none !important; }

.Refer .Refer-action-container button.share-code {
  background-color: #0787fa !important; }

.Refer .Refer-action-container button.invite-friends {
  background-color: orange; }

.Refer .Header-back .Header-back-img p {
  padding-left: 0; }

.Refer .Refer-img {
  padding: 15px 30px 0px; }

.News {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-height: 200px;
  overflow-y: scroll; }

.News::-webkit-scrollbar {
  width: 0; }

.News_Box {
  background-color: #FAFBFC;
  height: 80px;
  box-shadow: 0 3px 5px 0 rgba(38, 62, 174, 0.1);
  padding: 15px;
  flex-basis: 100%;
  display: flex;
  margin: 15px 24px;
  justify-content: space-between; }
  .News_Box .News_Box_Envelope {
    flex-basis: 15%; }
    .News_Box .News_Box_Envelope .News_Box_Envelope_Container {
      position: relative;
      width: 28px;
      height: 22px;
      border-radius: 50%;
      margin: 0; }
      .News_Box .News_Box_Envelope .News_Box_Envelope_Container .News_Day {
        position: absolute;
        font-size: 11px;
        color: #0070E0;
        top: 17px;
        width: 27px;
        right: 0;
        font-weight: bold; }
      .News_Box .News_Box_Envelope .News_Box_Envelope_Container .News_Month {
        width: 18px;
        position: absolute;
        font-size: 7px;
        color: #2F353B;
        top: 10px;
        z-index: 1000;
        font-weight: bold;
        right: 5px;
        z-index: 0; }
    .News_Box .News_Box_Envelope .News_Box_Envelope_Container img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 12px; }
  .News_Box .News_Box_Right {
    margin: 6px 0;
    flex-basis: 85%;
    overflow: hidden; }

.News_Box .News_Box_Title {
  font-size: 13px;
  font-weight: bold;
  color: black; }

.News_Box .News_Box_Summary {
  font-size: 12px;
  font-weight: bold;
  color: gray;
  white-space: nowrap;
  margin: 4px 0 0 0; }
  .News_Box .News_Box_Summary p {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis; }

.News_Box .News_Box_Img {
  width: 8px;
  padding: 14px 0; }

.News_Detail {
  display: flex;
  flex-direction: column;
  margin: 64px 0 0 0;
  background-color: #FAFBFC; }
  .News_Detail .Title_Date_Box {
    flex-basis: 100%;
    margin: 0 24px; }
  .News_Detail .News_Detail_Thumbnail {
    margin: auto; }

.News_Detail .News_Detail_Body {
  white-space: pre-line; }

.job-receipt-box {
  background: #0070E0;
  height: auto;
  flex-basis: 100%;
  overflow: auto; }
  .job-receipt-box.Has_Chat_Bar {
    padding: 65px 0 0 0; }

.job-receipt {
  height: auto;
  min-height: 300px;
  margin: 24px 16px; }
  .job-receipt .first-section {
    height: auto;
    border-radius: 8px;
    background: #FAFBFC;
    border-bottom: 2px dashed #0070E0;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px; }
    .job-receipt .first-section .job-receipt-header {
      display: flex;
      align-items: center; }
  .job-receipt .second-section,
  .job-receipt .third-section,
  .job-receipt .job-detail-section {
    background: #FAFBFC;
    position: relative;
    border-radius: 8px;
    margin-top: -1px; }
  .job-receipt .job-detail-section {
    border-bottom: 2px dashed #0070E0; }
  .job-receipt .second-section {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-bottom: 2px dashed #0070E0; }
    .job-receipt .second-section h5 {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: #0C87FA;
      margin: 0; }
  .job-receipt .job-receipt-logo {
    width: 29%;
    display: block;
    float: left;
    position: relative; }
    .job-receipt .job-receipt-logo .job-my-kuya-logo {
      width: 100px;
      height: 30px;
      margin: 16px; }
    .job-receipt .job-receipt-logo .detail-toggle {
      position: absolute; }
    .job-receipt .job-receipt-logo img {
      width: 76px;
      height: 22px;
      margin: 24px 20px 0 0px; }
  .job-receipt .credit-logo {
    width: 18% !important; }
  .job-receipt .master-card-number {
    width: 70%;
    display: block;
    float: left;
    overflow: auto; }
    .job-receipt .master-card-number .card-number {
      margin: 30px 0 0 0; }
    .job-receipt .master-card-number .detail-toggle {
      position: absolute;
      left: 40px;
      color: #0C87FA;
      font-weight: 600; }
  .job-receipt .job-receipt-date {
    width: 70%;
    display: block;
    float: left;
    overflow: auto; }
    .job-receipt .job-receipt-date h5 {
      margin: 16px;
      text-align: right;
      font-size: 12px; }

.request-again-box {
  flex: 1 1;
  margin: 24px; }
  .request-again-box .request-again {
    flex: 1 1;
    flex-shrink: 1;
    width: 100%; }
    .request-again-box .request-again .button-container {
      width: 100%; }

.talk-link-box {
  flex: 1 1;
  flex-shrink: 1;
  width: 100%; }
  .talk-link-box h3 {
    text-align: center;
    color: #0C87FA;
    font-weight: 600;
    line-height: 27px; }

.Job_Detail_Title {
  margin: 0;
  padding: 16px;
  font-weight: bold;
  font-size: 19px; }

.Job_Receipt_Help_Btn {
  background: #FAFBFC !important;
  color: #0C87FA !important;
  border: 1px solid #0C87FA !important;
  outline: none;
  box-shadow: none;
  width: 100%;
  border-radius: 5px !important;
  padding: 6px !important;
  margin: 8px 0 !important;
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px; }
  .Job_Receipt_Help_Btn .chat_Support_Receipt {
    margin: 0 4px; }
  .Job_Receipt_Help_Btn:disabled {
    background-color: #C5C5C5 !important; }

.hide {
  display: none; }

.job-receipt-home .title-bar-container {
  border-radius: 0 0  20px 20px; }
  .job-receipt-home .title-bar-container .title-bar-icon {
    display: flex; }
    .job-receipt-home .title-bar-container .title-bar-icon .heading {
      padding: 0 8px 0 16px; }
    .job-receipt-home .title-bar-container .title-bar-icon .lozenge-container {
      align-items: center; }
      .job-receipt-home .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; }

.job-receipt-cost {
  font-size: 18px;
  line-height: 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  color: #3B424A; }
  .job-receipt-cost .total-amount {
    margin-bottom: 0; }
  .job-receipt-cost .cost {
    margin-bottom: 0; }
  .job-receipt-cost .cost-text {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.25px;
    margin-bottom: 0; }

.job-receipt-keys-values {
  padding: 0 16px 16px; }

.Job-receipt-row hr {
  margin: 0px;
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
  overflow: hidden;
  border-top: 2px dotted #eeeeee; }

.Job-receipt-row .Job-receipt-title {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: #3B424A; }

.Job-receipt-row .Job-receipt-value {
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #3B424A; }

.Job-receipt-row {
  width: 100%;
  height: auto;
  min-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .Job-receipt-row.location-job-receipt {
    flex-direction: column;
    align-items: flex-start; }
    .Job-receipt-row.location-job-receipt .Job-receipt-value {
      max-width: 100%; }
    .Job-receipt-row.location-job-receipt .location-job-receipt-inner {
      display: flex;
      align-items: center; }
      .Job-receipt-row.location-job-receipt .location-job-receipt-inner .Dynamic_Parent_Icon {
        width: 24px;
        height: 24px; }
        .Job-receipt-row.location-job-receipt .location-job-receipt-inner .Dynamic_Parent_Icon img {
          width: 24px;
          height: 24px; }
      .Job-receipt-row.location-job-receipt .location-job-receipt-inner .Job_Receipt_Location_Address {
        font-weight: normal;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.25px;
        padding-left: 5px; }

.card-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }
  .card-container .icon-container {
    width: 32px; }
    .card-container .icon-container .mykoin {
      width: 16px;
      height: 16px;
      margin: 0 auto;
      max-width: 100%;
      display: block; }
    .card-container .icon-container .mc, .card-container .icon-container .visa {
      max-width: 100%;
      height: auto;
      display: block; }
    .card-container .icon-container .mc {
      width: 25px;
      margin: 0 auto; }
  .card-container p {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.25px;
    color: #3B424A;
    margin-bottom: 0;
    padding-left: 8px; }

.thank-using {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .thank-using p {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #3B424A; }
  .thank-using .website-name {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #B1B3B7; }

.Job-list-history {
  position: fixed;
  top: 60px;
  overflow-y: scroll;
  bottom: 0;
  right: 0;
  left: 0; }

.Job-list-history::-webkit-scrollbar {
  width: 0; }

.Job-list-history .Job-locations .address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.page-title {
  margin: 0 0 10px;
  padding: 65px 20px 0;
  font-size: 19px;
  font-weight: 600; }

.Parent_Jobs {
  position: fixed;
  top: 10px;
  overflow-y: scroll;
  bottom: 0;
  right: 0;
  left: 0; }

.Jobs-empty {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 1 100%; }
  .Jobs-empty .Home_Empty_Box Empty {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 1 1 100%; }

.Not_Found_Title_Job {
  margin: 0 0 16px 0;
  text-align: center;
  flex: 1 1;
  font-weight: bold;
  color: #3B424A;
  font-size: 18px; }

.Empty_Search_Text {
  text-align: center;
  margin: 0 24px;
  font-size: 12px;
  color: #3B424A; }

.Empty_Job_Btn {
  background-color: #0C87FA !important;
  color: #FAFBFC !important;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  border-radius: 3px;
  padding: 8px !important;
  text-transform: none;
  font-weight: bold !important;
  margin: 16px 24px !important;
  height: 40px;
  font-size: 16px !important; }
  .Empty_Job_Btn .Dynamic_Parent_Icon {
    height: auto !important; }
  .Empty_Job_Btn .Btn_Text {
    text-transform: none !important; }

.Empty_Down_Description_Jobs {
  text-align: left;
  margin: 0 8px 0 24px;
  font-size: 12px;
  color: #B1B3B7; }
  .Empty_Down_Description_Jobs span:nth-child(2) {
    font-weight: bold;
    color: #0C87FA; }

.Search_Empty_Jobs {
  margin: 0 auto 24px auto; }

.Active_Margin {
  margin: 0 8px 16px 24px !important; }

.Jobs_Empty_Box {
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: column; }

.my-jobs-tab {
  list-style: none;
  padding: 0;
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding: 0 24px; }
  .my-jobs-tab .tab-item-history {
    margin: 0 !important;
    padding: 0 12px; }
    .my-jobs-tab .tab-item-history:first-child {
      margin-left: 0 !important;
      padding-left: 0; }

.job-panel .Jobs-box:first-child {
  margin: 0 0 16px 0 !important; }

.Jobs-box {
  background-color: #FAFBFC;
  box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12);
  display: flex;
  align-items: center;
  height: 96px;
  overflow: hidden;
  margin: 16px 0;
  padding: 16px;
  border-radius: 5px; }

.job-box-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 16px; }

.Jobs-box-heading-title {
  margin-top: 4px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }
  .Jobs-box-heading-title .title {
    font-weight: bold;
    color: #3B424A;
    font-size: 13px; }
  .Jobs-box-heading-title .address {
    font-size: 12px;
    font-weight: 300;
    color: #3B424A; }

.Jobs-box-heading-startdate {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Status_State {
  margin: 0 !important; }

.Jobs-box-heading-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #898E92;
  display: flex;
  align-items: center; }
  .Jobs-box-heading-date p {
    margin: 0; }
  .Jobs-box-heading-date span:nth-child(1) {
    padding: 0 4px 0 0; }
  .Jobs-box-heading-date .job-price {
    display: flex;
    align-items: center; }
    .Jobs-box-heading-date .job-price .dot-divider {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #898E92;
      margin: 0 4px; }

.Jobs-box-heading-status {
  padding: 0 3px;
  background-color: gray;
  text-align: center;
  border-radius: 4px;
  color: #FAFBFC;
  font-weight: normal;
  outline: none;
  border: none;
  font-size: 10px;
  line-height: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .Jobs-box-heading-status.orange {
    background-color: #ED7E00; }
  .Jobs-box-heading-status.green {
    background-color: #15BD5D; }
  .Jobs-box-heading-status.yellow {
    background-color: #FEC132; }

.Jobs-box-heading-status.Active {
  background-color: #15BD5D; }

.job-img-box {
  display: flex; }
  .job-img-box img {
    width: 32px;
    height: 32px; }

.job-img-box {
  display: flex; }
  .job-img-box img {
    width: 32px;
    height: 32px; }

.Job_Rating {
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #0070E0;
  z-index: 1000; }
  .Job_Rating .Job_Rating_Parent {
    position: relative;
    height: auto;
    display: flex;
    flex-basis: 100%; }
  .Job_Rating .White_Box {
    display: flex;
    height: 90%;
    background: #FAFBFC;
    border-radius: 0 0 20px 20px;
    overflow: hidden; }
    .Job_Rating .White_Box.not-show-top-image {
      height: 85%; }
      .Job_Rating .White_Box.not-show-top-image + .Blue_Box {
        height: 15%; }
  .Job_Rating .Job_Rating_Box {
    display: flex;
    flex-direction: column;
    flex: 1 1; }
    .Job_Rating .Job_Rating_Box .title-bar-container .title-bar-icon {
      display: flex; }
      .Job_Rating .Job_Rating_Box .title-bar-container .title-bar-icon .back-button {
        display: none; }
      .Job_Rating .Job_Rating_Box .title-bar-container .title-bar-icon .heading {
        padding: 0 8px 0 0; }
      .Job_Rating .Job_Rating_Box .title-bar-container .title-bar-icon .lozenge-container {
        align-items: center; }
        .Job_Rating .Job_Rating_Box .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner {
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content; }
    .Job_Rating .Job_Rating_Box .Job_Rating_Chat_Section {
      height: auto; }
    .Job_Rating .Job_Rating_Box .Job_Rating_Hand_Shake {
      padding: 65px 20px 0;
      background-color: #FAFBFC; }
      .Job_Rating .Job_Rating_Box .Job_Rating_Hand_Shake .Job_Rating_S_1_Img img {
        display: block;
        max-width: 100%;
        height: auto; }
    .Job_Rating .Job_Rating_Box .Job_Rating_Partner {
      padding: 20px;
      background-color: #FAFBFC; }
    .Job_Rating .Job_Rating_Box .Job_Rating_Thumb {
      display: flex;
      flex-basis: 100%;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      margin: auto;
      padding: 0 20px;
      border-radius: 8px; }
      .Job_Rating .Job_Rating_Box .Job_Rating_Thumb .Thumb_Box {
        box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12);
        flex-basis: 50%;
        height: 56px;
        border-radius: 8px;
        background-color: #FAFBFC; }
        .Job_Rating .Job_Rating_Box .Job_Rating_Thumb .Thumb_Box:nth-child(1) {
          margin: 0 8px 0 0; }
        .Job_Rating .Job_Rating_Box .Job_Rating_Thumb .Thumb_Box:nth-child(2) {
          margin: 0 0 0 8px; }

.Job_Rating .modal-kuya-container {
  width: 120px;
  height: 120px;
  background-color: transparent;
  /* padding-top: 19px; */
  border-radius: 50%;
  border: 3px solid white;
  position: relative;
  text-align: center;
  margin: auto; }

.Job_Rating .modal-kuya-container img {
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  /* margin-top: -5px; */ }

.Job_Rating .Job_Rating-section .Chat-support-icon {
  width: 20px;
  float: right; }

.Job_Rating .Job_Rating-completed {
  text-align: center;
  margin-bottom: 5px; }

.Job_Rating .Job_Rating-completed h3 {
  font-size: 18px;
  font-weight: 900;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #000; }

.Job_Rating .Job_Rating-completed p {
  margin: 0;
  color: #949494; }

.Job_Rating .job-rating-info-container .job-rating-info-inner {
  padding: 10px 5px;
  border-bottom: 1px solid #EDECEC;
  display: flex;
  justify-content: space-between; }

.Job_Rating .job-rating-info-container .job-rating-info-inner.no-border-bottom {
  border-bottom: 0; }

.Job_Rating .job-rating-info-container .job-rating-info-inner.border-top {
  border-top: 1px solid #EDECEC; }

.Job_Rating .Job_Rating-completed p {
  margin: 0;
  color: #949494; }

.Job_Rating hr {
  margin-top: 8px;
  margin-bottom: 8px; }

.Job_Rating .Job_Rating-name .Job_Rating-partner-name {
  color: #949494;
  font-size: 18px;
  margin: 0; }

.Job_Rating .Job_Rating-name .Job-co-partner {
  justify-content: center;
  color: #949494;
  font-size: 14px; }

.Job_Rating .text-title {
  color: #949494; }

.Job_Rating .text-display {
  color: black;
  font-weight: bold; }

.Job_Rating .text-display p {
  color: black;
  font-weight: bold;
  display: inline-block;
  float: right;
  margin: 0; }

.Job_Rating .payment-method-img {
  display: inline-block;
  max-width: 30px;
  margin-top: -1px;
  max-height: 22px; }

.Job_Rating .text-display.duration {
  font-family: 'digital';
  font-size: 16px; }

.Blue_Box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0070E0;
  height: 10%;
  padding: 20px; }
  .Blue_Box .See_Job_Right {
    flex-basis: 60%;
    max-width: 60%; }
  .Blue_Box .See_Job_Left {
    flex-basis: 40%;
    max-width: 40%;
    padding-right: 16px; }
    .Blue_Box .See_Job_Left .Total_Amount_Paid {
      color: #E2EEF8;
      font-size: 12px;
      text-align: right;
      margin: 0;
      line-height: 18px; }
    .Blue_Box .See_Job_Left .Total_Amount_Cost {
      color: #FAFBFC;
      font-size: 19px;
      font-weight: 600;
      margin: 0;
      text-align: right; }

.See_Job_Receipt_Btn {
  width: 100%; }
  .See_Job_Receipt_Btn .MuiButtonBase-root {
    background-color: #FAFBFC !important;
    color: #0C87FA !important;
    width: 100%;
    text-transform: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.25px;
    padding: 10px; }
    .See_Job_Receipt_Btn .MuiButtonBase-root:hover, .See_Job_Receipt_Btn .MuiButtonBase-root:focus, .See_Job_Receipt_Btn .MuiButtonBase-root:active {
      background-color: #FAFBFC !important;
      color: #0C87FA !important; }
  .See_Job_Receipt_Btn .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    padding: 10px; }

.Job_Rating_Completed_Section_1 {
  display: flex;
  flex: 1 1;
  flex-basis: 100%;
  flex-direction: column; }
  .Job_Rating_Completed_Section_1 .Job_Rating_S_1_Content {
    margin: 16px 0; }
    .Job_Rating_Completed_Section_1 .Job_Rating_S_1_Content h3 {
      font-size: 19px;
      font-weight: bold;
      color: #3B424A;
      line-height: 28px;
      margin: 0; }
    .Job_Rating_Completed_Section_1 .Job_Rating_S_1_Content h5 {
      color: #898E92;
      font-weight: 400;
      font-size: 15px;
      margin: 5px 0 0; }

.Job_Rating_Buttons {
  display: flex;
  flex: 1 1;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin: 24px 0 0; }
  .Job_Rating_Buttons .Job_Rating_Buttons_Skipp {
    flex-basis: 35%; }
    .Job_Rating_Buttons .Job_Rating_Buttons_Skipp .J_R_B_Skipp {
      text-transform: none !important;
      width: 100%;
      outline: none;
      box-shadow: none;
      background-color: #FAFBFC;
      color: #0488FA;
      border: none;
      font-weight: 600;
      border-radius: 3px;
      padding: 12px; }
  .Job_Rating_Buttons .Job_Rating_Buttons_Submit {
    flex-basis: 100%; }
    .Job_Rating_Buttons .Job_Rating_Buttons_Submit .J_R_B_Submit {
      text-transform: none !important;
      width: 100%;
      outline: none;
      box-shadow: none;
      font-weight: 600;
      background-color: #0488FA;
      color: #FAFBFC;
      border: none;
      border-radius: 5px;
      padding: 12px; }
      .Job_Rating_Buttons .Job_Rating_Buttons_Submit .J_R_B_Submit.Mui-disabled {
        background-color: #81B7E9;
        cursor: not-allowed; }

.Job_Rating_Bottom_Sheet .Sheet_Content {
  width: 100%; }

.Job_Rating_Partner_Section {
  display: flex;
  flex: 1 1;
  flex-basis: 100%;
  flex-direction: column;
  padding: 32px 0 0 0;
  border-top: 1px solid #DBDBDB; }
  .Job_Rating_Partner_Section .Partner_Section_Top {
    display: flex;
    align-items: center; }
    .Job_Rating_Partner_Section .Partner_Section_Top .Partner_Section_Img img {
      width: 48px;
      height: 48px;
      border-radius: 100%; }
    .Job_Rating_Partner_Section .Partner_Section_Top .Partner_Section_Name {
      display: flex;
      flex-direction: column;
      padding-left: 16px; }
      .Job_Rating_Partner_Section .Partner_Section_Top .Partner_Section_Name h3 {
        font-weight: 600;
        line-height: 28px;
        margin: 0;
        font-size: 19px;
        text-align: left;
        color: #3B424A; }
      .Job_Rating_Partner_Section .Partner_Section_Top .Partner_Section_Name span {
        color: #898E92;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: left; }
  .Job_Rating_Partner_Section .Partner_Section_Text {
    margin: 24px 0 0; }
    .Job_Rating_Partner_Section .Partner_Section_Text h5 {
      text-align: left;
      font-size: 15px;
      line-height: 22px;
      color: #3B424A;
      margin: 0; }

.Job_Rating_Thumb img {
  /* padding-top: 22px; */ }

.Job_Rating_Thumb img.up {
  width: 48px;
  height: 48px;
  margin: auto; }

.Job_Rating_Thumb img.down {
  width: 48px;
  height: 48px;
  margin: auto; }

.Job_Rating_Thumb_Selected {
  background-color: #0C87FA !important; }

.Job_Rating .Prevent_Touch {
  -webkit-transform: translate(0px, -100%) !important;
          transform: translate(0px, -100%) !important; }

.Job-rating-compliments {
  padding: 24px 20px;
  background-color: #FAFBFC;
  border-radius: 20px 20px 0 0; }

.Job_Rating_Items {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap; }
  .Job_Rating_Items .Job-Rating-compliments-item {
    flex: 1 1 50%;
    margin: 0 0 16px;
    padding: 0 8px; }
    .Job_Rating_Items .Job-Rating-compliments-item label {
      display: flex;
      align-items: center;
      background-color: #FAFBFC;
      color: #949494;
      border-radius: 8px;
      font-weight: 500;
      width: 100%;
      height: 100%;
      margin: 0;
      box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12); }
      .Job_Rating_Items .Job-Rating-compliments-item label .J_R_Complements_Label_Icon {
        float: left;
        width: 25%;
        height: 100%; }
      .Job_Rating_Items .Job-Rating-compliments-item label .J_R_Complements_Label_Title {
        padding: 16px; }
        .Job_Rating_Items .Job-Rating-compliments-item label .J_R_Complements_Label_Title h5 {
          color: #3B424A;
          font-size: 13px;
          margin: 0;
          font-weight: 600;
          line-height: 24px; }

.Job-rating-compliments .Job-Rating-compliments-item input[type=checkbox] {
  display: none; }

.Job_Rating_Comment_Text_Area {
  height: 64px !important;
  width: 100%; }

.Job-rating-compliments input[type=checkbox]:checked ~ .check {
  background: #0057AD; }
  .Job-rating-compliments input[type=checkbox]:checked ~ .check .J_R_Complements_Label_Title h5 {
    color: #fff; }

.Feed_Back_Text {
  text-align: center;
  font-weight: 400;
  color: #3B424A;
  margin: 8px 0 24px; }

.open-jobs-container h2 {
  color: #898E92;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0;
  line-height: 18px; }

.matching-process {
  padding: 0 20px; }
  .matching-process .matching-process-title {
    padding: 24px 0;
    border-bottom: 1px solid #DBDBDB;
    display: flex;
    align-items: center; }
    .matching-process .matching-process-title .partner-icon {
      width: 48px;
      height: 48px; }
      .matching-process .matching-process-title .partner-icon img {
        border-radius: 50%;
        max-width: 100%;
        width: 100%;
        display: block;
        height: 100%; }
    .matching-process .matching-process-title .booking-button-icon {
      position: relative; }
      .matching-process .matching-process-title .booking-button-icon .kuya-headshot {
        width: 38px;
        height: 38px; }
      .matching-process .matching-process-title .booking-button-icon .icon {
        position: absolute;
        top: -10px;
        right: -13px;
        width: 20px;
        height: 24px; }
    .matching-process .matching-process-title .title {
      padding-left: 24px;
      display: flex;
      flex-direction: column; }
      .matching-process .matching-process-title .title p {
        margin-bottom: 0;
        color: #3B424A; }
      .matching-process .matching-process-title .title .text-secondary {
        color: #898E92;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px; }
  .matching-process .matching-process-container .matching-process-row {
    display: flex;
    align-items: center;
    padding-left: 4px;
    margin-bottom: 4px; }
    .matching-process .matching-process-container .matching-process-row .job-state {
      width: 16px;
      height: 16px; }
    .matching-process .matching-process-container .matching-process-row.payment {
      padding-left: 3px; }
    .matching-process .matching-process-container .matching-process-row .billing-card-image.mc {
      width: 18px;
      height: 11px; }
      .matching-process .matching-process-container .matching-process-row .billing-card-image.mc + p {
        padding-left: 14px; }
    .matching-process .matching-process-container .matching-process-row .billing-card-image.visa {
      width: 20px;
      height: 7px; }
      .matching-process .matching-process-container .matching-process-row .billing-card-image.visa + p {
        padding-left: 13px; }
    .matching-process .matching-process-container .matching-process-row p {
      margin-bottom: 0;
      color: #3B424A;
      padding-left: 16px;
      height: 20px;
      overflow: hidden;
      width: 100%; }
    .matching-process .matching-process-container .matching-process-row .icon-card.mc {
      width: 16px;
      height: 10px; }
    .matching-process .matching-process-container .matching-process-row .icon-card.visa {
      width: 16px;
      height: 6px; }
    .matching-process .matching-process-container .matching-process-row .mykoin-image {
      width: 13px;
      height: 13px;
      padding: 1px; }
      .matching-process .matching-process-container .matching-process-row .mykoin-image + p {
        padding-left: 19px; }
  .matching-process .tip {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #898E92;
    margin-bottom: 0; }

.request-placed .title-bar-container .title-bar-icon {
  display: flex; }
  .request-placed .title-bar-container .title-bar-icon .heading {
    padding: 0 8px 0 0; }
  .request-placed .title-bar-container .title-bar-icon .lozenge-container {
    align-items: center; }
    .request-placed .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }

.request-placed .matching-process .matching-process-container {
  padding: 40px 20px 100px 16px; }
  .request-placed .matching-process .matching-process-container .button-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: #FAFBFC; }
    .request-placed .matching-process .matching-process-container .button-container button {
      width: 100%; }

.request-placed-image {
  position: relative;
  padding-top: 65px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (max-width: 400px) {
  .request-placed .matching-process .matching-process-container {
    padding: 0; }
  .request-placed .request-placed-image {
    display: none; }
  .request-placed .matching-process {
    padding-top: 60px; }
    .request-placed .matching-process .matching-process-title {
      margin-bottom: 15px; } }

.open-job-info.request-placed .matching-process-container {
  padding: 0; }
  .open-job-info.request-placed .matching-process-container .button-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px; }
    .open-job-info.request-placed .matching-process-container .button-container button {
      width: 100%; }

.open-job-info.request-placed .matching-process {
  position: fixed;
  padding-top: 60px;
  max-width: 412px;
  height: 100vh;
  width: 100%; }
  .open-job-info.request-placed .matching-process .matching-process-title {
    margin-bottom: 10px; }
  .open-job-info.request-placed .matching-process .open-job-info-button {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 20px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FAFBFC; }
    .open-job-info.request-placed .matching-process .open-job-info-button .button-container {
      position: relative;
      padding: 0; }
      .open-job-info.request-placed .matching-process .open-job-info-button .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        width: 100%; }
      .open-job-info.request-placed .matching-process .open-job-info-button .button-container.cancel-booking {
        margin-top: 10px;
        border: 1px solid #E34B34;
        border-radius: 5px; }
        .open-job-info.request-placed .matching-process .open-job-info-button .button-container.cancel-booking .MuiButtonBase-root.MuiButton-root.MuiButton-text {
          background-color: transparent;
          color: #E34B34; }

.Wrap_Job_Information {
  width: 100%;
  height: auto;
  background: #FAFBFC; }
  .Wrap_Job_Information .title-bar-container {
    position: relative; }

.Job_Information {
  flex: 0 0 100%;
  height: auto;
  background: #FAFBFC;
  margin-top: 16px; }

.Job_Information_Partner {
  margin-top: 36px; }
  .Job_Information_Partner .Section_1 {
    display: flex;
    padding: 0 16px; }
    .Job_Information_Partner .Section_1 .Avatar {
      width: 48px;
      height: 48px; }
      .Job_Information_Partner .Section_1 .Avatar img {
        width: 48px;
        height: 48px;
        display: inherit; }
  .Job_Information_Partner .Job_Information_Section1_Right_Box {
    padding-left: 16px; }
  .Job_Information_Partner .Partner_Name {
    color: #3B424A;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin: 0; }
  .Job_Information_Partner .Co_Partner_Name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #898E92;
    letter-spacing: 0.4px;
    margin: 0; }
  .Job_Information_Partner .Partner_Star {
    width: 15px;
    height: 15px;
    margin-bottom: 5px;
    margin-left: 10px; }
  .Job_Information_Partner .Partner_Star {
    display: inline-block;
    font-size: 18px;
    margin-left: 5px; }

.Job_Information_Thumb {
  display: flex;
  align-items: center;
  padding: 20px 32px 8px; }
  .Job_Information_Thumb .Img_Box {
    width: 24px;
    height: 24px; }
    .Job_Information_Thumb .Img_Box img {
      width: 24px;
      height: 24px; }

.Job_Information_Rate {
  display: flex;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 8px; }

.thumbs-title {
  margin: 0 0 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #3B424A;
  letter-spacing: 0.25px; }

.job-comment {
  padding: 20px 24px;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: #3B424A; }

.Job_Info_Thumb {
  width: 48px;
  height: 48px; }

.Img_Box {
  width: 10%; }
  .Img_Box img {
    width: 24px;
    height: 24px; }

.Title_Box {
  width: 90%; }

.Img_Box_Rate {
  width: 24px;
  height: 24px; }
  .Img_Box_Rate img {
    width: 24px;
    height: 24px; }

.Job-locations {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #DBDBDB;
  padding: 12px 0;
  flex-direction: column; }
  .Job-locations .review-booking-item-inner {
    display: flex;
    align-items: center;
    margin-bottom: 4px; }
    .Job-locations .review-booking-item-inner p {
      margin-bottom: 0;
      padding-left: 16px; }

.queued-jobs-container {
  background-color: #FAFBFC; }
  .queued-jobs-container .title {
    text-align: center;
    margin: 0;
    padding: 30px 20px 0px;
    margin-bottom: 0;
    color: #3B424A;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px; }
  .queued-jobs-container .matching-process-container {
    padding: 10px 20px 10px 16px; }
    .queued-jobs-container .matching-process-container .matching-process-row {
      display: flex;
      align-items: center;
      padding-left: 4px;
      margin-bottom: 4px; }
      .queued-jobs-container .matching-process-container .matching-process-row.payment {
        padding-left: 3px; }
      .queued-jobs-container .matching-process-container .matching-process-row .billing-card-image.mc {
        width: 18px;
        height: 11px; }
        .queued-jobs-container .matching-process-container .matching-process-row .billing-card-image.mc + p {
          padding-left: 14px; }
      .queued-jobs-container .matching-process-container .matching-process-row .billing-card-image.visa {
        width: 20px;
        height: 7px; }
        .queued-jobs-container .matching-process-container .matching-process-row .billing-card-image.visa + p {
          padding-left: 13px; }
      .queued-jobs-container .matching-process-container .matching-process-row p {
        margin-bottom: 0;
        color: #3B424A;
        padding-left: 16px;
        height: 20px;
        overflow: hidden;
        width: 100%; }
      .queued-jobs-container .matching-process-container .matching-process-row .icon-card.mc {
        width: 16px;
        height: 10px; }
      .queued-jobs-container .matching-process-container .matching-process-row .icon-card.visa {
        width: 16px;
        height: 6px; }
      .queued-jobs-container .matching-process-container .matching-process-row .mykoin-image {
        width: 13px;
        height: 13px;
        padding: 1px; }
        .queued-jobs-container .matching-process-container .matching-process-row .mykoin-image + p {
          padding-left: 19px; }
  .queued-jobs-container .tip {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #898E92;
    margin-bottom: 0;
    padding: 20px; }
  .queued-jobs-container .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; }
    .queued-jobs-container .button-container button {
      width: 100%; }
  .queued-jobs-container .queued-options-list {
    padding: 0 20px; }
    .queued-jobs-container .queued-options-list .queued-option.disabled-option label {
      background-color: #DBDBDB;
      border-radius: 5px;
      cursor: not-allowed; }
  .queued-jobs-container ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
    overflow: auto; }
    .queued-jobs-container ul li {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 16px; }
      @media (min-width: 400px) {
        .queued-jobs-container ul li {
          margin-bottom: 24px; } }
      .queued-jobs-container ul li input[type=radio] {
        position: absolute;
        visibility: hidden; }
      .queued-jobs-container ul li .check {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear; }
        .queued-jobs-container ul li .check label {
          display: flex;
          position: relative;
          z-index: 9;
          cursor: pointer;
          -webkit-transition: all 0.25s linear;
          transition: all 0.25s linear;
          margin-bottom: 0;
          padding: 10px 16px 10px 10px;
          font-weight: normal;
          align-items: center; }
          .queued-jobs-container ul li .check label .content {
            padding-left: 5px; }
          .queued-jobs-container ul li .check label p {
            color: #3B424A;
            margin-bottom: 0; }
          .queued-jobs-container ul li .check label span {
            color: #898E92; }
        .queued-jobs-container ul li .check::before {
          display: block;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 8px;
          margin: auto;
          transition: background 0.25s linear;
          -webkit-transition: background 0.25s linear;
          background-color: #FAFBFC;
          border-radius: 8px; }
  .queued-jobs-container input[type=radio]:checked ~ .check::before {
    background: #0057AD;
    box-shadow: none; }
  .queued-jobs-container input[type=radio]:checked ~ .check label p, .queued-jobs-container input[type=radio]:checked ~ .check label span {
    color: #FAFBFC; }

.datepicker-modal {
  margin: 0 auto;
  left: 0;
  max-width: 414px; }

.datepicker.ios {
  border-radius: 20px 20px 0 0; }
  .datepicker.ios .queued-jobs-container-title {
    text-align: center;
    margin: 0;
    padding: 30px 20px 0px;
    margin-bottom: 0;
    color: #3B424A;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px; }
  .datepicker.ios .datepicker-content {
    padding: 0 20px; }
    .datepicker.ios .datepicker-content .datepicker-col-1:first-child .datepicker-wheel {
      border-radius: 5px 0 0 5px; }
    .datepicker.ios .datepicker-content .datepicker-col-1:last-child .datepicker-wheel {
      border-radius: 0 5px 5px 0; }
  .datepicker.ios .datepicker-wheel {
    border: 0;
    background-color: #DBDBDB; }
  .datepicker.ios .datepicker-navbar {
    position: relative !important;
    padding: 20px !important;
    display: flex;
    flex-direction: row-reverse; }
    .datepicker.ios .datepicker-navbar .datepicker-navbar-btn {
      text-decoration: none; }
      .datepicker.ios .datepicker-navbar .datepicker-navbar-btn:first-child {
        flex: 1 1 70%;
        background: #0C87FA;
        border-radius: 5px;
        color: #FAFBFC;
        text-transform: none;
        display: flex;
        justify-content: center;
        align-items: center; }
      .datepicker.ios .datepicker-navbar .datepicker-navbar-btn:last-child {
        flex: 1 1 30%;
        color: #FC6A54;
        display: flex;
        justify-content: center;
        align-items: center; }

.Wrap_Job_Information {
  width: 100%;
  height: auto;
  background: #FAFBFC; }
  .Wrap_Job_Information .title-bar-container {
    position: relative; }

.Job_Information {
  flex: 0 0 100%;
  height: auto;
  background: #FAFBFC;
  margin-top: 16px; }

.register-form-container {
  display: block;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden; }
  .register-form-container::-webkit-scrollbar {
    width: 0; }
  .register-form-container .title {
    color: #3B424A;
    padding: 64px 20px 0;
    margin: 0 0 16px; }
  .register-form-container .register-form {
    padding: 0 20px 60px;
    position: relative; }
  .register-form-container .sticky-button button {
    width: 100%; }

.form-group-container {
  background-color: #FAFBFC;
  border-bottom: 1px solid #DBDBDB;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  position: relative;
  padding: 5px 5px 0; }
  .form-group-container.form-group-contain-icon {
    display: flex;
    padding: 5px 8px; }
    .form-group-container.form-group-contain-icon .icon {
      margin-right: 5px; }
  .form-group-container label {
    display: flex;
    color: #898E92;
    margin: 0; }
  .form-group-container input,
  .form-group-container .form-control {
    border: 0;
    box-shadow: none;
    padding: 0;
    color: #181A1E;
    border-radius: 8px;
    background-color: transparent;
    height: 28px; }
    .form-group-container input:focus,
    .form-group-container .form-control:focus {
      outline: none; }
  .form-group-container.focused {
    border: 1px solid #81B7E9;
    border-radius: 8px;
    -webkit-transition: border .2s linear;
    transition: border .2s linear; }
    .form-group-container.focused label {
      color: #181A1E;
      -webkit-transition: color .1s linear;
      transition: color .1s linear; }
  .form-group-container.input-error {
    border: 1px solid #E34B34;
    border-radius: 8px;
    -webkit-transition: border-color .2s linear;
    transition: border-color .2s linear; }
    .form-group-container.input-error label {
      color: #E34B34;
      -webkit-transition: color .1s linear;
      transition: color .1s linear; }
    .form-group-container.input-error + .error {
      color: #E34B34;
      padding-left: 7px; }
  .form-group-container .Form-select {
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 0;
    padding-left: 5px;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none; }
  .form-group-container .form-select-box {
    display: inline-block;
    width: 100%;
    box-shadow: none;
    outline: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    height: 20px;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none; }
  .form-group-container .form-select-box.selected-city {
    color: #000; }

.welcome {
  background-color: #FAFBFC;
  padding: 0 20px; }
  .welcome .welcome-img {
    margin: 40px 0 0; }
  .welcome .welcome-title {
    color: #3B424A;
    margin: 0; }
  .welcome .welcome-subtitle {
    color: #3B424A; }
  .welcome .welcome-agree {
    color: #B1B3B7;
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px; }
  .welcome .welcome-agree-link {
    color: #B1B3B7;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 0; }
  .welcome .welcome-agree-policy,
  .welcome .welcome-agree-terms {
    color: #0C87FA;
    text-decoration: underline;
    margin: 0 5px;
    font-weight: 800;
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px; }
  .welcome .description {
    margin: 20px 0;
    color: #2F353B; }
  .welcome .enter-phone-number {
    display: flex;
    width: 100%;
    padding: 25px 0; }
    .welcome .enter-phone-number .country-code {
      flex-basis: 25%;
      max-width: 25%;
      padding-right: 8px; }
      .welcome .enter-phone-number .country-code .form-group-container {
        padding: 5px; }
        @media (min-width: 400px) {
          .welcome .enter-phone-number .country-code .form-group-container {
            padding: 5px 8px; } }
      .welcome .enter-phone-number .country-code label {
        color: #B1B3B7; }
      .welcome .enter-phone-number .country-code .welcome-select-country {
        background-color: #FAFBFC;
        width: 100%;
        border-radius: 8px;
        outline: none;
        -webkit-appearance: none;
        text-align: center;
        border: none;
        padding: 4px 0; }
        @media (min-width: 400px) {
          .welcome .enter-phone-number .country-code .welcome-select-country {
            padding: 4px 8px 4px 0; } }
      .welcome .enter-phone-number .country-code .welcome-select-country:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #FAFBFC; }
      .welcome .enter-phone-number .country-code .welcome-select-country-firefox {
        padding-left: 0 !important; }
    .welcome .enter-phone-number .phone-number {
      flex-basis: 75%;
      max-width: 75%;
      padding-left: 8px; }
      .welcome .enter-phone-number .phone-number .phone-number-container {
        padding: 5px 8px; }
        .welcome .enter-phone-number .phone-number .phone-number-container input {
          width: 100%;
          padding: 0;
          color: #181A1E; }
          .welcome .enter-phone-number .phone-number .phone-number-container input:focus {
            outline: none;
            box-shadow: none; }
          .welcome .enter-phone-number .phone-number .phone-number-container input::-webkit-input-placeholder {
            color: #B1B3B7; }
          .welcome .enter-phone-number .phone-number .phone-number-container input::-moz-placeholder {
            color: #B1B3B7; }
          .welcome .enter-phone-number .phone-number .phone-number-container input:-ms-input-placeholder {
            color: #B1B3B7; }
          .welcome .enter-phone-number .phone-number .phone-number-container input::-ms-input-placeholder {
            color: #B1B3B7; }
          .welcome .enter-phone-number .phone-number .phone-number-container input::placeholder {
            color: #B1B3B7; }
  .welcome .welcome-being-mykuya {
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    padding: 0 20px; }
    .welcome .welcome-being-mykuya .text-info {
      color: #3B424A;
      margin: 16px 0; }
      @media (min-width: 400px) {
        .welcome .welcome-being-mykuya .text-info {
          margin: 24px 0; } }
    .welcome .welcome-being-mykuya a {
      color: #0C87FA;
      font-weight: bold;
      display: block; }
  .welcome .continue-auth {
    margin-bottom: 25px; }
    .welcome .continue-auth .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%; }

.enter-code-description {
  padding: 65px 20px 0;
  margin-bottom: 24px; }
  .enter-code-description p {
    margin-bottom: 0; }

.enter-code-inputs {
  padding: 0 20px; }
  .enter-code-inputs .signup-input {
    background-color: #FAFBFC;
    border-radius: 8px;
    border: 1px solid transparent;
    margin-right: 8px;
    width: 40px !important;
    height: 50px;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(24, 26, 30, 0.15));
            filter: drop-shadow(0px 2px 4px rgba(24, 26, 30, 0.15));
    caret-color: #0C87FA; }
    .enter-code-inputs .signup-input.focused {
      outline: none;
      border-color: #0C87FA; }
    @media (min-width: 400px) {
      .enter-code-inputs .signup-input {
        width: 48px !important;
        height: 58px; } }
  .enter-code-inputs .signup-input-conntainer.error .signup-input {
    border-color: #E34B34; }

.check-code .error {
  color: #E34B34;
  margin-bottom: 0; }

.check-code .phone-number {
  font-weight: bold;
  font-size: 17px; }

.check-code .receive {
  padding: 20px; }
  .check-code .receive .didnt-receive {
    color: #898E92;
    margin: 0;
    font-size: 12px;
    line-height: 18px; }
  .check-code .receive .receive-new-text {
    color: #3B424A;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px; }
  .check-code .receive .receive-new {
    background-color: transparent;
    border: none;
    padding: 0;
    color: #0C87FA;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold; }

.avatar {
  width: 56px;
  height: 56px; }
  .avatar img {
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%; }

.button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  background: #0C87FA;
  border-radius: 5px;
  color: #FAFBFC;
  text-transform: none; }
  .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled {
    background-color: #E2EEF8;
    color: #81B7E9; }

.button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  border-color: #0C87FA;
  border-radius: 5px;
  color: #0C87FA;
  background-color: #FAFBFC;
  border: 1px solid #0C87FA; }
  .button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled {
    background-color: #E2EEF8;
    color: #81B7E9; }

.request-again {
  width: 100%; }
  .request-again .MuiButtonBase-root {
    width: 100%;
    padding: 14px !important; }
    .request-again .MuiButtonBase-root .MuiButton-label {
      font-size: 18px !important; }

.label-with-icon-container {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .label-with-icon-container .label-with-icon-inner {
    display: flex;
    align-items: center; }
    .label-with-icon-container .label-with-icon-inner.vertical {
      flex-direction: column; }
      .label-with-icon-container .label-with-icon-inner.vertical .label-with-icon-title {
        margin-top: 10px;
        margin-left: 0; }
    .label-with-icon-container .label-with-icon-inner .icon {
      width: 32px;
      height: 32px; }
      .label-with-icon-container .label-with-icon-inner .icon img {
        max-width: 100%;
        height: auto;
        display: block; }
    .label-with-icon-container .label-with-icon-inner .label-with-icon-title {
      margin-left: 6px; }
      .label-with-icon-container .label-with-icon-inner .label-with-icon-title p {
        margin-bottom: 0; }

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 26, 30, 0.7);
  z-index: 1051;
  max-width: 414px;
  margin: 0 auto; }

.lds-default {
  display: inline-block;
  position: absolute;
  width: 67px;
  height: 67px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: lds-default 1.2s linear infinite;
          animation: lds-default 1.2s linear infinite; }

.lds-default div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 30px;
  left: 59px; }

.lds-default div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 15px;
  left: 55px; }

.lds-default div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 4px;
  left: 45px; }

.lds-default div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 0;
  left: 30px; }

.lds-default div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 4px;
  left: 15px; }

.lds-default div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 15px;
  left: 4px; }

.lds-default div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 30px;
  left: 0px; }

.lds-default div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 45px;
  left: 4px; }

.lds-default div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 55px;
  left: 15px; }

.lds-default div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 59px;
  left: 30px; }

.lds-default div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 55px;
  left: 45px; }

.lds-default div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 45px;
  left: 55px; }

@-webkit-keyframes lds-default {
  0%, 20%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2); } }

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2); } }

.lozenge-container {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .lozenge-container .lozenge-inner {
    border-radius: 4px; }
    .lozenge-container .lozenge-inner.color-green {
      background-color: #15BD5D; }
    .lozenge-container .lozenge-inner.color-yellow {
      background-color: #F2BA1F; }
    .lozenge-container .lozenge-inner.color-orange {
      background-color: #ED7E00; }
    .lozenge-container .lozenge-inner.color-red {
      background-color: #E34B34; }
    .lozenge-container .lozenge-inner.color-violet {
      background-color: #581C8A; }
    .lozenge-container .lozenge-inner.color-gray {
      background-color: #DBDBDB; }
      .lozenge-container .lozenge-inner.color-gray .lozenge-title p {
        color: #3B424A; }
    .lozenge-container .lozenge-inner.color-gunmetal {
      background-color: #2F353B; }
    .lozenge-container .lozenge-inner.color-charcoal {
      background-color: #3B424A; }
    .lozenge-container .lozenge-inner .lozenge-title {
      padding: 0 5px; }
      .lozenge-container .lozenge-inner .lozenge-title p {
        margin-bottom: 0;
        color: #FAFBFC; }

.tab-container {
  border-radius: 100px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between; }
  .tab-container .tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3px 12px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .tab-container .tab:hover {
      background-color: #E2EEF8; }
  .tab-container .segmented-control {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #0070E0;
    border-bottom: 1px solid #0070E0;
    padding: 3px 12px;
    color: #0070E0; }
    .tab-container .segmented-control:first-child {
      border-left: 1px solid #0070E0;
      border-right: 1px solid #0070E0;
      border-radius: 100px 0px 0px 100px; }
    .tab-container .segmented-control:last-child {
      border-right: 1px solid #0070E0;
      border-left: 1px solid #0070E0;
      border-radius: 0 100px 100px 0px; }
    .tab-container .segmented-control p {
      margin-bottom: 0; }

.stepper-container {
  display: flex;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }
  .stepper-container button {
    background-color: #FAFBFC;
    width: 48px;
    height: 48px;
    min-width: auto;
    border-radius: 8px;
    margin: 4px;
    font-size: 20px;
    color: #0C87FA; }
    .stepper-container button.MuiButton-root.Mui-disabled {
      opacity: 0.5;
      color: #0C87FA; }

.visual-picker-container {
  background-color: #FAFBFC;
  border-radius: 8px;
  padding: 10px 15px;
  display: flex; }
  .visual-picker-container .visual-picker-inner {
    display: flex;
    width: 100%;
    align-items: center; }
    .visual-picker-container .visual-picker-inner.no-title {
      justify-content: center; }
    .visual-picker-container .visual-picker-inner .icon {
      width: 16px;
      height: 16px;
      margin-right: 15px;
      display: flex; }
      .visual-picker-container .visual-picker-inner .icon img {
        width: 100%; }
    .visual-picker-container .visual-picker-inner .visual-picker-title p {
      margin-bottom: 0; }
    .visual-picker-container .visual-picker-inner .visual-picker-title .title {
      color: #3B424A;
      font-size: 13px; }
    .visual-picker-container .visual-picker-inner .visual-picker-title .sub-title {
      color: #898E92;
      font-size: 12px; }

.tabs-menu-tab {
  display: flex;
  flex-basis: 100%;
  min-width: 100%;
  overflow-x: auto;
  scrollbar-width: none; }

.tabs-menu-tab::-webkit-scrollbar-track {
  background-color: #0070E0;
  color: #0070E0;
  width: 0; }

.tabs-menu-tab::-webkit-scrollbar {
  background-color: #0070E0;
  color: #0070E0;
  width: 0; }

.tabs-menu-tab::-webkit-scrollbar-thumb {
  background-color: #0070E0;
  color: #0070E0;
  width: 0; }

.tabs-menu-segment {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  border-radius: 24px;
  margin: 0 16px; }
  .tabs-menu-segment .item-segment:first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-right: 0; }
    .tabs-menu-segment .item-segment:first-child .segment-active {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px; }
  .tabs-menu-segment .item-segment:last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    border-left: 0; }
    .tabs-menu-segment .item-segment:last-child .segment-active {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px; }

.tab-item-history {
  margin: 0 12px !important; }
  .tab-item-history:first-child {
    margin-left: 24px !important; }
  .tab-item-history h5 {
    color: #81B7E9;
    font-size: 19px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin: 16px 0 24px;
    white-space: pre; }

.item-segment {
  flex-basis: 33.333%;
  color: #0070E0;
  background: #fff;
  border: 1px solid #0070E0;
  text-align: center;
  height: 26px; }
  .item-segment h5 {
    margin: 5px;
    font-size: 12px; }

.tab-active h5 {
  color: #0057AD !important; }

.segment-active {
  background: #0070E0 !important;
  color: #fff;
  font-weight: bold;
  height: 24px;
  margin: 0;
  overflow: auto; }

.job-panel {
  margin: 0 20px; }

.page-title-box {
  display: flex;
  width: 100%; }
  .page-title-box h3 {
    margin: 64px 20px 0 20px; }

.Bottom_Sheet {
  display: flex;
  flex-basis: 100%;
  z-index: 10;
  border-radius: 10px; }

.ReactSwipeableBottomSheet--open, .ReactSwipeableBottomSheet--closed {
  border-radius: 20px 20px 0 0; }

.react-swipeable-view-container {
  border-radius: 20px 20px 0 0; }

.Sheet_Content {
  position: relative;
  width: 100%; }
  .Sheet_Content .Bottom_Border_Sheet {
    position: absolute;
    bottom: 8px;
    padding: 0 13%;
    height: 4px;
    background-color: black;
    border-radius: 15%;
    margin: 0 auto;
    right: 38%; }
  .Sheet_Content .indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 100%;
    background-color: #FAFBFC;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    border-radius: 20px 20px 0 0; }
    .Sheet_Content .indicator-container .indicator {
      background-color: #DBDBDB;
      width: 40px;
      height: 3px;
      border-radius: 30px; }

.saved-places .saved-places-others {
  margin: 40px 0; }
  .saved-places .saved-places-others .saved-places-swipe-to-delete:last-child {
    border-bottom: 0; }
  .saved-places .saved-places-others .saved-places-others-title {
    padding: 10px 15px; }
    .saved-places .saved-places-others .saved-places-others-title p {
      color: #676767;
      margin: 0; }
  .saved-places .saved-places-others .saved-places-others-item {
    display: flex;
    align-items: center;
    background-color: #FAFBFC;
    padding: 13px 15px;
    border-bottom: 1px solid #DBDBDB;
    height: 64px; }
    .saved-places .saved-places-others .saved-places-others-item .divider {
      display: flex;
      width: 100%; }
      .saved-places .saved-places-others .saved-places-others-item .divider img {
        margin-right: 15px; }
    .saved-places .saved-places-others .saved-places-others-item .name-address .name {
      margin: 0;
      font-size: 14px;
      color: #000; }

.saved-places .saved-places-favorite {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 65px 0 0; }
  .saved-places .saved-places-favorite .saved-places-favorites-title {
    padding: 10px 15px; }
    .saved-places .saved-places-favorite .saved-places-favorites-title p {
      color: #676767;
      margin: 0; }

.saved-places .saved-places-favorite-item {
  background-color: #FAFBFC;
  padding: 13px 15px;
  border-bottom: 1px solid #DBDBDB;
  height: 64px; }
  .saved-places .saved-places-favorite-item .title,
  .saved-places .saved-places-favorite-item .add-title {
    display: flex;
    align-items: center; }
    .saved-places .saved-places-favorite-item .title .sub-title,
    .saved-places .saved-places-favorite-item .add-title .sub-title {
      align-items: center; }
  .saved-places .saved-places-favorite-item .divider {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.saved-places .saved-places-add {
  display: flex;
  padding: 15px;
  align-items: center; }
  .saved-places .saved-places-add .title-container .sub-title {
    align-items: center; }

.saved-places-address .saved-places-background {
  padding: 65px 20px 0;
  margin-bottom: 20px; }
  .saved-places-address .saved-places-background .form-group-container {
    padding: 8px 8px 0; }
  .saved-places-address .saved-places-background input {
    width: 100%; }

.saved-places-address .saved-places-name {
  color: #898E92; }
  .saved-places-address .saved-places-name p {
    margin-bottom: 0;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #898E92; }

.saved-places-address .saved-places-address-container {
  padding: 0 25px 20px; }
  .saved-places-address .saved-places-address-container .saved-places-address-selected {
    display: flex;
    align-items: center;
    padding: 0 8px 8px;
    border-bottom: 1px solid #DBDBDB; }
    .saved-places-address .saved-places-address-container .saved-places-address-selected p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.25px;
      color: #3B424A; }

.saved-places-address-confirm {
  margin-top: 16px;
  padding: 0 20px; }
  .saved-places-address-confirm .cancel-button {
    margin-top: 8px; }
    .saved-places-address-confirm .cancel-button.button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      background-color: #FAFBFC;
      border: 0;
      color: #0C87FA; }
  .saved-places-address-confirm .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled {
    background-color: #0C87FA;
    color: #FAFBFC;
    opacity: .6; }
  .saved-places-address-confirm button {
    width: 100%; }

.saved-places .saved-places-container {
  padding: 0 20px;
  width: 100%;
  overflow: hidden; }

.saved-places .icon {
  margin-right: 15px; }

.saved-places .title-container p {
  margin: 0;
  color: #3B424A; }

.saved-places .title-container .sub-title {
  color: #B1B3B7;
  display: flex;
  font-weight: normal;
  height: 20px;
  width: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis; }

.saved-places .title-container .forward-icon {
  margin-left: 5px;
  width: 6px; }

.location-search-box {
  display: flex;
  align-items: center; }
  .location-search-box.saved-places-location-search, .location-search-box.search-location-container {
    padding: 75px 20px 0; }
  .location-search-box .form-group-container {
    width: 100%;
    border: 1px solid #B1B3B7;
    border-radius: 8px;
    padding: 5px 8px; }
    .location-search-box .form-group-container.focused {
      border-color: #81B7E9; }
    .location-search-box .form-group-container input {
      width: 100%;
      border-radius: 0; }
      .location-search-box .form-group-container input:focus {
        outline: none; }
    .location-search-box .form-group-container .icon path {
      stroke: #DBDBDB; }
  .location-search-box .location-search-box-inner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between; }

.location-search-delete-button {
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
  display: flex;
  align-items: center; }
  .location-search-delete-button svg {
    width: 22px;
    height: 22px; }

.location-search-suggestions {
  margin-top: 24px;
  padding: 0 20px 25px;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  background: #FAFBFC; }
  .location-search-suggestions .location-search-suggestion-title {
    color: #898E92; }
  .location-search-suggestions .location-search-suggestions-item {
    padding: 16px;
    border-bottom: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .location-search-suggestions .location-search-suggestions-item.is-searching {
      justify-content: space-between; }
    @media (min-width: 400px) {
      .location-search-suggestions .location-search-suggestions-item {
        padding: 16px; } }
    .location-search-suggestions .location-search-suggestions-item .recent-search-result {
      margin-right: 12px;
      width: 24px;
      height: 24px; }
      @media (min-width: 400px) {
        .location-search-suggestions .location-search-suggestions-item .recent-search-result {
          margin-right: 16px; } }
    .location-search-suggestions .location-search-suggestions-item .location-search-suggestions-title {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 18px;
      margin-right: 8px; }
      @media (min-width: 400px) {
        .location-search-suggestions .location-search-suggestions-item .location-search-suggestions-title {
          margin-right: 16px;
          padding-left: 12px; } }

.location-search-suggestions.bookmarked-places {
  margin: 0;
  padding: 10px 20px; }
  .location-search-suggestions.bookmarked-places .location-search-suggestions-item:last-child {
    border-bottom: 1px solid #DBDBDB; }
  .location-search-suggestions.bookmarked-places .location-search-suggestions-item {
    padding: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.location-search-saving-container {
  padding: 0 20px; }
  .location-search-saving-container .location-search-saving-item {
    border-bottom: 1px solid #DBDBDB;
    height: 77px;
    display: flex;
    align-items: center; }
    .location-search-saving-container .location-search-saving-item .title-container .sub-title {
      align-items: center; }
    .location-search-saving-container .location-search-saving-item .location-search-saving {
      padding: 15px;
      width: 100%;
      display: flex;
      align-items: center; }
    .location-search-saving-container .location-search-saving-item .bookmark-icon {
      margin-right: 15px; }
    .location-search-saving-container .location-search-saving-item .saved-places-title {
      color: #3B424A; }
      .location-search-saving-container .location-search-saving-item .saved-places-title p {
        margin-bottom: 0; }

#google-map {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 65px; }

.google-map-blur {
  -webkit-filter: blur(2px);
          filter: blur(2px); }

.location-search-map.set-first-location .location-search-map-icon {
  position: fixed; }

.location-search-map .location-search-map-icon {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 51%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .location-search-map .location-search-map-icon svg.Pin-icon {
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s; }
  .location-search-map .location-search-map-icon svg.Ellipse-icon {
    left: 49%;
    top: 49%;
    z-index: 10;
    width: 8px;
    fill: #4c4c4c70; }
  .location-search-map .location-search-map-icon.dragged svg.Pin-icon {
    -webkit-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
    top: 42%; }

.location-search-map .location-search-map-current {
  position: absolute;
  right: 0;
  bottom: 70px;
  z-index: 10;
  cursor: pointer;
  padding: 0 15px; }
  .location-search-map .location-search-map-current .Current-icon {
    width: 35px;
    margin: auto; }

.location-search-map .location-search-map-confirm {
  background-color: transparent; }
  .location-search-map .location-search-map-confirm button {
    width: 100%; }

.bottom-sheet-info {
  background-color: #FAFBFC;
  padding: 30px 20px; }
  .bottom-sheet-info .button-container {
    width: 100%;
    justify-content: center; }
    .bottom-sheet-info .button-container button {
      width: 100%; }
  .bottom-sheet-info p {
    text-align: center;
    margin-bottom: 30px;
    font-size: 13px;
    color: #3B424A; }
  .bottom-sheet-info h2 {
    font-size: 18px;
    margin-top: 0;
    text-align: center; }

.location-search-current {
  border: none;
  box-shadow: none;
  outline: none;
  background: inherit;
  padding: 0;
  background-color: white;
  border-radius: 50%;
  width: 45px;
  height: 45px; }

.location-search-set .Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(110, 110, 110, 0.55); }
  .location-search-set .Modal .modal-dialog {
    margin: 0 20px; }
  .location-search-set .Modal .modal-content {
    padding: 15px;
    border-radius: 10px;
    border: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .location-search-set .Modal .modal-kuya-container {
    position: relative;
    text-align: center;
    margin: auto;
    margin-top: -75px; }
    .location-search-set .Modal .modal-kuya-container svg {
      width: 100px;
      max-height: 100px;
      margin: auto;
      margin-top: 0px; }
  .location-search-set .Modal .location-search-set-title {
    margin-bottom: 8px; }
    .location-search-set .Modal .location-search-set-title p {
      margin-bottom: 0;
      color: #3B424A; }
  .location-search-set .Modal .message {
    margin-bottom: 8px; }
    .location-search-set .Modal .message p {
      margin-bottom: 0;
      color: #181A1E; }
  .location-search-set .Modal .action {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .location-search-set .Modal .action .button-action {
      flex-basis: 50%;
      max-width: 50%;
      padding-right: 16px; }
      .location-search-set .Modal .action .button-action:last-child {
        padding-right: 0; }
      .location-search-set .Modal .action .button-action button {
        font-size: 14px;
        width: 100%; }

.location-search-map-confirm {
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background-color: #FAFBFC; }

.location-bottom-sheet-container .location-search.saved-places .saved-places-favorite {
  padding-top: 16px; }

.location-bottom-sheet-container .location-search-saving-container .location-search-saving-item {
  height: 65px; }

.location-bottom-sheet-container + .close-button-container {
  position: fixed;
  bottom: 0;
  padding: 20px;
  background: #FAFBFC;
  width: 100%; }
  .location-bottom-sheet-container + .close-button-container button {
    background: #0C87FA;
    border-radius: 5px;
    color: #FAFBFC;
    text-transform: none;
    width: 100%;
    border: 0;
    padding: 8px 16px; }

.location-search-box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 15px 8px;
  border-bottom: 1px solid #DBDBDB;
  width: 100%;
  background-color: #FAFBFC; }
  .location-search-box-container .location-search-box-inner {
    display: flex;
    align-items: center;
    padding-left: 12px;
    width: 100%;
    justify-content: space-between; }
    .location-search-box-container .location-search-box-inner input {
      border: 0;
      width: 100%;
      background: transparent;
      box-shadow: none;
      border-radius: 0;
      cursor: pointer;
      font-weight: normal; }
      .location-search-box-container .location-search-box-inner input:focus {
        outline: none; }
      .location-search-box-container .location-search-box-inner input::-webkit-input-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-box-container .location-search-box-inner input::-moz-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-box-container .location-search-box-inner input:-ms-input-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-box-container .location-search-box-inner input::-ms-input-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-box-container .location-search-box-inner input::placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-box-container .location-search-box-inner input::-moz-placeholder, .location-search-box-container .location-search-box-inner input::-ms-placeholder, .location-search-box-container .location-search-box-inner input::-webkit-placeholder, .location-search-box-container .location-search-box-inner input::-o-placeholder {
        color: #898E92;
        font-weight: 400; }
    .location-search-box-container .location-search-box-inner .location-search-box-delete {
      padding-left: 5px; }
  .location-search-box-container .location-search-box-input {
    width: 100%;
    color: #3B424A;
    padding: 0; }
    .location-search-box-container .location-search-box-input::-webkit-input-placeholder, .location-search-box-container .location-search-box-input:-ms-input-placeholder, .location-search-box-container .location-search-box-input::-moz-placeholder {
      color: #3B424A; }

.pin-icon-wrapper {
  position: relative;
  display: flex; }
  .pin-icon-wrapper .number-destination {
    color: #B1B3B7;
    position: absolute;
    bottom: -2px;
    right: 0; }

.location-search.saved-places .saved-places-favorite {
  padding-top: 32px; }

.location-search-confirm button {
  width: 100%; }

.change-location .location-search-suggestions {
  padding-bottom: 80px; }

.change-location .location-bookmarked-container {
  padding-bottom: 0; }

.change-location .location-search-map-confirm {
  position: fixed;
  margin: 0 auto;
  max-width: 412px; }

.Location-search-spacer-booking {
  background-color: white; }

.Location-search-spacer-booking .Spacer-icon {
  width: 2px; }

.Location-search-spacer-booking .Location-search-spacer-div {
  padding: 0 6px; }

.Location-search-spacer-booking .Location-search-booking-divider hr {
  margin: 10px 0; }

.Location-box.jobs-box-spacer img {
  width: 3px;
  margin-bottom: 2px; }

.Location-box.jobs-box-spacer {
  padding-left: 0;
  background-color: #fff;
  border-radius: 0;
  display: flex;
  align-items: center; }

.Location-box.jobs-box-spacer .Location-search-booking-divider {
  padding-left: 40px;
  position: absolute;
  padding-right: 15px;
  width: 100%; }

.Location-box.jobs-box-spacer .spacer-img {
  position: absolute;
  top: -5px;
  left: 21px; }

.Location-box .Location-search-booking-divider {
  width: 100%;
  padding-left: 22px;
  position: absolute;
  padding-right: 30px; }

.Location-box .Location-search-booking-divider hr {
  margin: 6px 0; }

@media (max-width: 405px) {
  .Location-box.jobs-box-spacer .Location-search-booking-divider {
    width: 93%; } }

.location-box .pin-icon-wrapper {
  position: relative; }

.location-box .pin-icon-wrapper span {
  color: #DF5D4D;
  font-size: 8px;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top: 3px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.booking-location-box .Location-box.draggable {
  justify-content: space-between; }

.location-search-destination-box {
  padding: 16px 0 0;
  display: flex;
  align-items: center; }
  .location-search-destination-box .location-search-box-inner {
    display: flex;
    align-items: center;
    padding-left: 12px;
    width: 100%;
    justify-content: space-between; }
    .location-search-destination-box .location-search-box-inner input {
      border: 0;
      width: 100%;
      background: transparent;
      box-shadow: none;
      border-radius: 0;
      text-align: right;
      padding: 0;
      caret-color: #FAFBFC;
      cursor: pointer; }
      .location-search-destination-box .location-search-box-inner input::-webkit-input-placeholder {
        color: #0C87FA; }
      .location-search-destination-box .location-search-box-inner input::-moz-placeholder {
        color: #0C87FA; }
      .location-search-destination-box .location-search-box-inner input:-ms-input-placeholder {
        color: #0C87FA; }
      .location-search-destination-box .location-search-box-inner input::-ms-input-placeholder {
        color: #0C87FA; }
      .location-search-destination-box .location-search-box-inner input::placeholder {
        color: #0C87FA; }
      .location-search-destination-box .location-search-box-inner input::-moz-placeholder, .location-search-destination-box .location-search-box-inner input::-ms-placeholder, .location-search-destination-box .location-search-box-inner input::-webkit-placeholder, .location-search-destination-box .location-search-box-inner input::-o-placeholder {
        color: #0C87FA; }
      .location-search-destination-box .location-search-box-inner input:focus {
        outline: none; }
  .location-search-destination-box.has-min-point {
    padding: 15px 8px;
    border-bottom: 1px solid #DBDBDB; }
    .location-search-destination-box.has-min-point .location-search-box-inner input {
      text-align: left; }
      .location-search-destination-box.has-min-point .location-search-box-inner input::-webkit-input-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-destination-box.has-min-point .location-search-box-inner input::-moz-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-destination-box.has-min-point .location-search-box-inner input:-ms-input-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-destination-box.has-min-point .location-search-box-inner input::-ms-input-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-destination-box.has-min-point .location-search-box-inner input::placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-destination-box.has-min-point .location-search-box-inner input::-moz-placeholder, .location-search-destination-box.has-min-point .location-search-box-inner input::-ms-placeholder, .location-search-destination-box.has-min-point .location-search-box-inner input::-webkit-placeholder, .location-search-destination-box.has-min-point .location-search-box-inner input::-o-placeholder {
        color: #898E92;
        font-weight: 400; }
      .location-search-destination-box.has-min-point .location-search-box-inner input:focus {
        outline: none; }

.billing-information-card {
  padding: 0 20px; }

.billing-information-card-item {
  padding: 16px;
  height: 95px;
  background-color: #FAFBFC;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  margin: 24px 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .billing-information-card-item.mc {
    border-left: 8px solid #0057AD; }
  .billing-information-card-item.visa {
    border-left: 8px solid #B01C05; }
  .billing-information-card-item .billing-information-card-item-img .my-koin-svg {
    width: 16px;
    height: 16px; }
  .billing-information-card-item .billing-information-card-item-field {
    display: flex;
    color: #3B424A; }
    .billing-information-card-item .billing-information-card-item-field .billing-information-card-number {
      padding-left: 8px;
      margin-bottom: 0; }
  .billing-information-card-item .billing-information-card-item-inner {
    display: flex;
    align-items: center; }
  .billing-information-card-item .billing-information-card-expiration {
    display: flex;
    align-items: center; }
    .billing-information-card-item .billing-information-card-expiration span {
      color: #B1B3B7; }
    .billing-information-card-item .billing-information-card-expiration p {
      margin-bottom: 0;
      padding-left: 8px;
      color: #3B424A; }
  .billing-information-card-item .billing-information-card-item-radio {
    padding-left: 8px; }
  .billing-information-card-item .billing-information-card-item-first-row,
  .billing-information-card-item .billing-information-card-item-second-row {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.billing-card-image.mc {
  width: 25px;
  height: 16px; }

.billing-card-image.visa {
  width: 32px;
  height: 10px; }

.mykoin-container {
  padding: 0 20px; }
  .mykoin-container .billing-information-card-item {
    border-left: 8px solid #B56400;
    margin-top: 0; }
  .mykoin-container .mykoin-inner {
    display: flex;
    justify-content: space-between; }
    .mykoin-container .mykoin-inner .billing-information-card-item-name {
      display: flex;
      align-items: center; }
      .mykoin-container .mykoin-inner .billing-information-card-item-name .lozenge-container {
        padding-left: 8px; }
  .mykoin-container .billing-information-card-item-value {
    display: flex;
    justify-content: space-between; }
    .mykoin-container .billing-information-card-item-value .top-up-mykoin-container {
      display: flex; }
      .mykoin-container .billing-information-card-item-value .top-up-mykoin-container p {
        font-size: 13px;
        color: #B1B3B7;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        margin-left: 2px; }
        .mykoin-container .billing-information-card-item-value .top-up-mykoin-container p svg {
          width: 8px;
          height: 10px;
          margin-left: 6px; }
      .mykoin-container .billing-information-card-item-value .top-up-mykoin-container span {
        font-size: 12px;
        color: #FC6A54; }

.billing-information-add {
  padding: 24px 20px;
  display: flex;
  justify-content: flex-end; }
  .billing-information-add .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    padding: 0;
    color: #0C87FA;
    background: transparent;
    box-shadow: none; }

.billing-information-safely {
  background-color: #0070E0;
  height: 15%;
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0; }
  .billing-information-safely .billing-information-safely-inner {
    display: flex;
    height: 26px;
    align-items: center; }
    .billing-information-safely .billing-information-safely-inner .billing-information-safely-image {
      width: 30px; }
      .billing-information-safely .billing-information-safely-inner .billing-information-safely-image img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto; }
  .billing-information-safely p {
    color: #FAFBFC;
    margin: 0 0 0 8px; }

.error-text-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  width: 100%; }

.billing-information {
  height: 85%;
  position: absolute;
  background-color: #FAFBFC;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  .billing-information::-webkit-scrollbar {
    width: 0; }
  .billing-information .title {
    padding: 65px 20px 20px;
    margin: 0;
    color: #3B424A; }

.billing-add-card form input {
  border-bottom: 1px solid #949494;
  box-shadow: none;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  caret-color: #0488FA; }
  .billing-add-card form input::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DCDCDC;
    opacity: 1;
    /* Firefox */
    /* font-family: 'lato_regular'; */
    font-size: 16px; }
  .billing-add-card form input::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DCDCDC;
    opacity: 1;
    /* Firefox */
    /* font-family: 'lato_regular'; */
    font-size: 16px; }
  .billing-add-card form input:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DCDCDC;
    opacity: 1;
    /* Firefox */
    /* font-family: 'lato_regular'; */
    font-size: 16px; }
  .billing-add-card form input::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DCDCDC;
    opacity: 1;
    /* Firefox */
    /* font-family: 'lato_regular'; */
    font-size: 16px; }
  .billing-add-card form input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #DCDCDC;
    opacity: 1;
    /* Firefox */
    /* font-family: 'lato_regular'; */
    font-size: 16px; }
  .billing-add-card form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #DCDCDC;
    /* font-family: 'lato_regular'; */
    font-size: 16px; }
  .billing-add-card form input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #DCDCDC;
    /* font-family: 'lato_regular'; */
    font-size: 16px; }

.billing-add-card form label {
  margin-bottom: 25px !important;
  /* font-family: 'lato_black'; */ }

.billing-add-card form input.invalid {
  border-bottom: 1px solid red; }

.billing-add-card form input.valid {
  /* border-bottom: 1px solid green; */ }

.billing-add-card form .credit-card {
  width: 20px; }

.billing-add-card .billing-add-card-pay {
  position: fixed;
  bottom: 15px;
  right: 0;
  left: 0;
  z-index: -10000; }

.billing-add-card .billing-add-card-field {
  background-color: white;
  z-index: 100; }
  .billing-add-card .billing-add-card-field .form-group {
    background-color: white; }

.billing-add-card .billing-add-card-pay {
  margin-bottom: 5px; }
  .billing-add-card .billing-add-card-pay button[type=submit] {
    text-transform: none !important;
    width: 100%;
    outline: none;
    box-shadow: none;
    background-color: #0488FA !important;
    color: white !important;
    border: none;
    padding: 10px !important;
    border-radius: 5px !important;
    /* font-family: 'lato_black'; */
    font-size: 16px !important; }
  .billing-add-card .billing-add-card-pay .billing-methods-picture img {
    max-width: 50px;
    max-height: 60px;
    display: inline-block; }
    .billing-add-card .billing-add-card-pay .billing-methods-picture img.pci {
      max-height: 62px;
      max-width: 70px;
      position: absolute;
      left: 0;
      bottom: 10px; }
    .billing-add-card .billing-add-card-pay .billing-methods-picture img.payment-secured {
      max-height: 30px;
      margin-left: 5px;
      position: absolute;
      left: 68px;
      bottom: 10px; }
    .billing-add-card .billing-add-card-pay .billing-methods-picture img.mastercard {
      max-width: 50px;
      max-height: 60px;
      position: absolute;
      right: 0;
      bottom: 4px; }
    .billing-add-card .billing-add-card-pay .billing-methods-picture img.visa {
      max-width: 50px;
      max-height: 60px;
      position: absolute;
      right: 50px;
      bottom: 0px; }

.add-new-card {
  padding: 65px 20px; }
  .add-new-card .tip {
    color: #898E92;
    margin-bottom: 0; }
  .add-new-card .title {
    color: #3B424A;
    margin-bottom: 16px; }
  .add-new-card .adyen-checkout__input {
    border-bottom: 1px solid #DBDBDB;
    border-right: 0;
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    background: transparent; }
  .add-new-card .adyen-checkout__fieldset__title {
    color: #3B424A;
    margin-bottom: 16px; }
  .add-new-card .adyen-checkout__fieldset--billingAddress {
    margin-top: 40px; }
  .add-new-card .adyen-checkout__input--focus,
  .add-new-card .adyen-checkout__input--focus:hover,
  .add-new-card .adyen-checkout__input:active,
  .add-new-card .adyen-checkout__input:active:hover,
  .add-new-card .adyen-checkout__input:focus,
  .add-new-card .adyen-checkout__input:focus:hover {
    border-radius: 8px;
    border: 1px solid #0C87FA; }
  .add-new-card .adyen-checkout__label {
    margin-bottom: 24px; }
  .add-new-card .adyen-checkout__label--focused .adyen-checkout__label__text {
    color: #0C87FA; }
  .add-new-card .adyen-checkout__button {
    background-color: #0C87FA; }

.Job-chat-history .message-list {
  background-color: #FAFBFC;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  -webkit-transition: background .3s ease-out .1s;
  transition: background .3s ease-out .1s;
  width: 100%;
  z-index: 100; }
  .Job-chat-history .message-list::-webkit-scrollbar {
    width: 0; }

.Job-chat-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%; }

.Job-chat-history .extra {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%; }

.job-chat-history-body {
  flex: 1 1;
  order: 2;
  z-index: 1; }

.Job_Chat_History_Header_Top {
  background-color: #FAFBFC;
  margin-bottom: 16px;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 100;
  justify-content: space-between; }
  .Job_Chat_History_Header_Top .Job_Chat_History_Header_Back {
    flex-basis: 20%; }
    .Job_Chat_History_Header_Top .Job_Chat_History_Header_Back .Close_Job_History {
      width: 24px;
      height: 24px;
      margin: 16px; }
  .Job_Chat_History_Header_Top .Job_Chat_History_Header_Middle {
    flex-basis: 90%; }
    .Job_Chat_History_Header_Top .Job_Chat_History_Header_Middle .Title {
      width: 55%;
      float: left; }
      .Job_Chat_History_Header_Top .Job_Chat_History_Header_Middle .Title h5 {
        text-align: left;
        font-size: 15px; }
    .Job_Chat_History_Header_Top .Job_Chat_History_Header_Middle .State {
      width: 45%;
      float: left; }
    .Job_Chat_History_Header_Top .Job_Chat_History_Header_Middle .Matched_State {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 4px;
      text-align: center;
      background-color: #ED7E00;
      color: #FAFBFC;
      margin: 10px 0;
      padding: 0 2px; }
      .Job_Chat_History_Header_Top .Job_Chat_History_Header_Middle .Matched_State h5 {
        font-size: 12px;
        margin: 2px; }
  .Job_Chat_History_Header_Top .Job_Chat_History_Header_Option {
    flex-basis: 10%; }
    .Job_Chat_History_Header_Top .Job_Chat_History_Header_Option .kebab-menu {
      margin: 16px 20px 16px 0px;
      width: 24px;
      height: 24px; }
  .Job_Chat_History_Header_Top .Option-icon {
    width: 5px;
    margin-left: 5px; }

.Job_Chat_History_Header_Down {
  background-color: #f4f4f4;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  padding: 0px 24px 16px;
  margin-top: 63px; }
  .Job_Chat_History_Header_Down .J_C_H_H_D_Profile {
    display: flex;
    align-items: center; }
    .Job_Chat_History_Header_Down .J_C_H_H_D_Profile .J_C_H_H_D_Profile_Img_Box .Avatar {
      width: 48px;
      height: 48px; }
    .Job_Chat_History_Header_Down .J_C_H_H_D_Profile .J_C_H_H_D_Profile_Text_Box {
      padding: 0 16px; }
      .Job_Chat_History_Header_Down .J_C_H_H_D_Profile .J_C_H_H_D_Profile_Text_Box .Job_Chat_History_Header_Name {
        color: #3B424A;
        margin: 0;
        font-weight: 600;
        text-align: left; }
  .Job_Chat_History_Header_Down .J_C_H_H_D_Call .J_C_H_H_D_C_Circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #2CD574;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Job_Chat_History_Header_Down .J_C_H_H_D_Call .J_C_H_H_D_C_Circle .Call_Partner_Icon {
      width: 16px;
      height: 16px;
      margin: 12px; }

.chat-header-container .chat-menu-title-bar .title-bar-container {
  background-color: #f4f4f4;
  z-index: 2;
  padding: 20px 16px; }

.call-container {
  display: flex; }
  .call-container .video-chat-icon-container {
    width: 40px;
    height: 40px;
    background-color: #2CD574;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px; }
    .call-container .video-chat-icon-container svg {
      width: 16px;
      height: 16px;
      fill: #fff; }

.phone-voice-call-container .call-drop-down {
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  width: 50%;
  text-align: left; }
  .phone-voice-call-container .call-drop-down p {
    margin: 0;
    padding: 10px 20px; }
    .phone-voice-call-container .call-drop-down p:first-child {
      border-bottom: 1px solid #DBDBDB; }

.Job_Chat_History_Send {
  background-color: #0488FA !important;
  text-transform: none !important;
  border: none;
  box-shadow: none;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  font-size: 20px;
  outline: none;
  padding: 0;
  min-width: unset;
  margin-left: 8px; }

.Job-chat-history-emoji-button {
  border: none;
  width: 24px;
  height: 24px;
  outline: none;
  padding: 0;
  background: #FAFBFC; }

.Job-chat-history .Job-chat-history-emoji-button.IoMdAttach svg {
  margin-top: 6px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.Job-chat-history .Job-chat-history-recorder.IoIosMic {
  -webkit-transform: scale(1.9);
          transform: scale(1.9); }

.Job-chat-history .Job-chat-history-recorder-IoIosCheckmark {
  background-color: #39D139 !important; }

.Job-chat-history .Job-chat-history-recorder-IoIosClose {
  background-color: red !important; }

.Job-chat-history .Job-chat-history-recorder svg.IoMdSend {
  margin-top: 5px;
  margin-right: -3px; }

.Job-chat-history .Job-chat-history-recorder svg.IoIosSquare {
  margin-top: 5px;
  margin-right: -1px; }

.Job-chat-history .Job-chat-history-recorder svg.IoIosMic {
  margin-top: -0.5px;
  margin-right: 0.5px; }

.Job-chat-history .Job-chat-history-recorder svg.IoIosCheckmark {
  font-size: 30px; }

.Job-chat-history .Job-chat-history-recorder svg.IoIosClose {
  font-size: 30px; }

.Job-chat-history .Job-chat-history-recorder .MuiButton-label {
  display: flex;
  justify-content: center;
  align-items: center; }

.Job-chat-history .Job-chat-history-recorder:disabled {
  background-color: #fff; }

.Job-chat-history .Job-chat-history-record canvas {
  width: 100%;
  height: 30px;
  border-radius: 40px; }

.Job-chat-history .Job-chat-history-input-container {
  order: 3;
  position: relative;
  flex: none;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 0;
  background: #FAFBFC;
  bottom: 0;
  border-top: 1px solid #DBDBDB;
  margin-top: 16px; }

.Job-chat-history .Job-chat-history-input-container .Job-chat-history-input {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-height: 35px;
  position: relative;
  z-index: 2;
  width: 100%; }

.Job-chat-history .Job-chat-history-input-and-actions {
  background-color: #FAFBFC;
  border-radius: 8px;
  padding: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #81B7E9; }

.Job-chat-history-actions {
  display: inline-block; }

.Job-chat-history-action-width {
  width: 10%; }

.Job-chat-history-input-width {
  flex: 1 1 85%;
  width: 85%; }

.Job-chat-history-input-width-longer {
  flex: 1 1 85% !important;
  width: 85% !important; }

.Job-chat-history .Job-chat-history-user-actions canvas {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  vertical-align: middle; }

.Job-chat-history .Job-chat-history-user-actions-textarea {
  width: auto;
  flex-basis: 85%;
  padding: 0 0 0 5px; }

.Job-chat-history .Job-chat-history-user-actions-textarea-record {
  flex: 1 1 100% !important;
  width: 100% !important; }

.Job-chat-history #job-chat-history-timer svg {
  margin-top: 2px;
  font-size: 20px; }

.Job-chat-history #job-chat-history-timer .values {
  color: #FAFBFC;
  font-weight: 400;
  font-size: 15px; }

.Job-chat-history #job-chat-history-timer .recording p {
  margin: 0;
  color: black;
  font-weight: 500;
  font-size: 18px; }

.Job-chat-history .Job-chat-history-user-actions-recorder {
  width: auto;
  flex-basis: 15%;
  align-items: center;
  margin-left: 4px; }

.Job-chat-history .emoji-mart {
  width: 100% !important;
  margin-top: 2px; }

.Job-chat-history .Job-chat-history-show {
  display: block; }

.Job-chat-history .Job-chat-history-hide {
  display: none; }

.Job-chat-history-action-parent {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 12px; }
  .Job-chat-history-action-parent .J_C_H_A_P_Right {
    flex: 1 1 85%;
    width: 85%; }
    .Job-chat-history-action-parent .J_C_H_A_P_Right .Left_Icons_Text_Area {
      display: flex; }
      .Job-chat-history-action-parent .J_C_H_A_P_Right .Left_Icons_Text_Area .L_I_T_A_Icons {
        display: flex;
        align-items: center; }
        .Job-chat-history-action-parent .J_C_H_A_P_Right .Left_Icons_Text_Area .L_I_T_A_Icons .Left_Icons_Parent {
          display: flex;
          align-items: center; }
          .Job-chat-history-action-parent .J_C_H_A_P_Right .Left_Icons_Text_Area .L_I_T_A_Icons .Left_Icons_Parent .Left_Icons_Child_Box {
            margin-right: 8px; }
  .Job-chat-history-action-parent .J_C_H_A_P_Left {
    display: flex;
    align-items: center; }

.Camera_Chat_History, .Recorder_Chat_History .Blue_Left_History {
  width: 24px;
  height: 24px; }

.White_Up_Arrow {
  margin: 4px; }

.Job-chat-history .Job-chat-history-recorder-IoIosCheckmark.MuiButton-root.Mui-disabled {
  opacity: 0.5; }

.Job_Chat_History_Record_Voice {
  display: flex;
  flex-wrap: wrap;
  background-color: #181A1E;
  height: 65px; }

.Job-chat-history .Job-chat-history-recorder .MuiButton-label {
  display: flex;
  justify-content: center;
  align-items: center; }

.Job-chat-history .Job-chat-history-recorder:disabled {
  background-color: #dddddd; }

.Recording_Timer_Box {
  flex: 1 1 40%;
  width: 40%; }

.Job_Chat_History_User_Actions_Recorder {
  flex: 1 1 35%;
  width: 35%;
  display: flex; }
  .Job_Chat_History_User_Actions_Recorder .J_C_H_U_A_R_Icon {
    flex: 1 1 30%;
    width: 30%; }
    .Job_Chat_History_User_Actions_Recorder .J_C_H_U_A_R_Icon .Gray_Recorder {
      width: 24px;
      height: 24px;
      margin: 16px; }
  .Job_Chat_History_User_Actions_Recorder .J_C_H_U_A_R_Text {
    flex: 1 1 70%;
    width: 70%; }
    .Job_Chat_History_User_Actions_Recorder .J_C_H_U_A_R_Text p {
      font-size: 13px;
      margin: 19px 14px 0 0;
      line-height: 24px;
      color: #B1B3B7;
      text-align: right; }

.Job_Chat_History_Send_Delete {
  flex: 1 1 25%;
  width: 25%;
  display: flex; }
  .Job_Chat_History_Send_Delete .J_C_H_S_D_Box {
    flex: 1 1 40%;
    width: 40%; }

.Job_Chat_History_Record_Voice_Box {
  background-color: #2F353B;
  border-radius: 8px;
  padding: 4px;
  margin: 14px 0;
  height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse; }
  .Job_Chat_History_Record_Voice_Box .Timer_Circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #C9311A;
    float: right;
    margin: 8px 0; }
  .Job_Chat_History_Record_Voice_Box .Timer {
    float: right;
    margin: 0 16px 0 8px;
    text-align: right; }

.Job_Chat_History_Send_Recorder {
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 50%;
  font-size: 20px;
  outline: none;
  padding: 0;
  min-width: unset;
  margin: 18px 0;
  float: left; }

.Job_Chat_History_Trash {
  background-color: transparent;
  text-transform: none !important;
  border: none;
  color: white;
  box-shadow: none;
  outline: none;
  padding: 0;
  min-width: unset;
  margin: 18px 0; }
  .Job_Chat_History_Trash .Orange_Trash {
    width: 24px;
    height: 24px; }

.Job_Chat_History_Thumbs_Up {
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  min-width: unset;
  width: auto;
  height: auto; }
  .Job_Chat_History_Thumbs_Up img {
    width: 32px;
    height: 32px; }

.Job_Chat_History_Extend_Bottom_Sheet .Title {
  text-align: center;
  font-size: 16px;
  padding: 0 15px;
  margin: 24px 0;
  color: #3B424A; }

.Job_Chat_History_Extend_Buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0; }
  .Job_Chat_History_Extend_Buttons .J_C_H_E_B_Left {
    flex: 1 1 100%;
    margin: 0 16px; }
    .Job_Chat_History_Extend_Buttons .J_C_H_E_B_Left .Retry-Button {
      background-color: #0C87FA;
      color: #FAFBFC;
      outline: none;
      box-shadow: none;
      border: none;
      width: 100%;
      border-radius: 5px;
      padding: 10px !important;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-transform: none; }
      .Job_Chat_History_Extend_Buttons .J_C_H_E_B_Left .Retry-Button:disabled {
        background-color: #C5C5C5 !important; }
    .Job_Chat_History_Extend_Buttons .J_C_H_E_B_Left .Cancel_Button {
      background: #FAFBFC;
      color: #0C87FA;
      outline: none;
      box-shadow: none;
      border: none;
      width: 100%;
      border-radius: 3px;
      padding: 10px !important;
      margin: 10px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-transform: none; }
      .Job_Chat_History_Extend_Buttons .J_C_H_E_B_Left .Cancel_Button:disabled {
        background-color: #C5C5C5 !important; }

.Job_Chat_History_Comment {
  flex: 1 1 100%;
  margin: 24px 16px; }
  .Job_Chat_History_Comment p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #898E92; }

.Job_Chat_History_Extend .Job_Chat_History_Extend_Minus, .Job_Chat_History_Extend .Job_Chat_History_Extend_Plus {
  flex-basis: 50%;
  max-width: 50%;
  background-color: #FAFBFC;
  color: #0C87FA;
  font-size: 19px;
  font-weight: bold;
  width: 35px !important;
  height: 48px !important;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
  border-radius: 8px;
  margin: 0 0 0 8px;
  min-width: unset !important;
  box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12); }
  @media (min-width: 400px) {
    .Job_Chat_History_Extend .Job_Chat_History_Extend_Minus, .Job_Chat_History_Extend .Job_Chat_History_Extend_Plus {
      width: 48px !important;
      height: 48px !important; } }

.Job_Chat_History_Extend {
  background-color: #FAFBFC;
  padding: 0 20px;
  display: flex;
  margin: 0 0 32px;
  align-items: flex-end; }
  .Job_Chat_History_Extend .J_C_H_E_Time_Box {
    flex: 1 1 45%;
    max-width: 45%;
    border-bottom: 1px solid #DBDBDB;
    padding: 0 8px 16px; }
    .Job_Chat_History_Extend .J_C_H_E_Time_Box h5 {
      margin: 0;
      color: #3B424A;
      font-weight: 600;
      font-size: 19px;
      line-height: 28px; }
  .Job_Chat_History_Extend .J_C_H_E_Time_Fee {
    flex: 1 1 20%;
    max-width: 20%;
    padding: 16px 0 16px 8px; }
    .Job_Chat_History_Extend .J_C_H_E_Time_Fee p {
      font-size: 13px;
      color: #898E92;
      margin: 0; }
  .Job_Chat_History_Extend .J_C_H_E_Time_Extend_Cost {
    flex: 1 1 35%;
    max-width: 35%;
    display: flex;
    flex-direction: row-reverse; }

.Chat_Extend_Job {
  background: #FAFBFC; }
  .Chat_Extend_Job .Dynamic_Parent_Icon {
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    display: flex; }

.Job_Chat_History_Detail_Sheet_Content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 24px; }
  .Job_Chat_History_Detail_Sheet_Content .Title_Box {
    flex: 1 1 100%; }
    .Job_Chat_History_Detail_Sheet_Content .Title_Box .Title {
      text-align: center;
      font-size: 15px;
      padding: 0 15px;
      margin: 24px 0;
      color: #3B424A; }
  .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Type_Box {
    border-bottom: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 4px; }
    .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Type_Box .Job_Description_Inner {
      padding-left: 16px;
      color: #3B424A; }
  .Job_Chat_History_Detail_Sheet_Content .Job_Chat_History_Detail_Locations {
    width: 100%; }
    .Job_Chat_History_Detail_Sheet_Content .Job_Chat_History_Detail_Locations .Job-locations {
      padding: 12px 4px; }
  .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Time_Content {
    width: 100%; }
    .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Time_Content .J_C_H_D_S_C_Job_Type_Box {
      border-bottom: 1px solid #DBDBDB;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: baseline;
      width: 100%;
      padding: 12px 4px; }
      .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Time_Content .J_C_H_D_S_C_Job_Type_Box .Job_Description_Inner {
        padding-left: 16px;
        color: #3B424A; }
      .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Time_Content .J_C_H_D_S_C_Job_Type_Box .job-time-inner {
        display: flex;
        align-items: center;
        margin-bottom: 8px; }
        .Job_Chat_History_Detail_Sheet_Content .J_C_H_D_S_C_Job_Time_Content .J_C_H_D_S_C_Job_Type_Box .job-time-inner:last-child {
          margin-bottom: 0; }
  .Job_Chat_History_Detail_Sheet_Content .card {
    display: flex;
    width: 100%;
    padding: 12px 0; }
    .Job_Chat_History_Detail_Sheet_Content .card .job-description-container {
      width: 100%;
      display: flex;
      align-items: center; }
      .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .job-description-inner {
        color: #3B424A;
        margin-bottom: 0;
        padding-left: 16px; }
      .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .billing-card-image.visa {
        width: 24px;
        height: 8px; }
        .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .billing-card-image.visa + .job-description-inner {
          padding-left: 12px; }
      .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .billing-card-image.mc {
        width: 22px;
        height: 14px; }
      .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .mykoin-image {
        width: 13px;
        height: 13px;
        padding: 1px;
        margin-left: 5px; }
        .Job_Chat_History_Detail_Sheet_Content .card .job-description-container .mykoin-image + p {
          padding-left: 19px;
          margin: 0; }
  .Job_Chat_History_Detail_Sheet_Content .button-container {
    width: 100%;
    margin: 40px 0 20px; }
    .Job_Chat_History_Detail_Sheet_Content .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%; }

.Job_Chat_History_Menu_Box {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  margin: 215px 16px 0; }
  .Job_Chat_History_Menu_Box .J_C_H_M_B_Row {
    padding: 16px;
    display: flex;
    width: 100%;
    border-top: 1px solid #DBDBDB; }
    .Job_Chat_History_Menu_Box .J_C_H_M_B_Row:first-child {
      border-top: 0; }
  .Job_Chat_History_Menu_Box .J_C_H_M_B_Row_Icon .J_C_H_B_Row_Icon_Img {
    width: 24px;
    height: 24px; }
  .Job_Chat_History_Menu_Box .J_C_H_M_B_Row_Text h5 {
    padding-left: 16px;
    color: #3B424A;
    margin: 0; }

.Cancel_Btn_Box {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap; }
  .Cancel_Btn_Box .Cancel_Job_Button {
    text-transform: none !important;
    background-color: #FAFBFC;
    color: #FC6A54;
    border: 1px solid #FC6A54;
    margin: 32px 0;
    outline: none;
    box-shadow: none;
    width: 100%;
    border-radius: 5px !important;
    padding: 8px; }
    .Cancel_Btn_Box .Cancel_Job_Button:disabled {
      background-color: #C5C5C5 !important; }

.chat-menu-title-bar .title-bar-container .title-bar-icon {
  display: flex; }
  .chat-menu-title-bar .title-bar-container .title-bar-icon .heading {
    padding: 0 8px 0 32px; }
  .chat-menu-title-bar .title-bar-container .title-bar-icon .lozenge-container {
    align-items: center; }
    .chat-menu-title-bar .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
      .chat-menu-title-bar .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner .lozenge-title p {
        text-transform: uppercase; }

.Chat_Note_To_Partner {
  background-color: #FAFBFC;
  padding-top: 10px; }
  .Chat_Note_To_Partner .Sheet_Content {
    flex: 1 1 100%;
    width: 100%; }
  .Chat_Note_To_Partner .job-note-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%; }
    .Chat_Note_To_Partner .job-note-container p {
      margin-bottom: 24px; }
    .Chat_Note_To_Partner .job-note-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%; }

.Job_Chat_History_Note_To_Partner {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  width: 100%; }
  .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Title {
    flex: 1 1 100%; }
    .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Title h5 {
      flex: 1 1 100%;
      text-align: center;
      margin: 16px 0; }
  .Job_Chat_History_Note_To_Partner .Note_To_Partner_Text {
    flex: 1 1 100%;
    margin: 16px 24px; }
  .Job_Chat_History_Note_To_Partner .Note_To_Partner_Button {
    display: flex;
    width: 100%;
    flex: 1 1 100%;
    flex-wrap: wrap; }
    .Job_Chat_History_Note_To_Partner .Note_To_Partner_Button .N_T_P_B_Box {
      width: 15%;
      height: 56px;
      margin: 8px 24px;
      border-radius: 8px;
      box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12); }
    .Job_Chat_History_Note_To_Partner .Note_To_Partner_Button .Camera_Icon {
      margin: 16px -12px; }
  .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Btn_Box {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;
    width: 100%; }
    .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Btn_Box .J_C_H_N_T_P_B_B_Right {
      flex: 1 1 70%;
      width: 70%;
      padding: 8px 24px; }
      .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Btn_Box .J_C_H_N_T_P_B_B_Right .Save_Changes {
        text-transform: none !important;
        background-color: #0488FA !important;
        color: white !important;
        outline: none;
        box-shadow: none;
        border: none;
        width: 100%;
        border-radius: 3px !important;
        padding: 4px !important;
        font-size: 18px !important;
        font-weight: 600; }
        .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Btn_Box .J_C_H_N_T_P_B_B_Right .Save_Changes:disabled {
          background-color: #C5C5C5 !important; }
    .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Btn_Box .J_C_H_N_T_P_B_B_Left {
      flex: 1 1 30%;
      width: 30%; }
      .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Btn_Box .J_C_H_N_T_P_B_B_Left .Discard {
        text-transform: none !important;
        background-color: #fff !important;
        color: #FC6A54 !important;
        outline: none;
        box-shadow: none;
        border: none;
        width: 100%;
        border-radius: 3px !important;
        padding: 4px !important;
        font-size: 18px !important;
        margin: 8px 0;
        font-weight: 600; }
        .Job_Chat_History_Note_To_Partner .J_C_H_N_T_P_Btn_Box .J_C_H_N_T_P_B_B_Left .Discard:disabled {
          background-color: #C5C5C5 !important; }

.cancel-job-bottom-sheet {
  background-color: #FAFBFC;
  padding: 0 12px;
  border-radius: 20px 20px 0 0; }
  @media (min-width: 400px) {
    .cancel-job-bottom-sheet {
      padding: 0 24px; } }

.Job-chat-history-cancel {
  width: 100%; }
  .Job-chat-history-cancel .title {
    margin: 0;
    color: #3B424A;
    text-align: center;
    padding-top: 24px; }
  .Job-chat-history-cancel .text-title {
    color: #949494; }
  .Job-chat-history-cancel .lets-get-started-button {
    text-transform: none !important;
    background-color: #0C87FA !important;
    color: white;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 3px;
    font-weight: bold;
    padding: 6px;
    font-size: 12px !important; }
  .Job-chat-history-cancel ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
    overflow: auto; }
    .Job-chat-history-cancel ul li {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 16px; }
      @media (min-width: 400px) {
        .Job-chat-history-cancel ul li {
          margin-bottom: 24px; } }
      .Job-chat-history-cancel ul li input[type=radio] {
        position: absolute;
        visibility: hidden; }
      .Job-chat-history-cancel ul li .check {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear; }
        .Job-chat-history-cancel ul li .check label {
          display: block;
          position: relative;
          z-index: 9;
          cursor: pointer;
          -webkit-transition: all 0.25s linear;
          transition: all 0.25s linear;
          margin-bottom: 0;
          color: #3B424A;
          font-weight: 600;
          padding: 16px; }
          @media (min-width: 400px) {
            .Job-chat-history-cancel ul li .check label {
              padding: 16px; } }
        .Job-chat-history-cancel ul li .check::before {
          display: block;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 8px;
          margin: auto;
          transition: background 0.25s linear;
          -webkit-transition: background 0.25s linear;
          background-color: #FAFBFC;
          border-radius: 8px; }
  .Job-chat-history-cancel input[type=radio]:checked ~ .check::before {
    background: #0057AD;
    box-shadow: none; }
  .Job-chat-history-cancel input[type=radio]:checked ~ .check label {
    color: #FAFBFC; }
  .Job-chat-history-cancel .Job-chat-history-cancel-textarea {
    width: 100%;
    border-bottom: 1px solid #DBDBDB;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    outline: none;
    box-shadow: none;
    height: 32px;
    background-color: #FAFBFC; }
    .Job-chat-history-cancel .Job-chat-history-cancel-textarea::-webkit-input-placeholder {
      color: #B1B3B7;
      font-size: 12px; }
    .Job-chat-history-cancel .Job-chat-history-cancel-textarea::-moz-placeholder {
      color: #B1B3B7;
      font-size: 12px; }
    .Job-chat-history-cancel .Job-chat-history-cancel-textarea:-ms-input-placeholder {
      color: #B1B3B7;
      font-size: 12px; }
    .Job-chat-history-cancel .Job-chat-history-cancel-textarea::-ms-input-placeholder {
      color: #B1B3B7;
      font-size: 12px; }
    .Job-chat-history-cancel .Job-chat-history-cancel-textarea::placeholder {
      color: #B1B3B7;
      font-size: 12px; }
    .Job-chat-history-cancel .Job-chat-history-cancel-textarea + p {
      color: #FC6A54;
      position: absolute;
      right: 0px;
      font-size: 20px;
      top: -5px; }
    .Job-chat-history-cancel .Job-chat-history-cancel-textarea:focus {
      outline: none;
      box-shadow: none; }
  .Job-chat-history-cancel .retry-button {
    text-transform: none !important;
    background-color: #FAFBFC !important;
    color: #FC6A54;
    outline: none;
    box-shadow: none;
    border: 1px solid #FC6A54;
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    flex-basis: 70%;
    max-width: 70%;
    margin-right: 16px; }
  .Job-chat-history-cancel .cancel-button {
    text-transform: none !important;
    color: #FAFBFC;
    background-color: #0C87FA;
    outline: none;
    box-shadow: none;
    border: 1px solid #0C87FA;
    width: 100%;
    border-radius: 5px;
    padding: 8px;
    flex-basis: 30%;
    max-width: 30%;
    width: 100%; }
    .Job-chat-history-cancel .cancel-button:hover, .Job-chat-history-cancel .cancel-button:focus, .Job-chat-history-cancel .cancel-button:active {
      color: #FAFBFC;
      background-color: #0C87FA; }
  .Job-chat-history-cancel .Job-chat-modal-button-container {
    display: flex;
    width: 100%;
    padding-top: 24px;
    justify-content: space-between; }
  .Job-chat-history-cancel .chat-with-support {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px; }
    .Job-chat-history-cancel .chat-with-support span {
      padding-left: 8px;
      color: #0C87FA; }

.Home_Header {
  display: flex;
  flex-wrap: wrap;
  height: auto; }
  .Home_Header .Home_Header_greeting_container {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    flex: 1 1 100%; }
    .Home_Header .Home_Header_greeting_container .Home_Header_greeting {
      height: 56px;
      display: flex;
      align-items: center;
      flex: 1 1 100%; }
    .Home_Header .Home_Header_greeting_container .kebab-menu {
      height: 56px;
      display: flex; }
    .Home_Header .Home_Header_greeting_container .Home_Header_greeting_Profile {
      flex: 1 1 13%;
      display: flex; }
      .Home_Header .Home_Header_greeting_container .Home_Header_greeting_Profile .H_H_G_P_Img_Box {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        padding: 2px; }
        .Home_Header .Home_Header_greeting_container .Home_Header_greeting_Profile .H_H_G_P_Img_Box img {
          max-width: 100%;
          height: auto;
          display: block; }
    .Home_Header .Home_Header_greeting_container .Home_Header_greeting_Text {
      flex: 1 1 87%; }
      .Home_Header .Home_Header_greeting_container .Home_Header_greeting_Text h5 {
        color: #3B424A; }

.Home_Location {
  flex: 1 1 100%;
  height: 90px;
  background: url(/static/media/ServiceCollectionBG.836c9cce.svg) #0057AD -40px -9px no-repeat; }
  .Home_Location .Home_Location_White_Box {
    flex: 1 1 100%;
    background: #FAFBFC;
    margin: 12px 24px;
    height: 64px;
    border-radius: 10px; }
    .Home_Location .Home_Location_White_Box .H_L_W_B_Top_Box {
      flex: 1 1 100%;
      height: 50%;
      border-bottom: 1px solid #ededed;
      display: flex;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .Home_Location .Home_Location_White_Box .H_L_W_B_Top_Box .Dynamic_Parent_Icon {
        width: auto;
        margin: 0 8px 0 0; }
      .Home_Location .Home_Location_White_Box .H_L_W_B_Top_Box .H_L_W_B_Top_Text {
        width: auto;
        margin: 2px 8px 0 0; }
        .Home_Location .Home_Location_White_Box .H_L_W_B_Top_Box .H_L_W_B_Top_Text h5 {
          text-align: left;
          margin: 0;
          font-size: 12px; }
      .Home_Location .Home_Location_White_Box .H_L_W_B_Top_Box .H_L_W_B_Top_Right_Arrow_Location {
        width: auto;
        margin: 0 8px 0 0; }
        .Home_Location .Home_Location_White_Box .H_L_W_B_Top_Box .H_L_W_B_Top_Right_Arrow_Location .Right_Arrow {
          width: 10px;
          height: 10px;
          margin: 0; }
    .Home_Location .Home_Location_White_Box .H_L_W_B_Down_Box {
      flex: 1 1 100%;
      height: 50%;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex; }
      .Home_Location .Home_Location_White_Box .H_L_W_B_Down_Box .Dynamic_Parent_Icon {
        flex: 1 1 13%; }
        .Home_Location .Home_Location_White_Box .H_L_W_B_Down_Box .Dynamic_Parent_Icon .Location_Search {
          margin: 8px 0 0 20px;
          width: 16px;
          height: 16px; }
      .Home_Location .Home_Location_White_Box .H_L_W_B_Down_Box .H_L_W_B_Top_Text {
        flex: 1 1 87%; }
        .Home_Location .Home_Location_White_Box .H_L_W_B_Down_Box .H_L_W_B_Top_Text h5 {
          color: #B1B3B7;
          font-size: 12px; }
  .Home_Location .Location {
    display: flex;
    flex: 1 1 100%;
    text-align: center;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.48);
    padding: 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    align-items: center; }
    .Home_Location .Location .Location_Pin {
      width: 16px;
      height: 16px;
      margin: 0 0 0 16px; }
    .Home_Location .Location p {
      margin: 0; }
    .Home_Location .Location .Pin-icon {
      width: 18px;
      float: left; }

.Home_Featured {
  display: flex;
  flex-wrap: wrap; }
  .Home_Featured .Home_Featured_Box {
    flex: 1 1 100%;
    width: 100%;
    overflow-x: hidden; }
    .Home_Featured .Home_Featured_Box .tab-active-home h5 {
      color: #FAFBFC; }
    .Home_Featured .Home_Featured_Box .Home_Featured_Box_Service {
      text-align: center;
      flex: 1 1 33.3333%;
      float: left;
      width: 33%; }
      .Home_Featured .Home_Featured_Box .Home_Featured_Box_Service button {
        padding: 0;
        min-width: unset;
        font-size: unset;
        text-transform: none;
        width: 100%; }
  .Home_Featured .tab-item-history {
    margin: 0 12px !important; }
    .Home_Featured .tab-item-history:first-child {
      margin-left: 24px !important; }

.Featured_Title {
  padding: 0 0 8px;
  color: #FAFBFC;
  margin: 4px 0 10px 0;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-align: center; }

.Tabs_Menu_Parent .tabs-menu-tab .tab-item-history:last-child {
  padding-right: 20px; }

.Home_Parent_Box {
  position: relative;
  height: auto; }

.Home_Page {
  position: relative;
  left: 0;
  -webkit-transition: left .6s ease-in-out;
  transition: left .6s ease-in-out; }

.Search_Home_Box {
  width: 100%;
  height: 100vh;
  background: #FAFBFC;
  position: absolute;
  right: -1000%;
  z-index: 999;
  -webkit-transition: right .7s ease-in-out;
  transition: right .7s ease-in-out; }

.Home_Page_Bottom_Sheet {
  height: 485px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 40px 40px 0 0; }
  .Home_Page_Bottom_Sheet .Top_Four_Product {
    padding: 0 24px;
    flex: 1 1 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin: 24px 0 24px 0;
    min-height: 92px; }

.Empty_Down_Description {
  color: #B1B3B7;
  text-align: left;
  margin: 0;
  font-size: 12px; }
  .Empty_Down_Description span:nth-child(2) {
    font-weight: bold;
    color: #0C87FA; }

.Empty_Top_Product_Description {
  color: #B1B3B7;
  text-align: left;
  margin: 16px 8px 0 0;
  font-size: 12px; }
  .Empty_Top_Product_Description span:nth-child(2) {
    font-weight: bold;
    color: #0C87FA; }

.Animated_Location {
  right: 0 !important; }

.Navigate_Blue_Box {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  right: 0;
  background: #0070E0; }

.navigator-bottom-sheet .indicator-container {
  position: relative;
  background: #0070E0; }

.Bottom_Sheet_Parent_Home_Page {
  position: relative;
  bottom: 0;
  width: 100%;
  height: auto;
  -webkit-transition: right .55s ease-in-out;
  transition: right .55s ease-in-out; }
  .Bottom_Sheet_Parent_Home_Page .react-swipeable-view-container {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1) !important;
    border-radius: 40px 40px 0 0 !important; }
  .Bottom_Sheet_Parent_Home_Page .ReactSwipeableBottomSheet--open, .Bottom_Sheet_Parent_Home_Page .ReactSwipeableBottomSheet--closed {
    border-radius: 40px 40px 0 0 !important; }
    .Bottom_Sheet_Parent_Home_Page .ReactSwipeableBottomSheet--open::-webkit-scrollbar, .Bottom_Sheet_Parent_Home_Page .ReactSwipeableBottomSheet--closed::-webkit-scrollbar {
      width: 0; }

.Animated_HomePagePos {
  right: 0; }

.Home_Tab_Panel {
  flex-wrap: wrap;
  -webkit-animation-duration: .7s;
          animation-duration: .7s;
  flex: 1 1 100%;
  display: flex;
  width: 100%;
  float: left;
  position: relative; }

.Right_Slide {
  -webkit-animation-name: rightreducetime;
          animation-name: rightreducetime;
  -webkit-animation-duration: .7s;
          animation-duration: .7s; }

.Left_Slide {
  -webkit-animation-name: leftreducetime;
          animation-name: leftreducetime;
  -webkit-animation-duration: .7s;
          animation-duration: .7s; }

@-webkit-keyframes rightreducetime {
  0% {
    right: -100%; }
  100% {
    right: 0; } }

@keyframes rightreducetime {
  0% {
    right: -100%; }
  100% {
    right: 0; } }

@-webkit-keyframes leftreducetime {
  0% {
    left: -100%; }
  100% {
    left: 0; } }

@keyframes leftreducetime {
  0% {
    left: -100%; }
  100% {
    left: 0; } }

.Active_panel_Parent {
  left: 0; }

.slick-slider {
  max-width: 100%;
  overflow: hidden; }

.Empty_Top_Four_Btn {
  background-color: #FAFBFC !important;
  color: #0C87FA !important;
  border: 1px solid #0C87FA !important;
  outline: none;
  box-shadow: none;
  width: 100%;
  border-radius: 3px;
  padding: 8px !important;
  text-transform: none;
  font-weight: bold !important;
  margin: 8px 0 0 0 !important;
  height: 40px;
  font-size: 16px !important; }
  .Empty_Top_Four_Btn .Dynamic_Parent_Icon {
    height: auto !important; }
  .Empty_Top_Four_Btn .Btn_Text {
    text-transform: none !important; }
  .Empty_Top_Four_Btn:disabled {
    background-color: #C5C5C5 !important; }

.Home_Empty_Top_Product {
  width: 100%; }

.Gray_Middle_Line {
  width: 40px;
  height: 4px;
  background: #DBDBDB;
  margin: -13px auto;
  border-radius: 20px; }

.Black_Middle_Line {
  width: 128px;
  height: 4px;
  background: black;
  padding: 25px auto 0 auto;
  margin: 26px auto -12px auto;
  border-radius: 23px; }

@media screen and (device-aspect-ratio: 40 / 71) {
  .Black_Middle_Line {
    margin: 16px auto -12px auto !important; }
  .Home_Featured_Box {
    min-height: 240px !important; }
  .Empty_Down_Description {
    font-size: 11px; } }

.products-container {
  flex: 1 1 100%;
  width: 100%;
  min-height: 312px;
  overflow-x: hidden;
  background-color: #0070E0;
  position: relative; }
  .products-container .rec-right {
    position: absolute;
    right: 0;
    top: 100px; }
  .products-container .home-bottom {
    position: absolute;
    bottom: 0;
    right: 0; }
  .products-container .bottom-ellipse {
    position: absolute;
    bottom: 0;
    left: 80px; }
  .products-container .shop-name {
    width: 100%;
    padding: 8px 20px 0; }
    .products-container .shop-name h1 {
      border-bottom: 1px solid #B1B3B7;
      margin: 0;
      padding-bottom: 8px;
      color: #FAFBFC;
      text-align: center; }

.Home_Search_Box_Mobile {
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0; }

.Home_Search_Box_Desktop {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap; }

.Home_Product_Box {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 150px 0 0 0;
  max-height: 600px;
  overflow: auto; }

.Home_Search_Location {
  flex: 1 1 100%;
  height: 56px;
  background: #FAFBFC;
  display: flex;
  align-items: center;
  margin: 0 16px; }
  .Home_Search_Location .H_S_L_Left_Arrow {
    width: auto;
    margin: 0 -16px 0 0; }
    .Home_Search_Location .H_S_L_Left_Arrow .Left_Arrow {
      width: 10px;
      height: 10px;
      margin: 15px 8px; }
  .Home_Search_Location .H_S_L_Pin {
    width: auto;
    margin: 13px 5px 12px 0; }
  .Home_Search_Location .H_S_L_Text {
    width: auto;
    margin: 0 4px 0 0; }
    .Home_Search_Location .H_S_L_Text h5 {
      margin: 13px 0 0 0;
      font-size: 13px; }
  .Home_Search_Location .H_S_L_Arrow_Location {
    width: auto;
    margin: 0 8px 0 0; }
    .Home_Search_Location .H_S_L_Arrow_Location .Dynamic_Parent_Icon {
      margin: 18px 8px; }

.Center_Search_Service {
  min-width: 80%;
  display: flex;
  margin: 0 auto;
  align-items: stretch; }

.Home_SearchLocation_Pin {
  width: 16px;
  height: 16px;
  margin: -1px; }

.Right_Arrow_Search_Location {
  margin: -5px;
  width: 23px;
  height: 13px; }

.Home_Search_Input_Box {
  flex: 1 1 100%;
  height: 90px;
  display: flex;
  background: #0057AD; }
  .Home_Search_Input_Box .Search_Box {
    flex: 1 1 100%;
    background: url(/static/media/ServiceCollectionBG.836c9cce.svg) #0057AD -29px -66px no-repeat; }
    .Home_Search_Input_Box .Search_Box .Search_Box_Form {
      position: relative;
      margin: 25px 24px; }
      .Home_Search_Input_Box .Search_Box .Search_Box_Form .Dynamic_Parent_Icon {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 7px;
        left: 4px; }
      .Home_Search_Input_Box .Search_Box .Search_Box_Form .form-control {
        border-radius: 8px !important;
        padding: 0 24px !important; }
      .Home_Search_Input_Box .Search_Box .Search_Box_Form .Cancel_Img {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 24px;
        height: 24px;
        display: none; }
      .Home_Search_Input_Box .Search_Box .Search_Box_Form .Show {
        display: block; }
  .Home_Search_Input_Box .Search_Box_Focused {
    flex: 1 1 80% !important; }
  .Home_Search_Input_Box .Button_Box {
    flex: 1 1 20%;
    background: url(/static/media/ServiceCollectionBG.836c9cce.svg) #0157AD -323px -345px no-repeat;
    display: none; }
    .Home_Search_Input_Box .Button_Box .H_S_I_B_Cancel_Btn {
      text-transform: none !important;
      width: 100%;
      outline: none;
      box-shadow: none;
      font-weight: 600;
      color: white;
      border: none;
      border-radius: 3px;
      padding: 5px !important;
      font-size: 12px;
      margin: 18px -16px;
      font-family: 'Poppins'; }
  .Home_Search_Input_Box .Button_Box_Focused {
    display: flex !important; }

.Search_Placeholder {
  font-size: 11px !important;
  height: 40px !important; }

.Home_Search_Product {
  display: flex;
  flex: 1 1 100%;
  margin: 8px 16px;
  height: 40px;
  border-bottom: 1px solid #ededed; }
  .Home_Search_Product .H_S_P_Icon {
    flex: 1 1 20%;
    height: 100%; }
    .Home_Search_Product .H_S_P_Icon img {
      width: 32px;
      height: 32px;
      margin: 0 16px; }
  .Home_Search_Product .H_S_P_Text {
    flex: 1 1 80%;
    height: 100%;
    margin: 4px 0;
    color: #898E92;
    font-size: 13px; }
    .Home_Search_Product .H_S_P_Text h3 {
      font-size: 13px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 18px 0 0 0; }
    .Home_Search_Product .H_S_P_Text h5 {
      font-size: 10px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 5px 0 0 0; }
  .Home_Search_Product .S_P_P_Product {
    flex: 1 1 80%;
    height: 100%;
    margin: 4px 0;
    color: #3B424A; }
    .Home_Search_Product .S_P_P_Product h3 {
      font-size: 13px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 18px 0 0 0; }
    .Home_Search_Product .S_P_P_Product h5 {
      font-size: 10px;
      font-weight: 600;
      color: #bfc3c6;
      margin: 5px 0 0 0; }

.High_Lighted {
  font-weight: bold;
  color: black;
  background: #fff !important;
  padding: 0 !important; }

.Home_Empty_Box {
  display: flex;
  flex: 1 1 100%;
  height: auto;
  flex-wrap: wrap;
  margin: 150px  0 0 0; }
  .Home_Empty_Box .Dynamic_Parent_Icon {
    flex: 1 1 100%;
    width: 100%;
    height: 100%; }

.Empty {
  margin: 0 !important; }

.Search_Empty_Product {
  margin: 24px auto 16px auto; }

.Not_Found_Title {
  margin: 24px 0 16px 0;
  text-align: center;
  font-weight: 600;
  flex: 1 1;
  font-size: 19px;
  color: #3B424A; }

.Empty_Search_Text_Home {
  text-align: center;
  margin: 0 24px;
  font-size: 12px;
  width: 100%;
  color: #3B424A; }
  .Empty_Search_Text_Home .Get_Help_Strong {
    color: #0070E0;
    font-weight: bold; }

.Show_Search_Background, .Hide_Search_Background {
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: column; }

@media screen and (device-aspect-ratio: 40 / 71) {
  .Hide_Search_Background {
    display: none !important; } }

@media only screen and (max-width: 320px) {
  .Show_Search_Background {
    display: none !important; } }

.Service-box .Service_Box_Container {
  border-radius: 50%;
  background: #fff;
  width: 40px;
  height: 40px;
  margin: auto;
  position: relative;
  overflow: hidden; }

.Service-box img {
  width: 100%;
  height: 100%; }

.Service-box p {
  font-size: 10px;
  text-align: center;
  white-space: nowrap; }

.T_F_P_Item {
  width: 25%;
  flex: 1 1 25%;
  display: flex;
  height: auto;
  flex-wrap: wrap; }
  .T_F_P_Item .T_F_P_I_Icon {
    flex: 1 1 100%;
    max-height: 32px; }
    .T_F_P_Item .T_F_P_I_Icon img {
      width: 32px;
      height: 32px;
      margin: 0 auto;
      border-radius: 100%;
      display: block; }
  .T_F_P_Item .T_F_P_I_Text {
    flex: 1 1;
    font-size: 16px;
    height: 50px; }
    .T_F_P_Item .T_F_P_I_Text h5 {
      font-size: 12px;
      text-align: center;
      font-weight: 400;
      line-height: 18px;
      margin: 0 4px; }

.Top_Four_Box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%; }

.N_B_B_Row {
  flex: 1 1 100%;
  margin: 24px 16px 0 16px;
  flex-wrap: wrap;
  display: flex;
  padding: 0;
  border-bottom: 1px solid #FAFBFC;
  height: 64px; }
  .N_B_B_Row:last-child {
    border-bottom: 0; }
  .N_B_B_Row .N_B_B_R_Icon {
    flex: 1 1 15%;
    width: 15%; }
    .N_B_B_Row .N_B_B_R_Icon img {
      margin: 8px 16px; }
  .N_B_B_Row .N_B_B_R_Text {
    color: #FAFBFC;
    flex: 1 1 85%;
    width: 85%; }
    .N_B_B_Row .N_B_B_R_Text h5 {
      margin: 4px 0;
      text-align: left; }
    .N_B_B_Row .N_B_B_R_Text h5:nth-child(1) {
      font-size: 13px;
      font-weight: 600; }
    .N_B_B_Row .N_B_B_R_Text h5:nth-child(2) {
      font-size: 12px; }

.Whats_New {
  padding: 0 0 0 20px;
  margin: 0 0 150px 0; }
  .Whats_New .Whats_New_Scroll_Mobile {
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap; }
  .Whats_New .Whats_New_Scroll_Desktop {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap; }
    .Whats_New .Whats_New_Scroll_Desktop::-webkit-scrollbar {
      width: 0; }
  .Whats_New .Whats_New_Title {
    flex: 1 1 100%;
    width: 100%; }
    .Whats_New .Whats_New_Title h1 {
      color: #3B424A;
      margin: 12px 0; }
  .Whats_New .Whats_New_Slider_Box_Mobile, .Whats_New .Whats_New_Slider_Box_Desktop {
    background-color: #FAFBFC;
    display: inline-block;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 24px 16px 0; }
    .Whats_New .Whats_New_Slider_Box_Mobile .Whats_New_Slider_Container, .Whats_New .Whats_New_Slider_Box_Desktop .Whats_New_Slider_Container {
      text-align: center; }
      .Whats_New .Whats_New_Slider_Box_Mobile .Whats_New_Slider_Container img, .Whats_New .Whats_New_Slider_Box_Desktop .Whats_New_Slider_Container img {
        max-height: 135px;
        max-width: 100%;
        width: 100%;
        height: 145px; }
      .Whats_New .Whats_New_Slider_Box_Mobile .Whats_New_Slider_Container .Whats_New_More, .Whats_New .Whats_New_Slider_Box_Desktop .Whats_New_Slider_Container .Whats_New_More {
        padding: 10px;
        text-align: left; }
        .Whats_New .Whats_New_Slider_Box_Mobile .Whats_New_Slider_Container .Whats_New_More p, .Whats_New .Whats_New_Slider_Box_Desktop .Whats_New_Slider_Container .Whats_New_More p {
          margin: 0; }
        .Whats_New .Whats_New_Slider_Box_Mobile .Whats_New_Slider_Container .Whats_New_More .Whats_New_More_Title, .Whats_New .Whats_New_Slider_Box_Desktop .Whats_New_Slider_Container .Whats_New_More .Whats_New_More_Title {
          margin-bottom: 5px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .Whats_New .Whats_New_Slider_Box_Mobile .Whats_New_Slider_Container .Whats_New_More .Whats_New_More_Link, .Whats_New .Whats_New_Slider_Box_Desktop .Whats_New_Slider_Container .Whats_New_More .Whats_New_More_Link {
          padding: 8px 0px 0px 0px;
          border-top: 1px solid #E6EBEF;
          margin-top: 10px;
          font-weight: bold;
          color: #0C87FA !important;
          text-align: center; }
          .Whats_New .Whats_New_Slider_Box_Mobile .Whats_New_Slider_Container .Whats_New_More .Whats_New_More_Link a, .Whats_New .Whats_New_Slider_Box_Desktop .Whats_New_Slider_Container .Whats_New_More .Whats_New_More_Link a {
            color: #0C87FA !important; }

.Whats_New_Slider_Box_Mobile {
  flex: 1 1 80%;
  width: 80%; }

.Whats_New_Slider_Box_Desktop {
  flex: 1 1 65%;
  width: 65%; }

.News_MarginBottom {
  margin-bottom: 40px; }

.Whats_new_scroll::-webkit-scrollbar {
  width: 0; }

.Time_Read {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  height: 16px; }
  .Time_Read .Time_Read_Icon {
    width: 5%; }
  .Time_Read .Time_Read_Text {
    flex: 1 1 85%;
    width: 15%; }
    .Time_Read .Time_Read_Text h5 {
      margin: 2px !important;
      text-align: left;
      font-size: 12px; }

.News_Link {
  width: 100%;
  text-align: right;
  color: #0C87FA;
  margin: 0;
  padding: 0 24px 0 0;
  font-size: 12px;
  font-weight: bold; }

.Active_Jobs {
  padding: 0 0 0 20px;
  margin: 0; }
  .Active_Jobs .Active_Jobs_Scroll {
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; }
  .Active_Jobs .Active_Jobs_Title {
    flex: 1 1 100%;
    width: 100%; }
    .Active_Jobs .Active_Jobs_Title h1 {
      color: #3B424A;
      margin: 16px 0; }
  .Active_Jobs .Active_Jobs_Slider_Box {
    flex: 1 1 80%;
    width: 80%;
    background-color: #FAFBFC;
    display: inline-block;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 24px 12px 0; }
    .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 100%;
      text-align: center; }
      .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_Map {
        width: 35%;
        position: relative;
        float: left; }
        .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_Map .Map_Img {
          width: 100%;
          height: 100%; }
        .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_Map .location-icon {
          position: absolute;
          width: 32px;
          height: 32px;
          left: calc(50% - 32px/2 + 1px);
          top: calc(50% - 32px/2);
          -webkit-filter: drop-shadow(0px 2px 4px rgba(24, 26, 30, 0.15));
                  filter: drop-shadow(0px 2px 4px rgba(24, 26, 30, 0.15)); }
          .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_Map .location-icon img {
            width: 32px;
            height: 32px; }
        .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_Map .Partner_Img {
          position: absolute;
          width: 32px;
          height: 32px;
          background: transparent;
          left: 34px;
          top: 67px; }
      .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_Content {
        width: 65%;
        height: auto;
        padding: 20px 8px; }
      .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_C_State {
        width: 100%;
        height: auto;
        margin-bottom: 12px; }
        .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_C_State .lozenge-container .lozenge-inner .lozenge-title p {
          letter-spacing: 1.5px; }
        .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_C_State .H_A_J_C_C_S_Child_Service {
          font-size: 15px;
          text-align: left;
          font-weight: 600;
          margin: 8px 0 0; }
      .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_C_Row {
        display: flex;
        align-items: center;
        margin-bottom: 8px; }
        .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_C_Row .Dynamic_Parent_Icon {
          width: 16px;
          height: 16px; }
          .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_C_Row .Dynamic_Parent_Icon img {
            width: 16px;
            height: 16px; }
        .Active_Jobs .Active_Jobs_Slider_Box .Home_Active_Job_Container .H_A_J_C_C_Row .H_A_J_C_C_R_Text h5 {
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #3B424A;
          margin: 0 0 0 4px; }

.Active_Jobs_Scroll::-webkit-scrollbar {
  width: 0; }

.Time_Left_Red {
  color: #E34B34 !important; }

.Time_Left_Black {
  color: #3B424A; }

.Active_Job_Link {
  width: 100%;
  text-align: right;
  color: #0C87FA;
  margin: 0;
  padding: 0 24px 0 0;
  font-size: 12px;
  font-weight: bold; }

.Home_Sub_Categories {
  display: flex;
  width: 100%; }

.Sub_Category_Parent {
  display: flex;
  flex: 1 1 100%;
  margin: 68px 16px 0  16px;
  height: 40px; }
  .Sub_Category_Parent .H_S_P_Text h3 {
    font-size: 19px;
    margin: 16px 10px; }
  .Sub_Category_Parent .H_S_P_Icon img {
    width: 48px;
    height: 48px; }

.Sub_Parent_Product {
  margin: 16px 0 0 0 !important; }

.Home_Empty_Services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 1 100%;
  flex-direction: column; }

.Home_Empty_Services .Dynamic_Parent_Icon {
  width: 100%;
  height: 100%;
  margin: 8px auto 0 auto; }

.Home_Search_Empty {
  margin: 36px auto 0px auto; }

.Empty_Description {
  margin: 16px auto 0 auto;
  color: #fff;
  text-align: center;
  font-size: 12px; }

.Empty_Service_Btn {
  background-color: #FAFBFC !important;
  color: #0C87FA !important;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  text-transform: none;
  font-weight: 600 !important;
  margin: 0 24px !important;
  height: 46px;
  font-size: 18px !important;
  line-height: 27px; }
  .Empty_Service_Btn .Dynamic_Parent_Icon {
    height: auto !important; }
  .Empty_Service_Btn .Btn_Text {
    text-transform: none !important; }
  .Empty_Service_Btn:disabled {
    background-color: #C5C5C5 !important; }

.Location_Pin_Empty {
  width: 24px;
  height: 24px;
  margin-right: 8px; }

.Top_Four_Empty {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  width: 100%; }
  .Top_Four_Empty .Empty_Down_Description {
    margin-top: 8px;
    color: #B1B3B7;
    margin: 0; }
    .Top_Four_Empty .Empty_Down_Description span {
      color: #0C87FA; }

.Empty_Top_Four {
  background-color: #FAFBFC !important;
  color: #0C87FA !important;
  outline: none;
  border: 1px solid #0C87FA !important;
  box-shadow: none;
  width: 100%;
  border-radius: 5px;
  padding: 8px !important;
  text-transform: none;
  font-weight: bold !important;
  margin: 0 !important;
  height: 46px;
  font-size: 18px !important;
  letter-spacing: 0.25px; }
  .Empty_Top_Four .Dynamic_Parent_Icon {
    height: auto !important; }
  .Empty_Top_Four .Btn_Text {
    text-transform: none !important; }
  .Empty_Top_Four:disabled {
    background-color: #C5C5C5 !important; }

.booking-info-container .booking-service {
  padding: 20px;
  position: fixed;
  top: 16px;
  width: 100%;
  z-index: 1;
  background: #FAFBFC; }

.booking-info-container .booking-service-body {
  padding: 90px 0;
  background: #FAFBFC; }
  .booking-info-container .booking-service-body .got-it-button {
    position: fixed;
    bottom: 0;
    padding: 16px;
    background-color: #FAFBFC;
    width: 100%; }
    .booking-info-container .booking-service-body .got-it-button .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%; }

.booking-info-container + .close-button-container {
  position: fixed;
  bottom: 0;
  padding: 20px;
  background: #FAFBFC;
  width: 100%; }
  .booking-info-container + .close-button-container button {
    background: #0C87FA;
    border-radius: 5px;
    color: #FAFBFC;
    text-transform: none;
    width: 100%;
    border: 0;
    padding: 8px 16px; }

.booking-service {
  padding: 65px 20px 0;
  display: flex;
  align-items: center; }
  .booking-service .booking-service-wrapper {
    flex-basis: 100%;
    display: flex;
    align-items: center; }
    .booking-service .booking-service-wrapper .booking-service-product-image img {
      width: 48px;
      height: 48px; }
    .booking-service .booking-service-wrapper .booking-service-product {
      flex-basis: 80%;
      max-width: 80%;
      padding: 0 16px; }
  .booking-service .booking-service-product p {
    margin: 0; }
  .booking-service .booking-service-product .booking-service-name {
    color: #3B424A;
    margin-bottom: 0; }
  .booking-service .booking-service-product .booking-service-description {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #898E92; }
  .booking-service .info-icon {
    position: absolute;
    right: 20px; }

.prgress-bar {
  padding: 0 20px; }

.booking-location-box {
  padding: 24px 20px 0; }
  .booking-location-box h2 {
    margin: 0 0 16px;
    color: #3B424A; }
  .booking-location-box .location-box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 15px 8px;
    border-bottom: 1px solid #DBDBDB;
    width: 100%;
    background-color: #FAFBFC; }
    .booking-location-box .location-box-container .icons-wrapper {
      display: flex;
      align-items: center; }

.booking-header {
  height: 160px;
  background-position: center !important;
  background-size: cover !important; }

.booking-service-description-component {
  padding: 25px 20px; }
  .booking-service-description-component p {
    color: #3B424A;
    margin: 0;
    white-space: pre-line; }

.request-container {
  background-color: #FAFBFC;
  padding-bottom: 50px; }

.review-booking-button-container {
  padding: 24px 16px;
  bottom: 0;
  width: 100%;
  background-color: #0070E0;
  display: flex;
  align-items: center; }
  .review-booking-button-container .review-booking-button {
    flex-basis: 70%;
    max-width: 70%; }
    .review-booking-button-container .review-booking-button .button-container .MuiButton-root {
      width: 100%;
      background-color: #FAFBFC;
      border-radius: 5px;
      color: #0C87FA;
      padding: 8px;
      text-transform: none;
      letter-spacing: .25px; }
  .review-booking-button-container .price-container {
    flex-basis: 30%;
    max-width: 30%;
    color: #FAFBFC;
    padding-right: 16px;
    align-items: flex-end;
    display: flex;
    flex-direction: column; }
    .review-booking-button-container .price-container p {
      margin-bottom: 0; }

.booking-special {
  padding: 40px 20px 0; }
  .booking-special .booking-special-title p {
    margin-bottom: 24px;
    color: #3B424A; }
  .booking-special .booking-special-textarea.form-group-container {
    border-bottom: 1px solid #DBDBDB;
    padding: 0 8px; }
  .booking-special .booking-special-textarea label {
    color: #2F353B;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal; }
  .booking-special .booking-special-textarea textarea {
    width: 100%;
    background-color: #FAFBFC;
    border: 0;
    padding: 0; }
    .booking-special .booking-special-textarea textarea:focus {
      outline: none; }
    .booking-special .booking-special-textarea textarea::-webkit-input-placeholder {
      color: #DBDBDB; }
    .booking-special .booking-special-textarea textarea::-moz-placeholder {
      color: #DBDBDB; }
    .booking-special .booking-special-textarea textarea:-ms-input-placeholder {
      color: #DBDBDB; }
    .booking-special .booking-special-textarea textarea::-ms-input-placeholder {
      color: #DBDBDB; }
    .booking-special .booking-special-textarea textarea::placeholder {
      color: #DBDBDB; }
    .booking-special .booking-special-textarea textarea::-webkit-scrollbar {
      width: 0; }
    .booking-special .booking-special-textarea textarea:placeholder, .booking-special .booking-special-textarea textarea:-moz-placeholder, .booking-special .booking-special-textarea textarea:-ms-input-placeholder, .booking-special .booking-special-textarea textarea::-webkit-input-placeholder {
      color: #DBDBDB; }

.review-product .tip {
  color: #898E92;
  padding: 24px 20px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin: 0; }

.review-product .title-bar-container .title-bar-icon {
  display: flex;
  align-items: center; }
  .review-product .title-bar-container .title-bar-icon .lozenge-container {
    padding-left: 16px; }
    .review-product .title-bar-container .title-bar-icon .lozenge-container .color-violet {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
  .review-product .title-bar-container .title-bar-icon .lozenge-title p {
    text-transform: uppercase; }

.review-booking-item-container {
  padding: 32px 20px 0; }
  .review-booking-item-container.booking-note {
    margin-bottom: 24px; }
  .review-booking-item-container .title {
    color: #898E92;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    padding: 0 8px; }
  .review-booking-item-container .review-booking-item-inner {
    border-bottom: 1px solid #DBDBDB;
    padding: 0 8px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .review-booking-item-container .review-booking-item-inner p {
      color: #3B424A;
      margin: 0;
      flex-basis: 90%;
      max-width: 90%;
      overflow-wrap: break-word;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      margin: 0; }
    .review-booking-item-container .review-booking-item-inner + .hint {
      color: #898E92;
      padding: 0 8px;
      display: block; }
  .review-booking-item-container .promo-code {
    margin: 0;
    color: #81B7E9;
    padding: 0 8px;
    font-weight: 600; }

.review-container {
  position: relative;
  overflow-y: auto; }

.cancel-booking-container {
  padding: 0 20px;
  background-color: #FAFBFC; }
  .cancel-booking-container .Sheet_Content {
    width: 100%; }
  .cancel-booking-container h2 {
    color: #3B424A;
    margin-bottom: 8px;
    text-align: center; }
  .cancel-booking-container p {
    color: #3B424A;
    margin-bottom: 0;
    text-align: center; }
  .cancel-booking-container .cancel-booking-bottom-sheet {
    padding-top: 32px;
    padding-bottom: 8px; }
    .cancel-booking-container .cancel-booking-bottom-sheet img {
      max-width: 100%; }
  .cancel-booking-container .cancel-booking-bottom-sheet-buttons {
    display: flex;
    align-items: center;
    margin: 16px 0; }
    .cancel-booking-container .cancel-booking-bottom-sheet-buttons .cancel-booking-button {
      border: 1px solid #FC6A54;
      flex-basis: 70%;
      max-width: 70%;
      margin-right: 16px;
      border-radius: 5px; }
      .cancel-booking-container .cancel-booking-bottom-sheet-buttons .cancel-booking-button.button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background-color: #FAFBFC;
        width: 100%;
        color: #FC6A54; }
    .cancel-booking-container .cancel-booking-bottom-sheet-buttons .back {
      flex-basis: 30%;
      max-width: 30%; }
      .cancel-booking-container .cancel-booking-bottom-sheet-buttons .back.button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        width: 100%; }
  .cancel-booking-container .chat-with-support {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 20px; }
    .cancel-booking-container .chat-with-support span {
      padding-left: 8px;
      color: #0C87FA; }

.toast-rejected-payment {
  border-radius: 100px;
  background-color: #3B424A;
  background: #3B424A;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 16px auto;
  padding: 16px 24px; }
  .toast-rejected-payment .Toastify__toast-body.toastify-inner {
    margin: 0;
    text-align: center;
    color: #FAFBFC; }
  .toast-rejected-payment .Toastify__close-button {
    display: none; }

.job-locations-container {
  padding-top: 8px; }
  .job-locations-container .icon-container {
    position: relative; }
    .job-locations-container .icon-container .number {
      position: absolute;
      color: #B1B3B7;
      bottom: 5px;
      right: 1px; }
  .job-locations-container .review-booking-item-inner {
    margin-bottom: 16px; }
    .job-locations-container .review-booking-item-inner:last-child {
      margin-bottom: 0; }

.payment-method-bottom-sheet {
  padding-bottom: 24px;
  background-color: #FAFBFC; }
  .payment-method-bottom-sheet .title-bar-container {
    border-radius: 20px 20px 0 0;
    top: 20px; }
  .payment-method-bottom-sheet .Sheet_Content {
    width: 100%; }
  .payment-method-bottom-sheet .payment-methods-container {
    padding-top: 85px; }
  .payment-method-bottom-sheet .tip {
    color: #898E92;
    padding: 0 20px;
    margin: 0;
    background-color: #FAFBFC; }
  .payment-method-bottom-sheet .billing-information-add {
    padding: 0 20px 24px; }
  .payment-method-bottom-sheet .mykoin-inner .billing-information-card-item-name {
    display: flex;
    align-items: center; }
    .payment-method-bottom-sheet .mykoin-inner .billing-information-card-item-name .lozenge-container {
      padding-left: 8px; }

.payment-methods-container {
  width: 100%; }

.billing-information-card-item-value {
  display: flex;
  justify-content: space-between; }
  .billing-information-card-item-value .top-up-mykoin-container {
    display: flex; }
    .billing-information-card-item-value .top-up-mykoin-container p {
      font-size: 13px;
      color: #B1B3B7;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-left: 2px; }
      .billing-information-card-item-value .top-up-mykoin-container p svg {
        width: 8px;
        height: 10px;
        margin-left: 6px; }
    .billing-information-card-item-value .top-up-mykoin-container span {
      font-size: 12px;
      color: #FC6A54; }

.review-booking-item-container.mykoin .mykoin-image {
  width: 16px;
  height: 16px; }

.booking-button-container {
  background-color: #FAFBFC;
  padding: 0 20px 20px;
  bottom: 0;
  width: 100%; }
  .booking-button-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    width: 100%;
    padding: 8px; }
  .booking-button-container .booking-button-icon {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 16px; }
    .booking-button-container .booking-button-icon .icon {
      position: absolute;
      top: -6px;
      right: 3px; }
  .booking-button-container .cancel-booking .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    color: #FC6A54;
    border: 0;
    margin-top: 8px;
    background-color: #FAFBFC;
    box-shadow: none; }
    .booking-button-container .cancel-booking .MuiButtonBase-root.MuiButton-root.MuiButton-text:focus {
      box-shadow: none; }
  .booking-button-container .schedule-booking-button {
    margin: 15px 0 0; }
    .booking-button-container .schedule-booking-button p {
      margin: 0; }
    .booking-button-container .schedule-booking-button svg {
      width: 25px;
      height: 25px;
      margin-right: 5px; }

.booking-price-error {
  padding: 20px;
  background-color: #FAFBFC; }
  .booking-price-error .booking-price-error-content {
    display: flex;
    margin-bottom: 24px; }
    .booking-price-error .booking-price-error-content .booking-price-description {
      padding-left: 16px; }
      .booking-price-error .booking-price-error-content .booking-price-description .title {
        color: #3B424A;
        margin: 0; }
      .booking-price-error .booking-price-error-content .booking-price-description .text-secondary {
        color: #898E92;
        margin: 0; }
  .booking-price-error .booking-price-error-button .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    width: 100%; }

.rejected-payment-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #FAFBFC; }
  .rejected-payment-container img {
    max-width: 100%;
    height: auto;
    display: block; }
  @media (min-width: 400px) {
    .rejected-payment-container {
      padding: 24px; } }
  .rejected-payment-container .rejected-payment-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px 0 24px;
    text-align: center; }
    .rejected-payment-container .rejected-payment-header h2 {
      color: #3B424A;
      margin: 0; }
    .rejected-payment-container .rejected-payment-header h6 {
      color: #3B424A;
      font-size: 13px;
      line-height: 20px;
      margin: 5px 0 0; }
  .rejected-payment-container .rejected-payment-body {
    display: flex;
    align-items: center;
    padding: 24px 16px; }
    .rejected-payment-container .rejected-payment-body img {
      width: 16px;
      height: 16px; }
    .rejected-payment-container .rejected-payment-body p {
      color: #3B424A;
      margin-bottom: 0;
      padding-left: 24px; }
  .rejected-payment-container .rejected-payment-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center; }
    .rejected-payment-container .rejected-payment-bottom .button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text,
    .rejected-payment-container .rejected-payment-bottom .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%; }
    .rejected-payment-container .rejected-payment-bottom .button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      margin: 16px 0 8px; }
    .rejected-payment-container .rejected-payment-bottom .chat-with-support {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 8px; }
      .rejected-payment-container .rejected-payment-bottom .chat-with-support span {
        padding-left: 8px;
        color: #0C87FA; }

.rejected-payment-bottom-sheet .Sheet_Content {
  width: 100%; }

.how-it-works-wrapper {
  padding: 0 20px;
  margin-bottom: 24px; }

.how-it-works-container {
  background-color: #F5D1CC;
  padding: 16px 20px 0 20px;
  box-shadow: 0px 2px 4px rgba(24, 26, 30, 0.15);
  border-radius: 16px;
  display: flex; }
  .how-it-works-container .wrapper-image {
    max-width: 40%;
    flex-basis: 40%;
    display: flex;
    align-items: flex-end; }
    .how-it-works-container .wrapper-image img {
      max-width: 100%;
      height: auto;
      display: block; }
  .how-it-works-container .content-container {
    max-width: 60%;
    flex-basis: 60%;
    padding: 0 0 16px 16px; }
    .how-it-works-container .content-container h6 {
      color: #3B424A;
      margin: 0 0 8px; }
    .how-it-works-container .content-container p {
      color: #3B424A;
      margin: 0; }

.promo-code-bottom-sheet {
  background-color: #FAFBFC; }
  .promo-code-bottom-sheet .title {
    padding: 20px 0;
    text-align: center;
    margin-top: 10px; }
    .promo-code-bottom-sheet .title h2 {
      color: #3B424A;
      margin: 0; }
  .promo-code-bottom-sheet .promo-code-container {
    padding: 0 24px;
    margin-bottom: 20px; }
  .promo-code-bottom-sheet .promo-code-form {
    display: flex;
    flex-direction: column; }
    .promo-code-bottom-sheet .promo-code-form label {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #898E92;
      margin-bottom: 0; }
    .promo-code-bottom-sheet .promo-code-form input {
      border: 0;
      padding: 0;
      font-size: 13px;
      line-height: 24px;
      width: 100%;
      border-radius: 0; }
      .promo-code-bottom-sheet .promo-code-form input:placeholder {
        color: #DBDBDB; }
      .promo-code-bottom-sheet .promo-code-form input::-moz-placeholder {
        color: #DBDBDB; }
      .promo-code-bottom-sheet .promo-code-form input::-webkit-placeholder {
        color: #DBDBDB; }
      .promo-code-bottom-sheet .promo-code-form input:placeholder, .promo-code-bottom-sheet .promo-code-form input:-moz-placeholder, .promo-code-bottom-sheet .promo-code-form input:-ms-input-placeholder, .promo-code-bottom-sheet .promo-code-form input::-webkit-input-placeholder {
        color: #DBDBDB; }
      .promo-code-bottom-sheet .promo-code-form input:focus {
        outline: none;
        box-shadow: none; }
  .promo-code-bottom-sheet .promo-code-button-container {
    width: 100%;
    display: flex;
    padding: 10px 24px 20px; }
    .promo-code-bottom-sheet .promo-code-button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      padding: 10px;
      background: #0C87FA;
      border-radius: 5px;
      color: #FAFBFC;
      text-transform: none;
      width: 100%;
      letter-spacing: 0.25px; }
    .promo-code-bottom-sheet .promo-code-button-container .MuiButtonBase-root.MuiButton-root.Mui-disabled {
      background-color: #E2EEF8;
      color: #81B7E9; }
    .promo-code-bottom-sheet .promo-code-button-container .cancel-button {
      flex-basis: 40%;
      max-width: 40%; }
      .promo-code-bottom-sheet .promo-code-button-container .cancel-button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background-color: #FAFBFC;
        color: #0C87FA; }
    .promo-code-bottom-sheet .promo-code-button-container .apply-code-button {
      flex-basis: 60%;
      max-width: 60%; }

.real-price {
  text-decoration: line-through; }

.new-price {
  font-weight: 600; }

.applide-promo-code {
  display: flex;
  padding: 0 5px;
  color: #81B7E9;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.4px; }
  .applide-promo-code p {
    margin: 0; }
  .applide-promo-code .static-text {
    margin-left: 3px; }

.booking-inner {
  overflow-y: scroll;
  padding-bottom: 80px;
  width: 100%;
  display: inline; }

.call-to-action-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;
  background-color: #FAFBFC; }
  .call-to-action-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    padding: 8px 32px; }

.booking .react-swipeable-view-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }

.booking .Sheet_Content {
  width: 100%; }

.booking .ReactSwipeableBottomSheet--open, .booking .ReactSwipeableBottomSheet--closed {
  border-radius: 20px 20px 0 0 !important; }
  .booking .ReactSwipeableBottomSheet--open::-webkit-scrollbar, .booking .ReactSwipeableBottomSheet--closed::-webkit-scrollbar {
    width: 0; }

.product-info-bottom-sheet,
.choose-location-bottom-sheet {
  height: 450px;
  background-color: #FAFBFC;
  border-radius: 20px 20px 0 0; }

.choose-location-bottom-sheet .indicator-container {
  position: fixed;
  border-radius: 20px 20px 0 0;
  z-index: 2; }

.choose-location-bottom-sheet .location-bottom-sheet-container {
  position: relative;
  overflow-y: auto;
  position: relative;
  border-radius: 20px 20px 0 0;
  display: inline; }
  .choose-location-bottom-sheet .location-bottom-sheet-container::-webkit-scrollbar {
    width: 0; }
  .choose-location-bottom-sheet .location-bottom-sheet-container .location-search-suggestions {
    margin-top: 0; }

.choose-location-bottom-sheet .title-bar-container {
  top: 10px; }

.matching-process {
  padding: 0 20px; }
  .matching-process .matching-process-title {
    padding: 24px 0 10px;
    border-bottom: 1px solid #DBDBDB;
    display: flex;
    align-items: center; }
    .matching-process .matching-process-title .partner-icon {
      width: 48px;
      height: 48px; }
      .matching-process .matching-process-title .partner-icon img {
        border-radius: 50%;
        max-width: 100%;
        width: 100%;
        display: block;
        height: 100%; }
    .matching-process .matching-process-title .booking-button-icon {
      position: relative; }
      .matching-process .matching-process-title .booking-button-icon .kuya-headshot {
        width: 38px;
        height: 38px; }
      .matching-process .matching-process-title .booking-button-icon .icon {
        position: absolute;
        top: -10px;
        right: -13px;
        width: 20px;
        height: 24px; }
    .matching-process .matching-process-title .title {
      padding-left: 10px;
      display: flex;
      flex-direction: column; }
      .matching-process .matching-process-title .title p {
        margin-bottom: 0;
        color: #3B424A; }
      .matching-process .matching-process-title .title .text-secondary {
        color: #898E92;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px; }
  .matching-process .matching-process-container {
    padding: 40px 20px 40px 16px; }
    .matching-process .matching-process-container .matching-process-row {
      display: flex;
      align-items: center;
      padding-left: 4px;
      margin-bottom: 4px; }
      .matching-process .matching-process-container .matching-process-row.payment {
        padding-left: 3px; }
      .matching-process .matching-process-container .matching-process-row .billing-card-image.mc {
        width: 18px;
        height: 11px; }
        .matching-process .matching-process-container .matching-process-row .billing-card-image.mc + p {
          padding-left: 14px; }
      .matching-process .matching-process-container .matching-process-row .billing-card-image.visa {
        width: 20px;
        height: 7px; }
        .matching-process .matching-process-container .matching-process-row .billing-card-image.visa + p {
          padding-left: 13px; }
      .matching-process .matching-process-container .matching-process-row p {
        margin-bottom: 0;
        color: #3B424A;
        padding-left: 16px;
        height: 20px;
        overflow: hidden;
        width: 100%; }
      .matching-process .matching-process-container .matching-process-row .icon-card.mc {
        width: 16px;
        height: 10px; }
      .matching-process .matching-process-container .matching-process-row .icon-card.visa {
        width: 16px;
        height: 6px; }
      .matching-process .matching-process-container .matching-process-row .mykoin-image {
        width: 13px;
        height: 13px;
        padding: 1px; }
        .matching-process .matching-process-container .matching-process-row .mykoin-image + p {
          padding-left: 19px; }
  .matching-process .tip {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #898E92;
    margin-bottom: 0; }

@media (max-width: 400px) {
  .matching-process .matching-process-container {
    padding: 40px 0; } }

.job-matching-circle-ripple {
  position: relative;
  padding-top: 65px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .job-matching-circle-ripple .circle-ripple {
    background-color: #0787fa;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    -webkit-animation: ripple 0.7s linear infinite;
    animation: ripple 2s linear infinite;
    /* position: fixed; */ }

@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(12, 135, 250, 0.3), 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3); }
  100% {
    box-shadow: 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3), 0 0 0 8em rgba(12, 135, 250, 0); } }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(12, 135, 250, 0.3), 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3); }
  100% {
    box-shadow: 0 0 0 1em rgba(12, 135, 250, 0.3), 0 0 0 3em rgba(12, 135, 250, 0.3), 0 0 0 5em rgba(12, 135, 250, 0.3), 0 0 0 8em rgba(12, 135, 250, 0); } }

.matching-process-row .job-locations-container.review-booking-item-container {
  padding: 0;
  width: 100%; }
  .matching-process-row .job-locations-container.review-booking-item-container .review-booking-item-inner {
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border: 0;
    margin: 0; }
    .matching-process-row .job-locations-container.review-booking-item-container .review-booking-item-inner .icon-location {
      width: 16px;
      height: 16px; }

.job-matched-lottie {
  z-index: 101;
  position: relative; }
  .job-matched-lottie div {
    position: fixed;
    max-width: 412px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; }

.top-icon {
  display: flex;
  justify-content: center;
  align-self: center;
  padding-top: 30px; }

.job-matched-bottom-sheet {
  flex-direction: column;
  padding: 0 0 20px;
  background-color: #FAFBFC; }

.get-started-button .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
  width: 100%; }

.job-unfulfilled-buttons {
  margin: 16px 0;
  display: flex; }
  .job-unfulfilled-buttons .retry-button {
    flex-basis: 60%;
    text-align: center;
    color: #FAFBFC;
    background-color: #0C87FA;
    text-transform: none; }
    .job-unfulfilled-buttons .retry-button:focus, .job-unfulfilled-buttons .retry-button:active, .job-unfulfilled-buttons .retry-button:hover {
      color: #FAFBFC;
      background-color: #0C87FA; }
  .job-unfulfilled-buttons .cancel-button {
    flex-basis: 40%;
    color: #FC6A54;
    text-transform: none; }

.unfulfilled-schedule-booking-button {
  width: 100%; }
  .unfulfilled-schedule-booking-button .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    width: 100%; }
  .unfulfilled-schedule-booking-button p {
    margin: 0; }
  .unfulfilled-schedule-booking-button svg {
    width: 25px;
    height: 25px;
    margin-right: 5px; }

.job-matching .title-bar-container .title-bar-icon {
  display: flex; }
  .job-matching .title-bar-container .title-bar-icon .heading {
    padding: 0 8px 0 0; }
  .job-matching .title-bar-container .title-bar-icon .lozenge-container {
    align-items: center; }
    .job-matching .title-bar-container .title-bar-icon .lozenge-container .lozenge-inner {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }

.job-matching .matching-cta-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FAFBFC; }
  .job-matching .matching-cta-container h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin: 0 0 20px;
    color: #3B424A; }
  .job-matching .matching-cta-container .button-container {
    position: relative;
    padding: 0; }
    .job-matching .matching-cta-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%; }
    .job-matching .matching-cta-container .button-container.cancel-booking {
      margin-top: 10px; }
      .job-matching .matching-cta-container .button-container.cancel-booking .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background-color: transparent;
        color: #E34B34;
        border: 1px solid #E34B34; }

.rce-container-mbox {
  display: block;
  overflow: hidden;
  min-width: 200px; }
  .rce-container-mbox .timezone {
    display: flex;
    justify-content: center;
    color: #898E92;
    margin: 8px 0; }

.rce-mbox-forward {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  /*display: none;*/
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 #a4a4a4;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  margin: auto; }

.rce-mbox-forward-left {
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: -50px; }

.rce-mbox-forward-right {
  display: flex;
  opacity: 0;
  visibility: hidden;
  right: -50px; }

.rce-container-mbox:hover .rce-mbox-forward-left, .rce-container-mbox:hover .rce-mbox-forward-right {
  opacity: 1;
  visibility: visible; }

.text .rce-mbox {
  position: relative;
  background: #DBDBDB;
  border-radius: 8px 8px 8px 0px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 2px 0;
  float: left;
  min-width: 140px;
  max-width: 70%;
  color: #3B424A; }

.rce-mbox-text {
  font-size: 13px;
  word-break: break-word;
  /* font-weight: 600; */
  white-space: pre-line; }

.text.system .rce-mbox {
  position: relative;
  background: #DBDBDB;
  border-radius: 8px 8px 8px 0px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  float: left;
  min-width: 140px;
  max-width: 70%;
  color: #3B424A; }

.file .rce-mbox, .audio .rce-mbox {
  position: relative;
  background: #DBDBDB;
  border-radius: 8px 8px 8px 0px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 6px 9px 8px 9px;
  float: left;
  min-width: 140px;
  max-width: 60%; }

.photo .rce-mbox, .image .rce-mbox {
  position: relative;
  background: #FAFBFC;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 3px 3px 3px 3px;
  float: left;
  min-width: unset;
  max-width: 70%; }

.rce-mbox.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  -webkit-animation-name: message-box-default-focus;
          animation-name: message-box-default-focus;
  animation-duration: 1s; }

@-webkit-keyframes message-box-default-focus {
  from {
    background-color: #fff; }
  to {
    background-color: #dfdfdf; } }

.rce-mbox-body {
  margin: 0;
  padding: 8px;
  position: relative; }

.photo .rce-mbox-body, .image .rce-mbox-body {
  padding: 4px 7px 2px 7px; }

.text .rce-mbox.rce-mbox-right, .file .rce-mbox.rce-mbox-right, .audio .rce-mbox.rce-mbox-right {
  float: right;
  margin-left: 5px;
  margin-right: 20px;
  background-color: #0C87FA;
  color: #FAFBFC;
  border-radius: 8px 8px 0px 8px; }

.photo .rce-mbox.rce-mbox-right, .image .rce-mbox.rce-mbox-right {
  float: right;
  margin-left: 5px;
  margin-right: 20px;
  background-color: #FAFBFC;
  color: #FAFBFC; }

.rce-mbox.rce-mbox-right .rce-mbox-time {
  color: #FAFBFC; }

.rce-mbox.rce-mbox-mc {
  background-color: #F7E5CF !important;
  color: #3B424A; }
  .rce-mbox.rce-mbox-mc .rce-mbox-time {
    /* background-color: green; */
    color: #FAFBFC; }

.rce-mbox.rce-mbox-right.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  -webkit-animation-name: message-box-right-focus;
          animation-name: message-box-right-focus;
  animation-duration: 1s; }

@-webkit-keyframes message-box-right-focus {
  from {
    background-color: #d4f1fb; }
  to {
    background-color: #b8dae6; } }

.rce-mbox-text:after {
  /* content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0" */ }

.rce-mbox-time {
  text-align: right;
  font-size: 12px;
  position: absolute;
  right: 5px;
  bottom: 0; }

.text.system .rce-mbox-time {
  text-align: center;
  color: #949494;
  font-size: 12px;
  position: relative;
  /* right: 5px; */
  /* bottom: -5px; */ }

.rce-mbox-time.non-copiable:before {
  content: attr(data-text); }

.rce-mbox-time-block {
  /*position: relative;*/
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: 0px;
  margin-left: -6px;
  padding-top: 5px;
  padding-right: 3px;
  padding-bottom: 2px;
  /* background: linear-gradient(to top, rgba(0,0,0,0.33), transparent); */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff; }

.rce-mbox--clear-padding {
  padding-bottom: 3px; }

.rce-mbox.rce-mbox--clear-notch {
  border-radius: 5px 5px 5px 5px !important; }

.rce-mbox-right-notch {
  position: absolute;
  right: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  /* fill: $doctor; */
  -webkit-filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.2)); }
  .rce-mbox-right-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-right-notch-focus;
            animation-name: message-right-notch-focus;
    animation-duration: 1s; }

@-webkit-keyframes message-right-notch-focus {
  from {
    fill: #d4f1fb; }
  to {
    fill: #b8dae6; } }

.rce-mbox-left-notch {
  position: absolute;
  left: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: #FAFBFC; }
  .rce-mbox-left-notch.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: message-left-notch-focus;
            animation-name: message-left-notch-focus;
    animation-duration: 1s; }

@-webkit-keyframes message-left-notch-focus {
  from {
    fill: #fff; }
  to {
    fill: #dfdfdf; } }

.rce-mbox-title {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #4f81a1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .rce-mbox-title:hover {
    text-decoration: underline; }

.rce-mbox-title--clear {
  margin-bottom: 5px; }

.rce-mbox-status {
  margin-left: 3px;
  font-size: 12px; }
  .rce-mbox-status svg {
    vertical-align: text-bottom; }

.rce-mbox-title > .rce-avatar-container {
  margin-right: 5px; }

.tap-to-join {
  margin-bottom: 0;
  font-size: 12px;
  color: #0057AD;
  text-align: right; }

.edit-profile .title {
  color: #3B424A;
  padding: 20px 20px 0;
  margin: 0 0 16px; }

.profile-form {
  padding: 0 20px;
  background-color: #FAFBFC; }
  .profile-form .client-avatar-name {
    display: flex;
    align-items: center; }
    .profile-form .client-avatar-name .avatar-container {
      padding-right: 15px; }
    .profile-form .client-avatar-name .client-name-input {
      flex-basis: 100%;
      display: flex;
      flex-direction: column; }
  .profile-form .client-mobile-number {
    display: flex; }
    .profile-form .client-mobile-number .select-country {
      flex-basis: 25%;
      max-width: 25%;
      padding-right: 10px; }
      .profile-form .client-mobile-number .select-country .flag {
        display: flex;
        align-items: center; }
      .profile-form .client-mobile-number .select-country select {
        outline: none;
        -webkit-appearance: none;
        text-align: center;
        border: none;
        box-shadow: none; }
      .profile-form .client-mobile-number .select-country select[disabled] {
        background-color: transparent;
        padding: 0;
        opacity: .7; }
    .profile-form .client-mobile-number .mobile-number {
      flex-basis: 75%;
      max-width: 75%;
      padding-left: 10px; }
      .profile-form .client-mobile-number .mobile-number .form-control[disabled] {
        background-color: transparent;
        padding: 0;
        opacity: .7; }
  .profile-form .edit-profile-action {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px; }
    .profile-form .edit-profile-action .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      padding: 10px; }
    .profile-form .edit-profile-action button {
      width: 100%; }
    .profile-form .edit-profile-action .error-button .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      background-color: transparent;
      color: #0C87FA;
      margin-top: 8px; }

.About_My_Kuya {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .About_My_Kuya .A_M_K_Img_Box {
    width: 100%;
    display: flex;
    margin: 72px 0 0 0; }
    .About_My_Kuya .A_M_K_Img_Box img {
      width: 196px;
      height: 46px;
      margin: 0 0 0 16px; }

.About_My_Kuya_Rows {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  flex-wrap: wrap;
  margin: 16px 0; }
  .About_My_Kuya_Rows .A_M_K_R {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    margin: 8px 24px; }
    .About_My_Kuya_Rows .A_M_K_R .A_M_K_R_Text {
      display: flex;
      flex: 1 1 70%;
      width: 70%; }
    .About_My_Kuya_Rows .A_M_K_R .A_M_K_R_Link {
      display: flex;
      justify-content: flex-end; }

.About_My_Kuya_Description {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  margin: 0 16px; }
  .About_My_Kuya_Description h5 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #B1B3B7; }

.About_Kuya_Support {
  color: #0070E0; }

.topup-mykoin-bottom-sheet .Sheet_Content {
  width: 100%; }

.topup-mykoin-bottom-sheet .title-bar-container {
  border-radius: 20px 20px 0 0;
  top: 20px; }

.topup-mykoin-bottom-sheet .top-up-mykoin-container {
  padding: 85px 20px 20px;
  background-color: #FAFBFC;
  width: 100%;
  display: flex;
  flex-direction: column; }
  .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    border-bottom: 1px solid #DBDBDB; }
    .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form label {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #898E92;
      padding: 0 5px;
      margin-bottom: 0; }
    .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container {
      display: flex;
      align-items: center;
      padding: 0 5px; }
      .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container span {
        color: #898E92;
        margin-right: 3px; }
      .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container input {
        border: 0;
        padding: 0;
        font-size: 13px;
        line-height: 24px;
        width: 100%; }
        .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container input::-webkit-input-placeholder {
          color: #DBDBDB; }
        .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container input::-moz-placeholder {
          color: #DBDBDB; }
        .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container input:-ms-input-placeholder {
          color: #DBDBDB; }
        .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container input::-ms-input-placeholder {
          color: #DBDBDB; }
        .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container input::placeholder {
          color: #DBDBDB; }
        .topup-mykoin-bottom-sheet .top-up-mykoin-container .topup-form .input-container input:focus {
          outline: none;
          box-shadow: none; }
  .topup-mykoin-bottom-sheet .top-up-mykoin-container .default-amount-container {
    margin: 16px 0 24px;
    display: flex;
    justify-content: space-between; }
    .topup-mykoin-bottom-sheet .top-up-mykoin-container .default-amount-container .default-amount {
      flex-basis: 33.3333%;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 8px 12px rgba(24, 26, 30, 0.12);
      display: flex;
      align-items: center;
      margin-right: 16px; }
      .topup-mykoin-bottom-sheet .top-up-mykoin-container .default-amount-container .default-amount:last-child {
        margin-right: 0; }
      .topup-mykoin-bottom-sheet .top-up-mykoin-container .default-amount-container .default-amount p {
        color: #3B424A;
        margin-left: 12px;
        margin-bottom: 0; }
  .topup-mykoin-bottom-sheet .top-up-mykoin-container .button-container {
    width: 100%; }
    .topup-mykoin-bottom-sheet .top-up-mykoin-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      background: #0C87FA;
      border-radius: 5px;
      color: #FAFBFC;
      text-transform: none;
      width: 100%;
      letter-spacing: 0.25px;
      padding: 10px; }
      .topup-mykoin-bottom-sheet .top-up-mykoin-container .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled {
        background-color: #E2EEF8;
        color: #81B7E9; }
    .topup-mykoin-bottom-sheet .top-up-mykoin-container .button-container .MuiButtonBase-root .white-lock {
      margin-right: 8px; }
  .topup-mykoin-bottom-sheet .top-up-mykoin-container .error {
    color: #FC6A54; }

.Tutorial .slick-dots {
  bottom: 25px !important; }

.Tutorial .slide {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  width: 100%;
  height: 100vh !important;
  background-size: cover !important;
  position: relative; }

.Tutorial .slide .step-svg {
  /* width: 60%; */
  margin: auto auto 16px auto; }

.Tutorial .slick-dots li.slick-active button:before {
  color: #FF9012;
  font-size: 12px; }

.Tutorial .slick-dots li button:before {
  color: #FF9012;
  font-size: 12px; }

.Tutorial .text {
  text-align: center;
  color: white;
  font-weight: bold; }

.Tutorial .text .title {
  font-size: 24px;
  margin: 0 0 16px 0;
  font-weight: 900;
  font-family: 'Poppins'; }

.Tutorial .text .description {
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  margin: 0 50px 32px 50px; }

.Tutorial .next-tutorial {
  position: fixed;
  right: 5px;
  bottom: 24px;
  z-index: 10;
  outline: none;
  box-shadow: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 16px; }

.Tutorial .skip-tutorial {
  position: fixed;
  left: 5px;
  bottom: 24px;
  z-index: 10;
  outline: none;
  box-shadow: none;
  border: none;
  background: transparent;
  color: white;
  font-size: 16px; }

.Slide_Box_Img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 1 100%; }

.Text_Box {
  width: 100%;
  min-height: 100px; }

.chat-with-support-bottom-sheet .Sheet_Content {
  width: 100%;
  background-color: #FAFBFC;
  padding: 30px 20px 20px;
  border-radius: 20px 20px 0 0; }

.chat-with-support-bottom-sheet .img-container img {
  max-width: 100%;
  height: auto;
  display: block; }

.chat-with-support-bottom-sheet .header {
  color: #3B424A;
  text-align: center;
  margin: 24px 0 8px; }

.chat-with-support-bottom-sheet .content {
  color: #3B424A;
  text-align: center; }

.chat-with-support-bottom-sheet .code-content {
  color: #FC6A54; }

.chat-with-support-bottom-sheet .chat-with-support {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  margin-top: 16px;
  background-color: #0C87FA;
  color: #FAFBFC; }
  .chat-with-support-bottom-sheet .chat-with-support span {
    padding-left: 8px;
    color: #FAFBFC; }

.partner-not-joined-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #4c4c4c;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  z-index: -1; }

.partner-info p {
  color: #FAFBFC;
  text-align: center;
  z-index: 9;
  padding: 20px 0 0;
  font-size: 25px;
  font-weight: 500;
  margin: 0; }

.partner-info span {
  text-align: center;
  color: #898E92;
  display: block; }

.video-chat-container {
  background-color: #181A1E;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden; }
  .video-chat-container.partner-not-joined .client-video video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%; }
  .video-chat-container.partner-not-joined .partner-info {
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: center; }
    .video-chat-container.partner-not-joined .partner-info span {
      color: #DBDBDB; }
  .video-chat-container .client-video {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 100%;
    z-index: 1; }
    .video-chat-container .client-video.partner-joined video {
      display: flex;
      width: 35%;
      border-radius: 10px; }
  .video-chat-container .card-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 100%;
    padding: 20px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center; }
    .video-chat-container .card-bottom .left-call {
      margin: 0 15px; }
      .video-chat-container .card-bottom .left-call .left-call-icon {
        fill: #B01C05; }
    .video-chat-container .card-bottom .icon-container {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .video-chat-container .card-bottom .icon-container img {
        max-width: 100%;
        height: auto;
        display: block; }
  .video-chat-container .image-partner {
    position: absolute;
    left: 50%;
    top: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .video-chat-container .image-partner img {
      width: 150px;
      height: 150px;
      border-radius: 50%; }
    .video-chat-container .image-partner p {
      margin: 10px 0 0;
      color: #FAFBFC;
      text-align: center;
      font-weight: 600; }

.partner-video {
  margin: 0; }
  .partner-video video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%; }
  .partner-video .camera-off {
    filter: blur(8px);
    -webkit-filter: blur(8px); }

@media (min-aspect-ratio: 16 / 9) {
  .partner-video video {
    width: 100%;
    height: 100%; } }

.login-bottom-sheet-container {
  padding: 20px;
  background-color: #FAFBFC; }
  .login-bottom-sheet-container.check-code .enter-code-description {
    padding: 0; }
    .login-bottom-sheet-container.check-code .enter-code-description .back-icon {
      margin: 10px 0; }
  .login-bottom-sheet-container.check-code .enter-code-inputs {
    padding: 0; }
  .login-bottom-sheet-container.check-code .receive {
    padding: 20px 0; }
  .login-bottom-sheet-container .welcome-being-mykuya {
    position: relative;
    padding: 0;
    bottom: 0;
    margin-top: 50px; }
    .login-bottom-sheet-container .welcome-being-mykuya .text-info {
      color: #B1B3B7;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px; }

.jobs-tab-list {
  list-style: none;
  padding: 0;
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  margin-bottom: 24px;
  padding: 0 20px;
  position: absolute;
  top: 70px;
  -webkit-overflow-scrolling: touch; }
  .jobs-tab-list::-webkit-scrollbar {
    width: 0; }
  .jobs-tab-list .tab-item {
    color: #81B7E9;
    padding: 0 12px; }
    .jobs-tab-list .tab-item:first-child {
      padding-left: 0; }
    .jobs-tab-list .tab-item:last-child {
      padding-right: 24px; }
    .jobs-tab-list .tab-item.selected {
      color: #0057AD; }

.tab-panel-container {
  padding: 0 20px; }

.tabs-container {
  position: absolute;
  top: 110px;
  overflow-y: scroll;
  bottom: 0;
  right: 0;
  left: 0; }
  .tabs-container::-webkit-scrollbar {
    width: 0; }

.page-title {
  margin: 5px 0 24px;
  padding: 65px 20px 0;
  font-size: 19px;
  font-weight: 600; }

.title-bar-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #FAFBFC;
  max-width: 412px; }
  .title-bar-container p {
    margin: 0 auto;
    display: block;
    text-align: center;
    color: #3B424A; }

.Loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0b87f9;
    z-index: 10000000;
}

.Loading-screen-img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    width: 70%;
}

.loading-screen-index {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 100000;
}

.loading-screen-index .loading-screen-index-image {
    position: absolute;
    margin: 0 auto;
    top: 20%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 250px;
}
.loading-screen-index .splash-image {
    position: absolute;
    bottom: 0;
    width: 80%;
    left: 0;
}
.Not-found .Not-found-svg {
    text-align: center;
    margin-top: 90px;
}
.Not-found .Not-found-svg svg{
    width: 50%;
}
.Not-found .Not-found-oops{
    text-align: center;
    margin-top: 20px;
}
.Not-found .Not-found-wrong p{
    margin-bottom: 0px;
    text-align: center;
    color: #949494;
}
.Not-found .Not-found-button button{
    text-transform: none !important;
    outline: none;
    box-shadow: none;
    background-color: #0488FA !important;
    color: white;
    border: none;
    padding: 8px 50px;
    border-radius: 5px;
    /* font-family: 'lato_black'; */
    font-size: 14px;
}

.fixed-background {
    background-color: #f8f8f8;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -10;
}
.Rotate-phone-modal .Waitlist-mascot img{
    margin: auto;
    width: 130px;
}
.Rotate-phone-modal .Waitlist-mascot {
    margin-top: -90px;
}
.Rotate-phone-modal .Modal .modal-dialog {
    margin: 120px 190px;
}
.Rotate-phone-modal .Modal .modal-dialog .modal-content{
    border-radius: 15px;
    padding: 0 0 20px 0;
}
.Rotate-phone-modal .Modal .modal-dialog h4{
    margin-top: 10px;
    font-weight: bold;
    padding: 0 20px;
    /* font-family: 'lato_black'; */
    margin: 0;
}
.Rotate-phone-modal .Modal .modal-dialog p{
    color: #646464;
    margin: 0;
    text-align: center;
}
.Internet-connection .Modal {
    display: block;
    background-color: rgba(110, 110, 110, 0.55);
}
.Internet-connection .Modal .modal-dialog {
    margin: 200px 45px
}
.Internet-connection .Modal .modal-content {
    padding: 20px;
    border-radius: 15px;
}
.Internet-connection .Modal .modal-kuya-container {
    position: relative;
    text-align: center;
    margin: auto;
    margin-top: -70px;
}
.Internet-connection .Modal .modal-kuya-container .Card-error{
    width: 100px;
    max-height: 110px;
    margin: auto;
    margin-top: 0px;
}
.Internet-connection .Internet-connection-title p{
    font-size: 18px;
    margin: 0;
    /* font-family: 'lato_black'; */
}
.Internet-connection .message{
    padding: 0;
}
.Internet-connection .message p{
    margin: 0;
    color: #646464;
}
.Internet-connection .Internet-connection-button-container{
    padding: 0px 10px 10px 10px;
}
.Internet-connection .try-button{
    text-transform: none !important;
    background-color: #0488FA !important;
    color: white !important;
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 3px !important;
    padding: 6px !important;
    font-size: 14px !important;
    /* font-family: 'lato_black'; */
}
