.promo-code-bottom-sheet {
    background-color: $doctor;
    .title {
        padding: 20px 0;
        text-align: center;
        margin-top: 10px;
        h2 {
            color: $charcoal;
            @extend .subtitle1;
            margin: 0;
        }
    }
    .promo-code-container {
        padding: 0 24px;
        margin-bottom: 20px;
    }
    .promo-code-form {
        display: flex;
        flex-direction: column;
        label {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: $spanish-gray;
            margin-bottom: 0;
        }
        input {
            border: 0;
            padding: 0;
            font-size: 13px;
            line-height: 24px;
            width: 100%;
            border-radius: 0;
            &:placeholder {
                color: $grainsboro;
            }
            &::-moz-placeholder {
                color: $grainsboro;
            }
            &::-webkit-placeholder {
                color: $grainsboro;
            }
            &:placeholder, &:-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
                color: $grainsboro;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    .promo-code-button-container {
        width: 100%;
        display: flex;
        padding: 10px 24px 20px;
        .MuiButtonBase-root {
            &.MuiButton-root {
                &.MuiButton-text {
                    padding: 10px;
                    @extend .button;
                    background: $bleu-de-france;
                    border-radius: 5px;
                    color: $doctor;
                    text-transform: none;
                    width: 100%;
                    letter-spacing: 0.25px;
                }
                &.Mui-disabled {
                    background-color: $alice-blue;
                    color: $aero;
                }
            }
        }
        .cancel-button {
            flex-basis: 40%;
            max-width: 40%;
            &.MuiButtonBase-root {
                &.MuiButton-root {
                    &.MuiButton-text {
                        background-color: $doctor;
                        color: $bleu-de-france;
                    }
                }
            }
        }
        .apply-code-button {
            flex-basis: 60%;
            max-width: 60%;
        }
    }
}