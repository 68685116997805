.saved-places {
    .saved-places-others {
        margin: 40px 0;
        .saved-places-swipe-to-delete {
            &:last-child {
                border-bottom: 0;
            }
        }
        .saved-places-others-title {
            padding: 10px 15px;
            p {
                color: #676767;
                margin: 0;
            }
        }
        .saved-places-others-item {
            display: flex;
            align-items: center;
            background-color: $doctor;
            padding: 13px 15px;
            border-bottom: 1px solid $grainsboro;
            height: 64px;
            .divider {
                display: flex;
                width: 100%;
                img {
                    margin-right: 15px;
                }
            }
            .name-address {
                .name {
                    margin: 0;
                    font-size: 14px;
                    color: #000;
                }
            }
        }
    }
}