.account-links {
  display: flex;
  flex-direction: column;
  &.padding-top {
    padding-top: 65px;
  }
  .account-links-box {
    display: flex;
    justify-content: space-between;
    padding: 18px 20px;
  }
  .account-links-box-title {
    @extend .subtitle1;
    color: $charcoal;
  }
}