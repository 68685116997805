.open-job-info.request-placed {
    .matching-process-container {
        padding: 0;
        // padding: 40px 20px 100px 16px;
        .button-container {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 20px;
            button {
                width: 100%;
            }
        }
    }
    .matching-process {
        position: fixed;
        padding-top: 60px;
        max-width: 412px;
        height: 100vh;
        width: 100%;
        .matching-process-title {
            margin-bottom: 10px;
        }
        .open-job-info-button {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 20px 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: $doctor;
            .button-container {
                position: relative;
                padding: 0;
                .MuiButtonBase-root.MuiButton-root.MuiButton-text {
                    width: 100%;
                }
                &.cancel-booking {
                    margin-top: 10px;
                    border: 1px solid $cinnabar;
                    border-radius: 5px;
                    .MuiButtonBase-root.MuiButton-root.MuiButton-text {
                        background-color: transparent;
                        color: $cinnabar;
                    }
                }
            }
        }
    }
}