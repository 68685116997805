.matching-process {
    padding: 0 20px;

  .matching-process-title {
    padding: 24px 0;
    border-bottom: 1px solid $grainsboro;
    display: flex;
    align-items: center;
    .partner-icon {
        width: 48px;
        height: 48px;
        img {
            border-radius: 50%;
            max-width: 100%;
            width: 100%;
            display: block;
            height: 100%;
        }
    }
    .booking-button-icon {
        position: relative;
        .kuya-headshot {
            width: 38px;
            height: 38px;
        }
        .icon {
            position: absolute;
            top: -10px;
            right: -13px;
            width: 20px;
            height: 24px;
        }
    }
    .title {
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        p {
            @extend .h6;
            margin-bottom: 0;
            color: $charcoal;
        }
        .text-secondary {
            color: $spanish-gray;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.4px;
        }
    }
  }
  .matching-process-container {
      .matching-process-row {
        display: flex;
        align-items: center;
        padding-left: 4px;
        margin-bottom: 4px;
        .job-state {
            width: 16px;
            height: 16px;
        }
        &.payment {
            padding-left: 3px;
        }
        .billing-card-image.mc {
            width: 18px;
            height: 11px;
            + p {
                padding-left: 14px;
            }
        }
        .billing-card-image.visa {
            width: 20px;
            height: 7px;
            + p {
                padding-left: 13px;
            }
        }
        p {
            margin-bottom: 0;
            @extend .body2;
            color: $charcoal;
            padding-left: 16px;
            height: 20px;
            overflow: hidden;
            width: 100%;
        }
        .icon-card {
            &.mc {
                width: 16px;
                height: 10px;
            }
            &.visa {
                width: 16px;
                height: 6px;
            }
        }
        .mykoin-image {
            width: 13px;
            height: 13px;
            padding: 1px;
            + p {
                padding-left: 19px;
            }
        }
      }
  }
  .tip {
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: $spanish-gray;
      margin-bottom: 0;
  }
}
.request-placed {
    .title-bar-container {
        .title-bar-icon {
            display: flex;
            .heading {
                padding: 0 8px 0 0;
                @extend .subtitle1;
            }
            .lozenge-container{
                align-items: center;
                .lozenge-inner {
                    height: fit-content;
                    .lozenge-title {
                        p {
                            @extend .overline;
                        }
                    }
                }
            }
        }
    }
    .matching-process {
        .matching-process-container {
            padding: 40px 20px 100px 16px;
            .button-container {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                padding: 20px;
                background-color: $doctor;
                button {
                    width: 100%;
                }
            }
        }
    }
}
.request-placed-image {
    position: relative;
    padding-top: 65px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

}
@media(max-width: 400px) {
    .request-placed .matching-process .matching-process-container {
        padding: 0;
    }
    .request-placed {
        .request-placed-image {
            display: none;
        }
        .matching-process {
            padding-top: 60px;
            .matching-process-title {
                margin-bottom: 15px;
            }
        }
    }
}