.location-search-box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 15px 8px;
    border-bottom: 1px solid $grainsboro;
    width: 100%;
    background-color: $doctor;
    .location-search-box-inner {
        display: flex;
        align-items: center;
        padding-left: 12px;
        width: 100%;
        justify-content: space-between;
        input {
            border: 0;
            width: 100%;
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            @extend .link;
            cursor: pointer;
            font-weight: normal;
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: $spanish-gray;
                font-weight: 400;
            }
            &::-moz-placeholder,
            &::-ms-placeholder,
            &::-webkit-placeholder,
            &::-o-placeholder {
                color: $spanish-gray;
                font-weight: 400;
            }
        }
        .location-search-box-delete {
            padding-left: 5px;
        }
    }
    .location-search-box-input{
        width: 100%;
        @extend .caption;
        color: $charcoal;
        padding: 0;
        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::-moz-placeholder {
            color: $charcoal;
            @extend .caption;
        }
    }
}
.pin-icon-wrapper {
    position: relative;
    display: flex;
    .number-destination {
        @extend .overline;
        color: $silver-chalice;
        position: absolute;
        bottom: -2px;
        right: 0;
    }
}