.Job_Chat_History_Menu_Box {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  margin: 215px 16px 0;
  .J_C_H_M_B_Row {
    padding: 16px;
    display: flex;
    width: 100%;
    border-top: 1px solid $grainsboro;
    &:first-child {
      border-top: 0;
    }
  }
  .J_C_H_M_B_Row_Icon {
    .J_C_H_B_Row_Icon_Img {
      width: 24px;
      height: 24px;
    }
  }
  .J_C_H_M_B_Row_Text {
    h5 {
      padding-left: 16px;
      color: $charcoal;
      margin: 0;
      @extend .subtitle1;
    }
  }
}

.Cancel_Btn_Box {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  .Cancel_Job_Button {
    text-transform: none !important;
    background-color: $doctor;
    color: $bittersweet;
    border: 1px solid $bittersweet;
    margin: 32px 0;
    outline: none;
    box-shadow: none;
    width: 100%;
    border-radius: 5px !important;
    padding: 8px;
    @extend .button;
    &:disabled {
      background-color: #C5C5C5 !important;
    }
  }
}
.chat-menu-title-bar {
  .title-bar-container {
    .title-bar-icon {
      display: flex;
      .heading {
        padding: 0 8px 0 32px;
        @extend .subtitle1;
      }
      .lozenge-container{
        align-items: center;
        .lozenge-inner {
          height: fit-content;
          .lozenge-title {
            p {
                @extend .overline;
                text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}