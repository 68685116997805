.Job_Rating {
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #0070E0;
  z-index: 1000;
  .Job_Rating_Parent{
    position: relative;
    height: auto;
    display: flex;
    flex-basis: 100%;
  }
  .White_Box{
    display: flex;
    height: 90%;
    background: $doctor;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    &.not-show-top-image {
      height: 85%;
      + .Blue_Box {
        height: 15%;
      }
    }
  }

  .Job_Rating_Box{
    display: flex;
    flex-direction: column;
    flex: 1;
    .title-bar-container {
        .title-bar-icon {
            display: flex;
            .back-button {
              display: none;
            }
            .heading {
                padding: 0 8px 0 0;
                @extend .subtitle1;
            }
            .lozenge-container{
                align-items: center;
                .lozenge-inner {
                    height: fit-content;
                    .lozenge-title {
                        p {
                            @extend .overline;
                        }
                    }
                }
            }
        }
    }
    .Job_Rating_Chat_Section{
      height: auto;
    }
    .Job_Rating_Hand_Shake{
      padding: 65px 20px 0;
      background-color: $doctor;
      .Job_Rating_S_1_Img {
        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }
    }
    .Job_Rating_Partner {
      padding:  20px;
      background-color: $doctor;
    }
    .Job_Rating_Thumb{
      display: flex;
      flex-basis: 100%;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      margin: auto;
      padding: 0 20px;
      border-radius: 8px;
      .Thumb_Box{
        box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12);
        flex-basis: 50%;
        height: 56px;
        border-radius: 8px;
        background-color: $doctor;
        &:nth-child(1){
          margin: 0 8px 0 0;
        }
        &:nth-child(2){
          margin: 0 0 0 8px;

        }
      }
    }
  }
}
.Job_Rating .modal-kuya-container {
  width: 120px;
  height: 120px;
  background-color: transparent;
  /* padding-top: 19px; */
  border-radius: 50%;
  border: 3px solid white;
  position: relative;
  text-align: center;
  margin: auto;
}
.Job_Rating .modal-kuya-container img{
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 50%;
  /* margin-top: -5px; */
}
.Job_Rating .Job_Rating-section .Chat-support-icon{
  width: 20px;
  float: right;
}
.Job_Rating .Job_Rating-completed{
  text-align: center;
  margin-bottom: 5px;
}
.Job_Rating .Job_Rating-completed h3{
  font-size: 18px;
  font-weight: 900;
  margin-top: 15px;
  margin-bottom: 5px;
  color: #000;
}
.Job_Rating .Job_Rating-completed p{
  margin: 0;
  color: #949494;
}
.Job_Rating .job-rating-info-container .job-rating-info-inner {
  padding: 10px 5px;
  border-bottom: 1px solid #EDECEC;
  display: flex;
  justify-content: space-between;
}
.Job_Rating .job-rating-info-container .job-rating-info-inner.no-border-bottom {
  border-bottom: 0;
}
.Job_Rating .job-rating-info-container .job-rating-info-inner.border-top {
  border-top: 1px solid #EDECEC;
}
.Job_Rating .Job_Rating-completed p{
  margin: 0;
  color: #949494;
}
.Job_Rating hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
.Job_Rating .Job_Rating-name .Job_Rating-partner-name {
  color: #949494;
  font-size: 18px;
  margin: 0;
}
.Job_Rating .Job_Rating-name .Job-co-partner {
  justify-content: center;
  color: #949494;
  font-size: 14px;
}
.Job_Rating .text-title {
  color: #949494;
}
.Job_Rating .text-display {
  color: black;
  font-weight: bold;
}
.Job_Rating .text-display p{
  color: black;
  font-weight: bold;
  display: inline-block;
  float: right;
  margin: 0;
}
.Job_Rating .payment-method-img {
  display: inline-block;
  max-width: 30px;
  margin-top: -1px;
  max-height: 22px;
}
.Job_Rating .text-display.duration {
  font-family: 'digital';
  font-size: 16px;
}
