.enter-code-description {
    padding: 65px 20px 0;
    margin-bottom: 24px;
    p {
        margin-bottom: 0;
    }
}
.enter-code-inputs {
    padding: 0 20px;
    .signup-input {
        background-color: $doctor;
        border-radius: 8px;
        border: 1px solid transparent;
        margin-right: 8px;
        width: 40px !important;
        height: 50px;
        filter: drop-shadow(0px 2px 4px rgba(24, 26, 30, 0.15));
        @extend .h6;
        caret-color: $bleu-de-france;
        &.focused {
            outline: none;
            border-color: $bleu-de-france;
        }
        @media (min-width: 400px) {
            width: 48px !important;
            height: 58px;
        }
    }
    .signup-input-conntainer {
        &.error {
            .signup-input {
                border-color: $cinnabar;
            }
        }
    }
}
.check-code {
    .error {
        color: $cinnabar;
        @extend .overline;
        margin-bottom: 0;
    }
    .phone-number {
        font-weight: bold;
        font-size: 17px;
    }
    .receive {
        padding: 20px;
        .didnt-receive {
            @extend .caption;
            color: $spanish-gray;
            margin: 0;
            font-size: 12px;
            line-height: 18px;
        }
        .receive-new-text {
            @extend .caption;
            color: $charcoal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
        }
        .receive-new {
            background-color: transparent;
            border: none;
            padding: 0;
            color: $bleu-de-france;
            @extend .caption;
            font-size: 12px;
            line-height: 18px;
            font-weight: bold;
        }
    }
}
