.Home_Search_Input_Box{
  flex: 1 1 100%;
  height: 90px;
  display: flex;
  background: #0057AD;
  .Search_Box{
    flex: 1 1 100%;
    background: url('../../../../assests/images/home/ServiceCollectionBG.svg') #0057AD -29px -66px no-repeat;
    .Search_Box_Form{
      position: relative;
      margin: 25px 24px;
      .Dynamic_Parent_Icon{
        width: 30px;
        height: 30px;
        position: absolute;
        top: 7px;
        left: 4px;
      }
      .form-control{
        border-radius: 8px !important;
        padding: 0 24px !important;
      }
      .Cancel_Img{
        position: absolute;
        top: 5px;
        right: 10px;
        width: 24px;
        height: 24px;
        display: none;
      }
      .Show{
      display: block;
    }
    }
  }
  .Search_Box_Focused{
    flex: 1 1 80% !important;
  }
  .Button_Box{
    flex: 1 1 20%;
    background: url('../../../../assests/images/home/ServiceCollectionBG.svg') #0157AD -323px -345px no-repeat;

    display: none;
    .H_S_I_B_Cancel_Btn{
      text-transform: none !important;
      width: 100%;
      outline: none;
      box-shadow: none;
      font-weight: 600;
      color: white;
      border: none;
      border-radius: 3px;
      padding: 5px !important;
      font-size: 12px;
      margin: 18px -16px;
      font-family: 'Poppins';
    }
  }
  .Button_Box_Focused{
    display: flex !important;
  }
}
.Search_Placeholder{
  font-size: 11px !important;
  height: 40px !important;
}