.job-receipt-box{
  background: #0070E0;
  height: auto;
  flex-basis: 100%;
  overflow: auto;
  &.Has_Chat_Bar{
    padding: 65px 0 0 0;
  }
}
.job-receipt{
  height: auto;
  min-height: 300px;
  margin: 24px 16px;
  .first-section{
    height: auto;
    border-radius: 8px;
    background: #FAFBFC;
    border-bottom: 2px dashed #0070E0;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    .job-receipt-header {
      display: flex;
      align-items: center;
    }
  }
  .second-section , 
  .third-section,
  .job-detail-section {
    background: #FAFBFC;
    position: relative;
    border-radius: 8px;
    margin-top: -1px;
  }
  .job-detail-section {
    border-bottom: 2px dashed #0070E0;
  }
  .second-section{
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-bottom: 2px dashed #0070E0;
    h5 {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.4px;
      color: $bleu-de-france;
      margin: 0;
    }
  }
  .job-receipt-logo{
    width: 29%;
    display: block;
    float: left;
    position: relative;
    .job-my-kuya-logo{
      width: 100px;
      height: 30px;
      margin: 16px;
    }
    .detail-toggle{
      position: absolute;
    }
    img{
      width: 76px;
      height: 22px;
      margin: 24px 20px 0 0px;
    }
  }
  .credit-logo{
    width: 18% !important;
  }
  .master-card-number{
    width: 70%;
    display: block;
    float: left;
    overflow: auto;
    .card-number{
      margin: 30px 0 0 0;
    }
    .detail-toggle{
      position: absolute;
      left: 40px;
      color: #0C87FA;
      font-weight: 600;
    }
  }
  .job-receipt-date{
    width: 70%;
    display: block;
    float: left;
    overflow: auto;
    h5{
      margin: 16px;
      text-align: right;
      font-size: 12px;
    }
  }

}

.request-again-box{
  flex: 1;
  margin: 24px;
  .request-again{
    flex: 1;
    flex-shrink: 1;
    width: 100%;
    .button-container{
      width: 100%;
    }
  }

}
.talk-link-box{
  flex: 1;
  flex-shrink: 1;
  width: 100%;
  h3{
    text-align: center;
    color: #0C87FA;
    font-weight: 600;
    line-height: 27px;
  }
}

.Job_Detail_Title {
  margin: 0;
  padding: 16px;
  font-weight: bold;
  font-size: 19px;
}
.Job_Receipt_Help_Btn {
  background: $doctor !important;
  color: $bleu-de-france !important;
  border: 1px solid $bleu-de-france !important;
  outline: none;
  box-shadow: none;
  width: 100%;
  border-radius: 5px !important;
  padding: 6px !important;
  margin: 8px 0 !important;
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px;
  .chat_Support_Receipt{
    margin: 0 4px;
  }
  &:disabled {
    background-color: #C5C5C5 !important;
  }
}
.hide{
  display: none;
}
.job-receipt-home {
  .title-bar-container {
    border-radius: 0 0  20px 20px;
    .title-bar-icon {
        display: flex;
        .heading {
            padding: 0 8px 0 16px;
            @extend .subtitle1;
        }
        .lozenge-container{
            align-items: center;
            .lozenge-inner {
                height: fit-content;
                .lozenge-title {
                    p {
                        @extend .overline;
                    }
                }
            }
        }
    }
  }
}