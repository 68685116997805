.Chat_Note_To_Partner{
  background-color: $doctor;
  padding-top: 10px;
  .Sheet_Content{
    flex: 1 1 100%;
    width: 100%;
  }
  .job-note-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    p {
      @extend .caption;
      margin-bottom: 24px;
    }
    .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%;
    }
  }
}
.Job_Chat_History_Note_To_Partner{
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  width: 100%;
  .J_C_H_N_T_P_Title{
    flex: 1 1 100%;
    h5{
      flex: 1 1 100%;
      text-align: center;
      margin: 16px 0;
    }

  }
  .Note_To_Partner_Text{
    flex: 1 1 100%;
    margin: 16px 24px;
  }
  .Note_To_Partner_Button{
    display: flex;
    width: 100%;
    flex: 1 1 100%;
    flex-wrap: wrap;
    .N_T_P_B_Box{
      width: 15%;
      height: 56px;
      margin: 8px 24px;
      border-radius: 8px;
      box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12);
    }
    .Camera_Icon{
      margin: 16px -12px;
    }

  }
  .J_C_H_N_T_P_Btn_Box{
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;
    width: 100%;
    .J_C_H_N_T_P_B_B_Right{
      flex: 1 1 70%;
      width: 70%;
      padding: 8px 24px;
      .Save_Changes {
        text-transform: none !important;
        background-color: #0488FA !important;
        color: white !important;
        outline: none;
        box-shadow: none;
        border: none;
        width: 100%;
        border-radius: 3px !important;
        padding: 4px !important;
        font-size: 18px !important;
        font-weight: 600;
        &:disabled {
          background-color: #C5C5C5 !important;
        }
      }
    }
    .J_C_H_N_T_P_B_B_Left{
      flex: 1 1 30%;
      width: 30%;
      .Discard {
        text-transform: none !important;
        background-color: #fff !important;
        color: #FC6A54 !important;
        outline: none;
        box-shadow: none;
        border: none;
        width: 100%;
        border-radius: 3px !important;
        padding: 4px !important;
        font-size: 18px !important;
        margin: 8px 0;
        font-weight: 600;

        &:disabled {
          background-color: #C5C5C5 !important;
        }
      }
    }


  }

}