.Job_Chat_History_Header_Top {
  background-color: $doctor;
  margin-bottom: 16px;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 100;
  justify-content: space-between;
  .Job_Chat_History_Header_Back {
    flex-basis: 20%;
    .Close_Job_History {
      width: 24px;
      height: 24px;
      margin: 16px;
    }
  }
  .Job_Chat_History_Header_Middle {
    flex-basis: 90%;
    .Title {
      width: 55%;
      float: left;
      h5 {
        text-align: left;
        font-size: 15px;
      }
    }
    .State {
      width: 45%;
      float: left;
    }
    .Matched_State {
      // width: 78px;
      // border-radius: 4px;
      // text-align: center;
      // background-color: #ED7E00;
      // color: #fff;
      // height: 18px;
      // font-size: 10px;
      // overflow: auto;
      // margin: 10px 0;
      width: fit-content;
      @extend .overline;
      border-radius: 4px;
      text-align: center;
      background-color: $tangerine;
      color: $doctor;
      margin: 10px 0;
      padding: 0 2px;
      h5 {
        font-size: 12px;
        margin: 2px;
      }
    }
  }

  .Job_Chat_History_Header_Option {
    flex-basis: 10%;
    .kebab-menu {
      margin: 16px 20px 16px 0px;
      width: 24px;
      height: 24px;
    }
  }
  .Option-icon {
    width: 5px;
    margin-left: 5px;
  }
}

.Job_Chat_History_Header_Down {
  background-color: #f4f4f4;
  text-align: center;
  display: flex;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  padding: 0px 24px 16px;
  margin-top: 63px;
  .J_C_H_H_D_Profile {
    display: flex;
    align-items: center;

    .J_C_H_H_D_Profile_Img_Box {
      .Avatar {
        width: 48px;
        height: 48px;
      }
    }
    .J_C_H_H_D_Profile_Text_Box {
      padding: 0 16px;
      .Job_Chat_History_Header_Name {
        @extend .subtitle1;
        color: $charcoal;
        margin: 0;
        font-weight: 600;
        text-align: left;
      }
    }

  }
  .J_C_H_H_D_Call {
    
    .J_C_H_H_D_C_Circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $mint;
      display: flex;
      align-items: center;
      justify-content: center;
      .Call_Partner_Icon {
        width: 16px;
        height: 16px;
        margin: 12px;
      }
    }
  }

}
.chat-header-container {
  .chat-menu-title-bar {
    .title-bar-container {
      background-color: #f4f4f4;
      z-index: 2;
      padding: 20px 16px;
    }
  }
}
.call-container {
  display: flex;
  .video-chat-icon-container {
    width: 40px;
    height: 40px;
    background-color: $mint;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    svg {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }
}
.phone-voice-call-container {
  .call-drop-down {
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    right: 20px;
    width: 50%;
    text-align: left;
    p {
      margin: 0;
      padding: 10px 20px;
      &:first-child {
        border-bottom: 1px solid $grainsboro;
      }
    }
  }
}