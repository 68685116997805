.booking-service {
  padding: 65px 20px 0;
  display: flex;
  align-items: center;

  .booking-service-wrapper {
    flex-basis: 100%;
    display: flex;
    align-items: center;

    .booking-service-product-image img {
      width: 48px;
      height: 48px;
    }

    .booking-service-product {
      flex-basis: 80%;
      max-width: 80%;
      padding: 0 16px;
    }
  }

  .booking-service-product {
    p {
      margin: 0;
    }

    .booking-service-name {
      @extend .h6;
      color: $charcoal;
      margin-bottom: 0;
    }

    .booking-service-description {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $spanish-gray;
    }
  }

  .info-icon {
    position: absolute;
    right: 20px;
  }
}
.prgress-bar {
  padding: 0 20px;
}