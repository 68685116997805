.Home_Search_Location {
  flex: 1 1 100%;
  height: 56px;
  background: $doctor;
  display: flex;
  align-items: center;
  margin: 0 16px;
  .H_S_L_Left_Arrow {
    width: auto;
    margin: 0 -16px 0 0;
    .Left_Arrow {
      width: 10px;
      height: 10px;
      margin: 15px 8px;
    }
  }
  .H_S_L_Pin {
    width: auto;
    margin: 13px 5px 12px 0;
  }
  .H_S_L_Text {
    width: auto;
    margin: 0 4px 0 0;
    h5 {
      margin: 13px 0 0 0;
      font-size: 13px;
    }
  }
  .H_S_L_Arrow_Location {
    width: auto;
    margin: 0 8px 0 0;
    .Dynamic_Parent_Icon {
      margin: 18px 8px;
    }
  }
}

.Center_Search_Service {
  min-width: 80%;
  display: flex;
  margin: 0 auto;
  align-items: stretch;
}

.Home_SearchLocation_Pin {
  width: 16px;
  height: 16px;
  margin: -1px;
}

.Right_Arrow_Search_Location {
  margin: -5px;
  width: 23px;
  height: 13px;
}
