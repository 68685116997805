.Whats_New {
  padding: 0 0 0 20px;
  margin: 0 0 150px 0;
  .Whats_New_Scroll_Mobile{
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .Whats_New_Scroll_Desktop{
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .Whats_New_Title{
    flex: 1 1 100%;
    width: 100%;
    
    h1{
      @extend .h6;
      color: $charcoal;
      margin: 12px 0;
    }
  }
  .Whats_New_Slider_Box_Mobile , .Whats_New_Slider_Box_Desktop{
    background-color: $doctor;
    display: inline-block;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 24px 16px 0;
    @extend .big-black-shadow;
    .Whats_New_Slider_Container {
      text-align: center;
      img{
        max-height: 135px;
        max-width: 100%;
        width: 100%;
        height: 145px;
      }
      .Whats_New_More{
        padding: 10px;
        text-align: left;
        p{
          margin: 0;
        }
        .Whats_New_More_Title{
          margin-bottom: 5px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .Whats_New_More_Link{
          padding: 8px 0px 0px 0px;
          border-top: 1px solid #E6EBEF;
          margin-top: 10px;
          font-weight: bold;
          color: #0C87FA !important;
          text-align: center;
          a{
            color: #0C87FA !important;
          }
        }
      }
    }
  }
}
.Whats_New_Slider_Box_Mobile{
  flex: 1 1 80%;
  width: 80%;
}
.Whats_New_Slider_Box_Desktop{
  flex: 1 1 65%;
  width: 65%;
}
.News_MarginBottom{
  margin-bottom: 40px;
}
.Whats_new_scroll::-webkit-scrollbar {
  width: 0
}
.Time_Read{
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  height: 16px;
  .Time_Read_Icon{
    width: 5%;
  }
  .Time_Read_Text{
    flex: 1 1 85%;
    width: 15%;
    h5{
      margin: 2px !important;
      text-align: left;
      font-size: 12px;
    }
  }
}
.News_Link{
  width: 100%;
  text-align: right;
  color: #0C87FA;
  margin: 0;
  padding: 0 24px 0 0;
  font-size: 12px;
  font-weight: bold;

}