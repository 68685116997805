.queued-jobs-container {
    background-color: $doctor;
    .title {
        text-align: center;
        margin: 0;
        padding: 30px 20px 0px;
        margin-bottom: 0;
        color: $charcoal;
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
    }
    .matching-process-container {
      padding: 10px 20px 10px 16px;
      .matching-process-row {
        display: flex;
        align-items: center;
        padding-left: 4px;
        margin-bottom: 4px;
        &.payment {
            padding-left: 3px;
        }
        .billing-card-image.mc {
            width: 18px;
            height: 11px;
            + p {
                padding-left: 14px;
            }
        }
        .billing-card-image.visa {
            width: 20px;
            height: 7px;
            + p {
                padding-left: 13px;
            }
        }
        p {
            margin-bottom: 0;
            @extend .body2;
            color: $charcoal;
            padding-left: 16px;
            height: 20px;
            overflow: hidden;
            width: 100%;
        }
        .icon-card {
            &.mc {
                width: 16px;
                height: 10px;
            }
            &.visa {
                width: 16px;
                height: 6px;
            }
        }
        .mykoin-image {
            width: 13px;
            height: 13px;
            padding: 1px;
            + p {
                padding-left: 19px;
            }
        }
      }
  }
  .tip {
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: $spanish-gray;
      margin-bottom: 0;
      padding: 20px;
  }
  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    button {
        width: 100%;
    }
  }
  .queued-options-list {
      padding: 0 20px;
      .queued-option {
          &.disabled-option {
              label {
                  background-color: $grainsboro;
                  border-radius: 5px;
                  cursor: not-allowed;
              }
          }
      }
  }
  ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
    overflow: auto;

    li {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 16px;
      @media (min-width: 400px) {
        margin-bottom: 24px;
      }
      input[type=radio] {
        position: absolute;
        visibility: hidden;
      }

      .check {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;

        label {
          display: flex;
          position: relative;
          z-index: 9;
          cursor: pointer;
          transition: all 0.25s linear;
          margin-bottom: 0;
          padding: 10px 16px 10px 10px;
          font-weight: normal;
          align-items: center;
          .content {
              padding-left: 5px;
          }
          p {
              @extend .subtitle2;
              color: $charcoal;
              margin-bottom: 0;
          }
          span {
              @extend .caption;
              color: $spanish-gray;
          }
        }

        &::before {
          display: block;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 8px;
          margin: auto;
          transition: background 0.25s linear;
          -webkit-transition: background 0.25s linear;
          background-color: $doctor;
          border-radius: 8px;
          @extend .big-black-shadow;
        }
      }
    }
  }

  input[type=radio]:checked ~ .check {
    &::before {
      background: $sapphire;
      box-shadow: none;
    }
    label {
        p, span {
            color: $doctor;
        }
    }
  }
}