.location-search-map {
    &.set-first-location  {
        .location-search-map-icon {
            position: fixed;
        }
    }
    .location-search-map-icon {
        z-index: 1;
        // position: absolute;
        // left: 46%;
        // top: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        left: 50%;
        top: 51%;
        transform: translate(-50%, -50%);
        svg {
            &.Pin-icon {
                width: 40px;
                height: 40px;
                transition: all 0.8s ease 0s;
            }
            &.Ellipse-icon {
                // position: fixed;
                left: 49%;
                top: 49%;
                z-index: 10;
                width: 8px;
                fill: #4c4c4c70;
            }
        }
        &.dragged {
            svg {
                &.Pin-icon {
                    transition: all 0.8s ease 0s;
                    top: 42%;
                }
            }
        }
    }
    .location-search-map-current {
        position: absolute;
        right: 0;
        bottom: 70px;
        z-index: 10;
        cursor: pointer;
        padding: 0 15px;
        .Current-icon{
            width: 35px;
            margin: auto;
        }
    }
    .location-search-map-confirm {
        background-color: transparent;
        button {
            width: 100%;
        }
    }
}
.bottom-sheet-info {
    background-color: $doctor;
    padding: 30px 20px;
    .button-container {
        width: 100%;
        justify-content: center;
        button {
            width: 100%;
        }
    }
    p {
        text-align: center;
        margin-bottom: 30px;
        font-size: 13px;
        color: $charcoal;
    }
    h2 {
        font-size: 18px;
        margin-top: 0;
        text-align: center;
    }
}
