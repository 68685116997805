.About_My_Kuya{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .A_M_K_Img_Box{
    width: 100%;
    display: flex;
    margin: 72px 0 0 0;
    img{
      width: 196px;
      height: 46px;
      margin: 0 0 0 16px;
    }
  }
}
.About_My_Kuya_Rows{
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  flex-wrap: wrap;
  margin: 16px 0;
  .A_M_K_R{
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    margin: 8px 24px;
    .A_M_K_R_Text{
      display: flex;
      flex: 1 1 70%;
      width: 70%;
    }
    .A_M_K_R_Link{
      display: flex;
      justify-content: flex-end;
    }
  }
}
.About_My_Kuya_Description{
  display: flex;
  flex-wrap: wrap;
  flex : 1 1 100%;
  margin: 0 16px;
  h5{
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #B1B3B7;
  }
}
.About_Kuya_Support{
  color: #0070E0;
}