.Home_Empty_Box{
  display: flex;
  flex: 1 1 100%;
  height: auto;
  flex-wrap: wrap;
  margin: 150px  0 0 0;
  .Dynamic_Parent_Icon{
    flex: 1 1 100%;
    width: 100%;
    height: 100%;
  }
}
.Empty{
  margin: 0 !important;
}
.Search_Empty_Product{
  margin: 24px auto 16px auto;
}
.Not_Found_Title{
  margin: 24px 0 16px 0;
  text-align: center;
  font-weight: 600;
  flex: 1;
  font-size: 19px;
  color: #3B424A;
}
.Empty_Search_Text_Home{
  text-align: center;
  margin: 0 24px;
  font-size: 12px;
  width: 100%;
  color: #3B424A;
  .Get_Help_Strong{
    color: #0070E0;
    font-weight: bold;
  }
}
.Show_Search_Background , .Hide_Search_Background{
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: column;
}
@media screen and (device-aspect-ratio: 40/71) {
 .Hide_Search_Background{
   display: none !important;
 }
}
@media  only screen and (max-width: 320px)  {
  .Show_Search_Background{
    display: none !important;
  }
}
