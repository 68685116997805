.Job-chat-history .message-list{
  background-color: $doctor;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  transition: background .3s ease-out .1s;
  width: 100%;
  z-index: 100;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.Job-chat-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
}
.Job-chat-history .extra {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.job-chat-history-body {
  flex: 1 1 0;
  order: 2;
  z-index: 1;
}
