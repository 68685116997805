.billing-add-card {
  form {
    input {
      border-bottom: 1px solid #949494;
      box-shadow: none;
      outline: none;
      border-top: none;
      border-left: none;
      border-right: none;
      width: 100%;
      caret-color: #0488FA;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #DCDCDC;
        opacity: 1;

        /* Firefox */
        /* font-family: 'lato_regular'; */
        font-size: 16px;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #DCDCDC;

        /* font-family: 'lato_regular'; */
        font-size: 16px;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #DCDCDC;

        /* font-family: 'lato_regular'; */
        font-size: 16px;
      }
    }

    label {
      margin-bottom: 25px !important;

      /* font-family: 'lato_black'; */
    }

    input {
      &.invalid {
        border-bottom: 1px solid red;
      }

      &.valid {
        /* border-bottom: 1px solid green; */
      }
    }

    .credit-card {
      width: 20px;
    }
  }

  .billing-add-card-pay {
    position: fixed;
    bottom: 15px;
    right: 0;
    left: 0;
    z-index: -10000;
  }

  .billing-add-card-field {
    background-color: white;
    z-index: 100;

    .form-group {
      background-color: white;
    }
  }

  .billing-add-card-pay {
    button[type=submit] {
      text-transform: none !important;
      width: 100%;
      outline: none;
      box-shadow: none;
      background-color: #0488FA !important;
      color: white !important;
      border: none;
      padding: 10px !important;
      border-radius: 5px !important;

      /* font-family: 'lato_black'; */
      font-size: 16px !important;
    }

    .billing-methods-picture img {
      max-width: 50px;
      max-height: 60px;
      display: inline-block;

      &.pci {
        max-height: 62px;
        max-width: 70px;
        position: absolute;
        left: 0;
        bottom: 10px;
      }

      &.payment-secured {
        max-height: 30px;
        margin-left: 5px;
        position: absolute;
        left: 68px;
        bottom: 10px;
      }

      &.mastercard {
        max-width: 50px;
        max-height: 60px;
        position: absolute;
        right: 0;
        bottom: 4px;
      }

      &.visa {
        max-width: 50px;
        max-height: 60px;
        position: absolute;
        right: 50px;
        bottom: 0px;
      }
    }

    margin-bottom: 5px;
  }
}
.add-new-card {
    padding: 65px 20px;
    .tip {
        @extend .caption;
        color: $spanish-gray;
        margin-bottom: 0;
    }
    .title {
        @extend .h6;
        color: $charcoal;
        margin-bottom: 16px;
    }
    .adyen-checkout__input {
      border-bottom: 1px solid $grainsboro;
      border-right: 0;
      border-top: 0;
      border-left: 0;
      border-radius: 0;
      background: transparent;
    }
    .adyen-checkout__fieldset__title {
      @extend .h6;
      color: $charcoal;
      margin-bottom: 16px;
    }
    .adyen-checkout__fieldset--billingAddress {
      margin-top: 40px;
    }
    .adyen-checkout__input--focus, 
    .adyen-checkout__input--focus:hover, 
    .adyen-checkout__input:active, 
    .adyen-checkout__input:active:hover, 
    .adyen-checkout__input:focus, 
    .adyen-checkout__input:focus:hover {
      border-radius: 8px;
      @extend .subtle-black-shadow;
      border: 1px solid $bleu-de-france;
    }
    .adyen-checkout__label {
      margin-bottom: 24px;
    }
    .adyen-checkout__label--focused {
        .adyen-checkout__label__text {
            color: $bleu-de-france;
        }
    }
    .adyen-checkout__button {
        @extend .button;
        background-color: $bleu-de-france;
    }
}