.Wrap_Job_Information{
  width: 100%;
  height: auto;
  background: $doctor;
  
  .title-bar-container {
    position: relative;
  }
}
.Job_Information{
  flex: 0 0 100%;
  height: auto;
  background: $doctor;
  margin-top: 16px;
}