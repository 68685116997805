.Rotate-phone-modal .Waitlist-mascot img{
    margin: auto;
    width: 130px;
}
.Rotate-phone-modal .Waitlist-mascot {
    margin-top: -90px;
}
.Rotate-phone-modal .Modal .modal-dialog {
    margin: 120px 190px;
}
.Rotate-phone-modal .Modal .modal-dialog .modal-content{
    border-radius: 15px;
    padding: 0 0 20px 0;
}
.Rotate-phone-modal .Modal .modal-dialog h4{
    margin-top: 10px;
    font-weight: bold;
    padding: 0 20px;
    /* font-family: 'lato_black'; */
    margin: 0;
}
.Rotate-phone-modal .Modal .modal-dialog p{
    color: #646464;
    margin: 0;
    text-align: center;
}