.jobs-tab-list {
    list-style: none;
    padding: 0;
    max-width: 100%;
    display: flex;
    overflow-x: auto;
    margin-bottom: 24px;
    padding: 0 20px;
    position: absolute;
    top: 70px;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        width: 0;
    }
    .tab-item {
        color: $aero;
        @extend .h6;
        padding:  0 12px;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 24px;
        }
        &.selected {
            color: $sapphire;
        }
    }
}
.tab-panel-container {
    padding: 0 20px;
}
.tabs-container {
    position: absolute;
    top: 110px;
    overflow-y: scroll;
    bottom: 0;
    right: 0;
    left: 0;
    &::-webkit-scrollbar {
        width: 0;
    }
}