.booking-info-container {
  .booking-service {
    padding: 20px;
    position: fixed;
    top: 16px;
    width: 100%;
    z-index: 1;
    background: $doctor;
  }
  .booking-service-body {
    padding: 90px 0;
    background: $doctor;
    .got-it-button {
      position: fixed;
      bottom: 0;
      padding: 16px;
      background-color: $doctor;
      width: 100%;
      .button-container {
        .MuiButtonBase-root.MuiButton-root.MuiButton-text {
          width: 100%;
        }
      }
    }
  }
  + .close-button-container {
    position: fixed;
    bottom: 0;
    padding: 20px;
    background: $doctor;
    width: 100%;
    button {
      @extend .button;
      background: $bleu-de-france;
      border-radius: 5px;
      color: $doctor;
      text-transform: none;
      width: 100%;
      border: 0;
      padding: 8px 16px;
    }
  }
}