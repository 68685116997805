.Home_Featured{
  display: flex;
  flex-wrap: wrap;
  .Home_Featured_Box{
    flex: 1 1 100%;
    width: 100%;
    // min-height: 312px;
    overflow-x: hidden;
    // background: url('../../../assests/images/home/ServiceCollectionBG.svg') $bright-navy-blue  70% 68% no-repeat;
    .tab-active-home {
      h5 {
        @extend .h6;
        color: $doctor;
      }
    }
    .Home_Featured_Box_Service{
      text-align: center;
      flex: 1 1 33.3333%;
      float: left;
      width: 33%;
      button{
        padding: 0;
        min-width: unset;
        font-size: unset;
        text-transform: none;
        width: 100%;
      }

    }
  }
  .tab-item-history{
    margin: 0 12px !important;
    &:first-child{
      margin-left:24px !important;
    }
  }
}
.Featured_Title{
  padding: 0 0 8px;
  color: $doctor;
  margin: 4px 0 10px 0;
  @extend .caption;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-align: center;
}
.Tabs_Menu_Parent{
  .tabs-menu-tab{
    .tab-item-history:last-child{
      padding-right: 20px;
    }
  }
}