.button-container {
  .MuiButtonBase-root {
    &.MuiButton-root {
      &.MuiButton-text {
        @extend .button;
        background: $bleu-de-france;
        border-radius: 5px;
        color: $doctor;
        text-transform: none;

        &.Mui-disabled {
          background-color: $alice-blue;
          color: $aero;
        }

        &:hover {
          @extend .subtle-black-shadow;
        }
      }
    }
  }
}
.button-outline {
  .MuiButtonBase-root {
    &.MuiButton-root {
      &.MuiButton-text {
        @extend .button;
        border-color: $bleu-de-france;
        border-radius: 5px;
        color: $bleu-de-france;
        background-color: $doctor;
        border: 1px solid $bleu-de-france;

        &.Mui-disabled {
          background-color: $alice-blue;
          color: $aero;
        }

        &:hover {
          @extend .subtle-black-shadow;
        }
      }
    }
  }
}
.request-again{
  width: 100%;
  .MuiButtonBase-root{
    width: 100%;
    padding: 14px !important;
    .MuiButton-label{
      font-size: 18px !important;
    }
  }
}