.refer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 65px;
    background-color: $doctor;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
    &::-webkit-scroll {
        width: 0;
    }
    .refer-img {
        padding-top: 10%;
        padding-bottom: 30px;
        text-align: center;
    }
    .refer-description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 0 20px;
        h6 {
            @extend .h6;
            color: $charcoal;
        }
        p {
            @extend .caption;
            color: $charcoal;
            margin: 0;
        }
    }
    .refer-action-container {
        padding: 40px 20px 0;
        display: flex;
        justify-content: center;
        .refer-input-code {
            text-align: center;
            color: $charcoal;
            border: 2px dashed $liver-dogs;
            background-color: $antique-white;
            text-transform: none !important;
            height: 60px;
            width: 70%;
            border-radius: 8px;
            @extend .h6
        }
    }
    .share-code {
        position: absolute;
        bottom: 0;
        background: $doctor;
        padding: 15px;
        width: 100%;
        text-align: center;
        button {
            width: 100%;
        }
    }
}
.Refer .Refer-description {
    color: #ccc;
    margin-bottom: 2px;
}
.Refer .Refer-action-container {
    padding: 40px 60px;
}
.Refer .Refer-action-container .Refer-input-code {
    text-align: center;
    border: 1px dashed #0787fa !important;
    text-transform: none !important;
    height: 50px !important;
    font-size: 25px !important;
    width: 100%;
    border-radius: 5px;
}
.Refer .Refer-action-container button {
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    margin-top: 15px;
    padding: 12px;
    font-size: 16px;
    /* font-family: 'lato_black'; */
    color: white;
    text-transform: none !important;
}
.Refer .Refer-action-container button.share-code {
    background-color: #0787fa !important;
}
.Refer .Refer-action-container button.invite-friends {
    background-color: orange;
}
.Refer .Header-back .Header-back-img p {
    padding-left: 0;
}
.Refer .Refer-img {
    padding: 15px 30px 0px;
}
