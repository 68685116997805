.job-unfulfilled-buttons {
    margin: 16px 0;
    display: flex;
    .retry-button {
        flex-basis: 60%;
        text-align: center;
        color: $doctor;
        background-color: $bleu-de-france;
        @extend .button;
        text-transform: none;
        &:focus, &:active, &:hover {
            color: $doctor;
            background-color: $bleu-de-france;
        }
    }
    .cancel-button {
        flex-basis: 40%;
        color: $bittersweet;
        @extend .button;
        text-transform: none;
    }
}
.unfulfilled-schedule-booking-button {
    width: 100%;
    .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        width: 100%;
    }
    p {
        margin: 0;
    }
    svg {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
}