.Bottom_Sheet {
  display: flex;
  flex-basis: 100%;
  z-index: 10;
  border-radius: 10px;
}
.ReactSwipeableBottomSheet--open  , .ReactSwipeableBottomSheet--closed{
  border-radius: 20px 20px 0 0;
}
.react-swipeable-view-container{
  border-radius: 20px 20px 0 0;
}
.Sheet_Content{
  position: relative;
  width: 100%;
  .Bottom_Border_Sheet{
    position: absolute;
    bottom: 8px;
    padding: 0 13%;
    height: 4px;
    background-color: black;
    border-radius: 15%;
    margin: 0 auto;
    right: 38%;
  }
  .indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 100%;
    background-color: $doctor;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    border-radius: 20px 20px 0 0;
    .indicator {
      background-color: $grainsboro;
      width: 40px;
      height: 3px;
      border-radius: 30px;
    }
  }
}