.review-booking-button-container {
    padding: 24px 16px;
    bottom: 0;
    width: 100%;
    background-color: $bright-navy-blue;
    display: flex;
    align-items: center;
    .review-booking-button  {
        flex-basis: 70%;
        max-width: 70%;
        .button-container {
            .MuiButton-root {
                width: 100%;
                background-color: $doctor;
                border-radius: 5px;
                color: $bleu-de-france;
                @extend .button;
                padding: 8px;
                text-transform: none;
                letter-spacing: .25px;
            }
        }
    }
    .price-container {
        flex-basis: 30%;
        max-width: 30%; 
        color: $doctor;
        padding-right: 16px;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
       p {
           margin-bottom: 0;
           @extend .caption;
           &.price {
               @extend .h6;
           }
       }
    }
}
//.Parent_Review_Container{
//    width: 100%;
//    display: flex;
//    flex-wrap: wrap;
//    flex: 1;
//    max-height: 120px;
//    position: fixed;
//    bottom: 0;
//}