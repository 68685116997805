@import "./BookingInfoModal/BookingInfoModal";
@import "./BookingService/BookingService";
@import "./BookingLocationBox/BookingLocationBox";
@import "./BookingHeader/BookingHeader";
@import "./BookingServiceDescription/BookingServiceDescription";
@import "./BookingNote/BookingNote";
@import "./ReviewBookingButton/ReviewBookingButton";
@import "./BookingSpecial/BookingSpecial";
@import "./ReviewBooking/ReviewBooking";
@import "./JobLocations/JobLocations";
@import "./BookingPayment/BookingPayment";
@import "./BookingButton/BookingButton";
@import "./BookingPriceErrorModal/BookingPriceErrorModal";
@import "./RejectedPaymentMethod/RejectedPaymentMethod";
@import "./HowItWorks/HowItWorks";
@import "./PromoCodeBottomSheet/PromoCodeBottomSheet";
@import "./BookingPriceTime/BookingPriceTime";
.booking-inner {
    overflow-y: scroll;
    padding-bottom: 80px;
    width: 100%;
    display: inline;
}
.call-to-action-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;
    background-color: $doctor;
    .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        padding: 8px 32px;
    }
}
.booking {
    .react-swipeable-view-container {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    }
    .Sheet_Content  {
        width: 100%;
    }
    .ReactSwipeableBottomSheet--open  , .ReactSwipeableBottomSheet--closed{
        border-radius: 20px 20px 0 0 !important;
        &::-webkit-scrollbar {
        width: 0;
        }
    }
}
.product-info-bottom-sheet,
.choose-location-bottom-sheet {
    height: 450px;
    background-color: $doctor;
    border-radius: 20px 20px 0 0;
}
.choose-location-bottom-sheet {
    .indicator-container {
        position: fixed;
        border-radius: 20px 20px 0 0;
        z-index: 2;
    }
    .location-bottom-sheet-container {
        position: relative;
        overflow-y: auto;
        position: relative;
        border-radius: 20px 20px 0 0;
        display: inline;
        &::-webkit-scrollbar {
            width: 0;
        }
        .location-search-suggestions {
            margin-top: 0;
        }
    }
    .title-bar-container {
        // border-radius: 20px 20px 0 0;
        top: 10px;
    }
}