.topup-mykoin-bottom-sheet {
    .Sheet_Content {
        width: 100%;
    }
    .title-bar-container {
        border-radius: 20px 20px 0 0;
        top: 20px;
    }
    .top-up-mykoin-container {
        padding: 85px 20px 20px;
        background-color: $doctor;
        width: 100%;
        display: flex;
        flex-direction: column;
        .topup-form {
            display: flex;
            flex-direction: column;
            padding: 8px 0;
            border-bottom: 1px solid $grainsboro;
            label {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $spanish-gray;
                padding: 0 5px;
                margin-bottom: 0;
            }
            .input-container {
                display: flex;
                align-items: center;
                padding: 0 5px;
                span {
                    color: $spanish-gray;
                    margin-right: 3px;
                }
                input {
                    border: 0;
                    padding: 0;
                    font-size: 13px;
                    line-height: 24px;
                    width: 100%;
                    &::placeholder {
                        color: $grainsboro;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
        .default-amount-container {
            margin: 16px 0 24px;
            display: flex;
            justify-content: space-between;
            .default-amount {
                flex-basis: 33.3333%;
                padding: 16px;
                border-radius: 8px;
                box-shadow: 0px 8px 12px rgba(24, 26, 30, 0.12);
                display: flex;
                align-items: center;
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }
                p {
                    color: $charcoal;
                    @extend .subtitle2;
                    margin-left: 12px;
                    margin-bottom: 0;
                }
            }
        }
        .button-container {
            width: 100%;
            .MuiButtonBase-root {
                &.MuiButton-root {
                &.MuiButton-text {
                    @extend .button;
                    background: $bleu-de-france;
                    border-radius: 5px;
                    color: $doctor;
                    text-transform: none;
                    width: 100%;
                    letter-spacing: 0.25px;
                    padding: 10px;
                    &.Mui-disabled {
                        background-color: $alice-blue;
                        color: $aero;
                    }

                    &:hover {
                        @extend .subtle-black-shadow;
                    }
                }
                }
                .white-lock {
                    margin-right: 8px;
                }
            }
        }
        .error {
            @extend .assistive;
            color: $bittersweet;
        }
    }
}