@import "./JobMatchingModal/JobMatchingModal";
@import "./JobMatchingRippleEffect/JobMatchingRippleEffect";
@import "./JobLocations/JobLocations";
@import "./JobMatchedModal/JobMatchedModal";
@import "./JobUnfulfilledModal/JobUnfulfilledModal";

.job-matching {
    .title-bar-container {
        .title-bar-icon {
            display: flex;
            .heading {
                padding: 0 8px 0 0;
                @extend .subtitle1;
            }
            .lozenge-container{
                align-items: center;
                .lozenge-inner {
                    height: fit-content;
                    .lozenge-title {
                        p {
                            @extend .overline;
                        }
                    }
                }
            }
        }
    }
    .matching-cta-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $doctor;
        h2 {
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            margin: 0 0 20px;
            color: $charcoal;
        }
        .button-container {
            position: relative;
            padding: 0;
            .MuiButtonBase-root.MuiButton-root.MuiButton-text {
                width: 100%;
            }
            &.cancel-booking {
                margin-top: 10px;
                .MuiButtonBase-root.MuiButton-root.MuiButton-text {
                    background-color: transparent;
                    color: $cinnabar;
                    border: 1px solid $cinnabar;
                }
            }
        }
    }
}