.Home_Search_Box_Mobile{
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
}
.Home_Search_Box_Desktop{
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
}
.Home_Product_Box{
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 150px 0 0 0;
  max-height: 600px;
  overflow: auto;
}