.lozenge-container {
  display: flex;
  width: fit-content;
  .lozenge-inner {
    border-radius: 4px;
    &.color-green {
      background-color: $emerald;
    }
    &.color-yellow {
      background-color: $orange-yellow
    }
    &.color-orange {
      background-color: $tangerine;
    }
    &.color-red {
      background-color: $cinnabar;
    }
    &.color-violet {
      background-color: $secondary-violet;
    }
    &.color-gray {
      background-color: $grainsboro;
      .lozenge-title {
        p {
          color: $charcoal;
        }
      }
    }
    &.color-gunmetal {
      background-color: $gunmetal;
    }
    &.color-charcoal {
      background-color: $charcoal;
    }
    .lozenge-title {
      padding: 0 5px;
      @extend .overline;
      p {
        margin-bottom: 0;
        color: $doctor;
      }
    }
  }
}