@import "./SavedPlacesOthers/SavedPlacesOthers";
@import "./SavedPlacesFavorites/SavedPlacesFavorites";
@import "./SavedPlacesAdd/SavedPlacesAdd";
@import "./SavedPlacesAddress/SavedPlacesAddress";

//Shared style between all pages related to saved places
.saved-places {
    .saved-places-container {
        padding: 0 20px;
        width: 100%;
        overflow: hidden;
    }

    .saved-places-swipe-to-delete {
        // border-bottom: 1px solid $grainsboro;
    }
    .icon {
        margin-right: 15px;
    }
    .title-container {
        .name {
            @extend .subtitle2;
        }
        p {
            @extend .subtitle2;
            margin:0;
            color: $charcoal;
        }
        .sub-title {
            @extend .caption;
            color: $silver-chalice;
            display: flex;
            font-weight: normal;
            height: 20px;
            width: 100%;
            overflow-y: hidden;
            text-overflow: ellipsis;
        }
        .forward-icon {
            margin-left: 5px;
            width: 6px;
        }
    }
}