.saved-places-address {
    .saved-places-background {
        padding: 65px 20px 0;
        margin-bottom: 20px;
        .form-group-container {
            padding: 8px 8px 0;
        }
        input {
            width: 100%;
        }
    }
    .saved-places-name {
        @extend .caption;
        color: $spanish-gray;
        p {
            margin-bottom: 0;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.4px;
            color: #898E92;
        }
    }
    .saved-places-address-container {
        padding: 0 25px 20px;
        .saved-places-address-selected {
            display: flex;
            align-items: center;
            padding: 0 8px 8px;
            border-bottom: 1px solid $grainsboro;
            p {
                margin: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 19px;
                letter-spacing: 0.25px;
                color: #3B424A;
            }
        }
    }
}
.saved-places-address-confirm{
    margin-top: 16px;
    padding: 0 20px;
    .cancel-button {
        margin-top: 8px;
        &.button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
            background-color: $doctor;
            border: 0;
            color: $bleu-de-france;
        }
    }
    .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled {
        background-color: $bleu-de-france;
        color: $doctor;
        opacity: .6;
    }
    button {
        width: 100%;
    }
}
