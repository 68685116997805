.Active_Jobs {
  padding: 0 0 0 20px;
  margin: 0;
  .Active_Jobs_Scroll{
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .Active_Jobs_Title{
    flex: 1 1 100%;
    width: 100%;

    h1{
      @extend .h6;
      color: $charcoal;
      margin: 16px 0;
    }
  }
  .Active_Jobs_Slider_Box{
    flex: 1 1 80%;
    width: 80%;
    background-color: $doctor;
    display: inline-block;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 24px 12px 0;
    @extend .big-black-shadow;

    .Home_Active_Job_Container {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 100%;
      text-align: center;
      .H_A_J_C_Map{
        width: 35%;
        // height: 176px;
        position: relative;
        float: left;
        .Map_Img{
          width: 100%;
          height: 100%;
        }
        .location-icon {
          position: absolute;
          width: 32px;
          height: 32px;
          left: calc(50% - 32px/2 + 1px);
          top: calc(50% - 32px/2);
          filter: drop-shadow(0px 2px 4px rgba(24, 26, 30, 0.15));
          img {
            width: 32px;
            height: 32px;
          }
        }
        .Partner_Img{
          position: absolute;
          width: 32px;
          height: 32px;
          background: transparent;
          left: 34px;
          top: 67px;
        }
      }
      .H_A_J_C_Content{
        width: 65%;
        height: auto;
        padding: 20px 8px;
      }
      .H_A_J_C_C_State{
        width: 100%;
        height: auto;
        margin-bottom: 12px;
        .lozenge-container .lozenge-inner .lozenge-title p {
          letter-spacing: 1.5px;
        }
        .H_A_J_C_C_S_Child_Service{
          font-size: 15px;
          text-align: left;
          font-weight: 600;
          margin: 8px 0 0;
        }
      }
      .H_A_J_C_C_Row{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .H_A_J_C_C_R_Icon{
          
          .Blue_Person{
            
          }
        }
        .Dynamic_Parent_Icon {
          width: 16px;
          height: 16px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .H_A_J_C_C_R_Text{
          h5{   
            display: -webkit-box;
            max-width: 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #3B424A;
            margin: 0 0 0 4px;
          }
        }
      }
    }
  }
}
.Active_Jobs_Scroll::-webkit-scrollbar {
  width: 0
}
.Time_Left_Red{
  color: #E34B34 !important;
}
.Time_Left_Black{
  color: $charcoal;

}
.Active_Job_Link{
  width: 100%;
  text-align: right;
  color: #0C87FA;
  margin: 0;
  padding: 0 24px 0 0;
  font-size: 12px;
  font-weight: bold;
}