.rce-container-mbox {
  display: block;
  overflow: hidden;
  min-width: 200px;
  
  .timezone {
    display: flex;
    justify-content: center;
    color: $spanish-gray;
    @extend .assistive;
    margin: 8px 0;
  }
}

.rce-mbox-forward {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: #fff;
  position: absolute;

  /*display: none;*/
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
  cursor: pointer;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  margin: auto;
}

.rce-mbox-forward-left {
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: -50px;
}

.rce-mbox-forward-right {
  display: flex;
  opacity: 0;
  visibility: hidden;
  right: -50px;
}

.rce-container-mbox:hover {
  .rce-mbox-forward-left, .rce-mbox-forward-right {
    opacity: 1;
    visibility: visible;
  }
}

.text .rce-mbox {
  position: relative;
  background: $grainsboro;
  border-radius: 8px 8px 8px 0px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 2px 0;
  float: left;
  min-width: 140px;
  max-width: 70%;
  color: $charcoal;
  @extend .body2;
}

.rce-mbox-text {
  font-size: 13px;
  word-break: break-word;

  /* font-weight: 600; */
  white-space: pre-line;
}

.text.system .rce-mbox {
  position: relative;
  background: #DBDBDB;
  border-radius: 8px 8px 8px 0px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  float: left;
  min-width: 140px;
  max-width: 70%;
  color: #3B424A;
}

.file .rce-mbox, .audio .rce-mbox {
  position: relative;
  background: $grainsboro;
  border-radius: 8px 8px 8px 0px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 6px 9px 8px 9px;
  float: left;
  min-width: 140px;
  max-width: 60%;
}

.photo .rce-mbox, .image .rce-mbox {
  position: relative;
  background: $doctor;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  padding: 3px 3px 3px 3px;
  float: left;
  min-width: unset;
  max-width: 70%;
}

.rce-mbox.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-box-default-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-box-default-focus {
  from {
    background-color: #fff;
  }

  to {
    background-color: #dfdfdf;
  }
}

.rce-mbox-body {
  margin: 0;
  padding: 8px;
  position: relative;
}

.photo .rce-mbox-body, .image .rce-mbox-body {
  padding: 4px 7px 2px 7px;
}

.text .rce-mbox.rce-mbox-right, .file .rce-mbox.rce-mbox-right, .audio .rce-mbox.rce-mbox-right {
  float: right;
  margin-left: 5px;
  margin-right: 20px;
  background-color: $bleu-de-france;
  color: $doctor;
  border-radius: 8px 8px 0px 8px;
}

.photo .rce-mbox.rce-mbox-right, .image .rce-mbox.rce-mbox-right {
  float: right;
  margin-left: 5px;
  margin-right: 20px;
  background-color: $doctor;
  color: $doctor;
}

.rce-mbox {
  &.rce-mbox-right .rce-mbox-time {
    color: $doctor;
  }

  &.rce-mbox-mc {
    background-color: $antique-white !important;
    color: $charcoal;

    .rce-mbox-time {
      /* background-color: green; */
      color: $doctor;
    }
  }

  &.rce-mbox-right.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-box-right-focus;
    animation-duration: 1s;
  }
}

@-webkit-keyframes message-box-right-focus {
  from {
    background-color: #d4f1fb;
  }

  to {
    background-color: #b8dae6;
  }
}

.rce-mbox-text:after {
  /* content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0" */
}

.rce-mbox-time {
  text-align: right;
  font-size: 12px;
  position: absolute;
  right: 5px;
  bottom: 0;
}

.text.system .rce-mbox-time {
  text-align: center;
  color: #949494;
  font-size: 12px;
  position: relative;

  /* right: 5px; */
  /* bottom: -5px; */
}

.rce-mbox-time.non-copiable:before {
  content: attr(data-text);
}

.rce-mbox-time-block {
  /*position: relative;*/
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: 0px;
  margin-left: -6px;
  padding-top: 5px;
  padding-right: 3px;
  padding-bottom: 2px;

  /* background: linear-gradient(to top, rgba(0,0,0,0.33), transparent); */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
}

.rce-mbox--clear-padding {
  padding-bottom: 3px;
}

.rce-mbox.rce-mbox--clear-notch {
  border-radius: 5px 5px 5px 5px !important;
}

.rce-mbox-right-notch {
  position: absolute;
  right: -14px;
  top: 0px;
  width: 15px;
  height: 15px;

  /* fill: $doctor; */
  filter: drop-shadow(2px 0px 1px rgba(0, 0, 0, 0.2));

  &.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-right-notch-focus;
    animation-duration: 1s;
  }
}

@-webkit-keyframes message-right-notch-focus {
  from {
    fill: #d4f1fb;
  }

  to {
    fill: #b8dae6;
  }
}

.rce-mbox-left-notch {
  position: absolute;
  left: -14px;
  top: 0px;
  width: 15px;
  height: 15px;
  fill: $doctor;

  &.message-focus {
    animation-iteration-count: 2;
    -webkit-animation-iteration-count: 2;
    -webkit-animation-duration: 1s;
    animation-name: message-left-notch-focus;
    animation-duration: 1s;
  }
}

@-webkit-keyframes message-left-notch-focus {
  from {
    fill: #fff;
  }

  to {
    fill: #dfdfdf;
  }
}

.rce-mbox-title {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #4f81a1;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
}

.rce-mbox-title--clear {
  margin-bottom: 5px;
}

.rce-mbox-status {
  margin-left: 3px;
  font-size: 12px;

  svg {
    vertical-align: text-bottom;
  }
}

.rce-mbox-title > .rce-avatar-container {
  margin-right: 5px;
}
.tap-to-join {
  margin-bottom: 0;
  font-size: 12px;
  color: $sapphire;
  text-align: right;
}