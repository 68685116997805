.account-version-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .account-necessary-links {
        padding: 0 20px 24px;
        display: flex;
        flex-direction: column;
        a {
            @extend .link;
            margin-bottom: 8px;
            color: $bleu-de-france;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
    .account-version-number {
        color: $silver-chalice;
        @extend .overline;
    }
    .account-logout-container {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        
        button {
            color: $bittersweet;
            border: 1px solid $bittersweet;
            @extend .button;
            box-shadow: none;
            background: none;
            width: 100%;
            border-radius: 5px;
            padding: 8px;
            &:focus {
                outline: none;
            }
        }
    }
}