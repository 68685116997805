//Brand Colors - Blue
$sapphire: #0057AD;
$denim: #0063C7;
$bright-navy-blue: #0070E0;
$bleu-de-france: #0C87FA;
$little-boy-blue: #4C9EEB;
$aero: #81B7E9;
$alice-blue: #E2EEF8;

//Brand Colors - Orange
$liver-dogs: #B56400;
$ochre: #D47706;
$tangerine: #ED7E00;
$dark-orange: #FF9012;
$yellow-orange: #FDA949;
$gold-crayola: #F2C488;
$antique-white: #F7E5CF;

//State Colors - yellow
$lemon-curry: #CC9600;
$golden-rod: #E5AD12;
$orange-yellow: #F2BA1F;
$mango: #FEC132;
$maize-crayola: #FFCF60;
$jasmie: #F6D78D;
$lemon-merengue: #F6EAC1;

//State Colors - green
$spanish-green: #008A3B;
$green-pigment: #02A347;
$emerald: #15BD5D;
$mint: #2CD574;
$regen: #52E08F;
$aquamarine: #81E4AB;
$aero-blue: #C4EED6;

//State Colors - red
$rufous: #B01C05;
$rosso-corsa: #C9311A;
$cinnabar: #E34B34;
$bittersweet: #FC6A54;
$congo-pink: #F68A79;
$melon: #F2A79C;
$pale-pink: #F5D1CC;

//Neutral Colors
$eerie-black: #181A1E;
$charleston-green: #23282C;
$gunmetal: #2F353B;
$charcoal: #3B424A;
$dim-gray: #62686E;
$spanish-gray: #898E92;
$silver-chalice: #B1B3B7;
$grainsboro: #DBDBDB;
$doctor: #FAFBFC;

//Violet
$secondary-violet: #581C8A;

//Divider - light 
.light-divider {
    border: 0.2px solid $doctor;
}
// Divider - dark
.dark-divider {
    border: 0.2px solid $silver-chalice;
}

.fade {
    background: linear-gradient(180deg, rgba(226, 238, 248, 0) 0%, #E2EEF8 100%);
}

//Shadow
.blue-shadow {
    box-shadow: 0px 3px 4px rgba(9, 96, 179, 0.5);
}
.black-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.upper-black-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.subtle-black-shadow {
    box-shadow: 0px 2px 4px rgba(24, 26, 30, 0.15);
}
.big-black-shadow {
    box-shadow: 0px 8px 12px rgba(24, 26, 30, 0.12);
}
.small-inner-shadow {
    box-shadow: inset 0 0 4px rgba(24, 26, 30, 0.1);
}

@mixin  big-black-shadow(){
  -webkit-box-shadow:0 8px 12px rgba(24, 26, 30, 0.12);
  -moz-box-shadow:0 8px 12px rgba(24, 26, 30, 0.12);
  box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12);
}


//padding
.no-padding {
  padding: 0;
}
.no-padding-left {
  padding-left: 0;
}
.no-padding-right {
  padding-right: 0;
}
.p-l-0 {
  padding-left: 0px !important; 
}
.p-r-0 {
  padding-right: 0px !important; 
}
.p-l-5 {
  padding-left: 5px !important; 
}
.p-r-5 {
  padding-right: 5px !important; 
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-t-5 {
    padding-top: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important; 
}
.p-r-10 {
  padding-right: 10px !important; 
}

//margin
.m-t-2 {
  margin-top: 2px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-29 {
  margin-top: 29px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-70 {
  margin-bottom: 70px;
}
.m-t-150 {
  margin-top: 150px;
}
@mixin ca-5-shadow(){
  box-shadow: 0 8px 12px rgba(24, 26, 30, 0.12);
}
