.open-jobs-container {
    h2 {
        color: $spanish-gray;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        margin: 0;
        line-height: 18px;
    }
}