.accout-container {
  display: block;
  position: relative;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
  .account-profile {
    padding: 65px 20px 20px;
    display: flex;
    align-items: center;
    .account-profile-name {
      @extend .h6;
      margin-bottom: 0;
    }
    .account-profile-edit {
      display: inline-block;
      margin: 0 5px 0 0;
      color: $silver-chalice;
      font-size: 12px;
      line-height: 18px;
    }
    .avatar-wrapper {
      padding-right: 15px;
    }
    .profile-info {
      a {
        @extend .caption;
      }
    }
    .account-profile-forward {
        width: 6px;
        height: 10px;
    }
  }
}