.rejected-payment-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: $doctor;
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  @media (min-width: 400px) {
    padding: 24px;

  }
  .rejected-payment-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px 0 24px;
    text-align: center;
    h2 {
      color: $charcoal;
      @extend .h6;
      margin: 0;
    }
    h6 {
      color: $charcoal;
      font-size: 13px;
      line-height: 20px;
      margin: 5px 0 0;
    }
  }
  .rejected-payment-body {
    display: flex;
    align-items: center;
    padding: 24px 16px;
    img {
      width: 16px;
      height: 16px;
    }
    p {
      color: $charcoal;
      margin-bottom: 0;
      padding-left: 24px;
      @extend .caption;

    }
  }
  .rejected-payment-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    .button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text,
    .button-container .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      width: 100%;
    }
    .button-outline .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      margin: 16px 0 8px;
    }
    .chat-with-support {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 8px;
      span {
        padding-left: 8px;
        color: $bleu-de-france;
          @extend .button;
      }
    }
  }
}
.rejected-payment-bottom-sheet {
  .Sheet_Content {
    width: 100%;
  }
}