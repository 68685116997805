.billing-information-add {
    padding: 24px 20px;
    display: flex;
    justify-content: flex-end;
    .button-container {
        .MuiButtonBase-root {
            &.MuiButton-root {
                &.MuiButton-text {
                    @extend .link;
                    padding: 0;
                    color: $bleu-de-france;
                    background: transparent;
                    box-shadow: none;
                }
            }
        }

    }
}