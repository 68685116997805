.Home_Location {
  flex: 1 1 100%;
  height: 90px;
  background: url('../../../assests/images/home/ServiceCollectionBG.svg') #0057AD  -40px -9px no-repeat;
  .Home_Location_White_Box{
    flex: 1 1 100%;
    background: #FAFBFC;
    margin: 12px 24px;
    height: 64px;
    border-radius: 10px;
    .H_L_W_B_Top_Box{
      flex: 1 1 100%;
      height: 50%;
      border-bottom: 1px solid #ededed;
      display: flex;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .Dynamic_Parent_Icon{
        width: auto;
        margin: 0 8px 0 0;
      }
      .H_L_W_B_Top_Text{
        width: auto;
        margin: 2px 8px 0 0;

        h5 {
          text-align: left;
          margin: 0;
          font-size: 12px;
        }
      }
      .H_L_W_B_Top_Right_Arrow_Location{
        width: auto;
        margin: 0 8px 0 0;

        .Right_Arrow{
          width: 10px;
          height: 10px;
          margin: 0;
        }
      }
    }
    .H_L_W_B_Down_Box{
      flex: 1 1 100%;
      height: 50%;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      .Dynamic_Parent_Icon{
        flex: 1 1 13%;
        .Location_Search{
          margin: 8px 0 0 20px;
          width: 16px;
          height: 16px;
        }
      }
      .H_L_W_B_Top_Text{
        flex: 1 1 87%;
        h5{
          color: #B1B3B7;
          font-size: 12px;
        }
      }
    }
  }
  .Location{
    display: flex;
    flex: 1 1 100%;
    text-align: center;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.48);
    padding: 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    align-items: center;
    .Location_Pin{
      width: 16px;
      height: 16px;
      margin: 0 0 0 16px;
    }
    p {
      margin: 0;
    }
    .Pin-icon {
      width: 18px;
      float: left;
    }
  }
}