.title-bar-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: $doctor;
    max-width: 412px;
    p {
        margin: 0 auto;
        display: block;
        text-align: center;
        @extend .subtitle1;
        color: $charcoal;
    }
}