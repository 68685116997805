.saved-places {    
    .saved-places-add {
        display: flex;
        padding: 15px;
        align-items: center;
        .title-container {
            .sub-title {
                align-items: center;
            }
        }
    }
}