.partner-not-joined-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #4c4c4c;
    filter: blur(10px);
    z-index: -1;
}
.partner-info {
    p {
        color: $doctor;
        text-align: center;
        z-index: 9;
        padding: 20px 0 0;
        font-size: 25px;
        font-weight: 500;
        margin: 0;
    }
    span {
        text-align: center;
        color: $spanish-gray;
        display: block;
    }
}
.video-chat-container {
    background-color: $eerie-black;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    &.partner-not-joined {
        .client-video {
            video {
                position: absolute;
                right: 0;
                bottom: 0;
                min-width: 100%;
                min-height: 100%;
            }
        }
        .partner-info {
            position: absolute;
            z-index: 2;
            width: 100%;
            text-align: center;
            span {
                color: $grainsboro;
            }
        }
    }
    .client-video {
        position: absolute;
        left: 20px;
        top: 20px;
        width: 100%;
        z-index: 1;
        &.partner-joined  {
            video {
                display: flex;
                width: 35%;
                border-radius: 10px;
            }
        }
    }
    .card-bottom {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 100%;
        padding: 20px;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        .left-call {
            margin: 0 15px;
            .left-call-icon {
                fill: $rufous;
            }
        }
        .icon-container {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
    }
    .image-partner {
        position: absolute;
        left: 50%;
        top: 35%;
        transform: translate(-50%, -50%);
        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }
        p {
            margin: 10px 0 0;
            color: $doctor;
            text-align: center;
            font-weight: 600;
        }
    }
}
.partner-video {
    margin: 0;
    video {
        position: absolute;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
    }
    .camera-off {
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
}

@media (min-aspect-ratio: 16/9) {
    .partner-video {
        video {
            width:100%;
            height: 100%;
        }
    }
}